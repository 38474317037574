import styled from 'styled-components';
import { Button, Link, Title } from '@smartups-sl/react-basic-components';

export const LoginLogoContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginLogo = styled.img`
  width: 50%;
`;

export const LoginLogoExt = styled.img`
  width: 30%;
`;

export const IconSum = styled.i`
  font-size: 3rem;
  color: #032b44;
  margin-left: 40px;
  margin-right: 30px;
`;

export const ButtonForgot = styled.button`
  background: transparent;
  border: 0px;
  color: #e83c7e;

  &:hover {
    color: #e83c7e;
    text-decoration: underline;
  }
`;
