import * as Yup from 'yup';

export const dataModel = {
  confirm: false,
  accept: false,
  countryResidence: '',
  nationality: '',
};

export const validationDataModel = Yup.object().shape({
  accept: Yup.boolean()
    .required('forms.validations.required')
    .oneOf([true], 'forms.validations.mustSelect'),
  confirm: Yup.boolean()
    .required('forms.validations.required')
    .oneOf([true], 'forms.validations.mustSelect'),
  countryResidence: Yup.string().required('forms.validations.required'),
  nationality: Yup.string().required('forms.validations.required'),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
