import { createSelector } from 'reselect';

const getStateAuthSelector = (state) => state.auth;

export const getAuthStatusSelector = createSelector(getStateAuthSelector, (auth) => {
  if (auth && auth.status) {
    return auth.status;
  }

  return undefined;
});

export const getAuthTokenSelector = createSelector(getAuthStatusSelector, (status) => {
  if (status && status.token) {
    return status.token;
  }

  return undefined;
});

export const getTFATimeSelector = createSelector(getAuthStatusSelector, (status) => {
  if (status && status.tfaTime) {
    return status.tfaTime;
  }

  return undefined;
});

export const getAuthInfoSelector = createSelector(getStateAuthSelector, (auth) => {
  if (auth && auth.info !== undefined) {
    return auth.info;
  }

  return undefined;
});

export const getRegisterPayedSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.registerPayed !== undefined) {
    return info.registerPayed;
  }

  return undefined;
});

export const getNicknameSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.nickname !== undefined) {
    return info.nickname;
  }

  return undefined;
});

export const getRegisterPaymentSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.registerPayment !== undefined) {
    return info.registerPayment;
  }

  return undefined;
});

export const getUserRoleSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.role) {
    return info.role;
  }
  return undefined;
});

export const getUserPersonalInfoSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info) {
    const data = {
      name: info.name,
      nickname: info.nickname,
      surname: info.surname,
      nationality: info.nationality,
      role: info.role,
      passedKYC: info.passedKYC,
      email: info.email,
      lang: info.lang ? info.lang : 'es',
      hasWallet: info.hasWallet,
    };
    if (info.avatar) {
      data.avatar = info.avatar;
      data.avatarUrl = info.avatarUrl;
    }
    return data;
  }

  return undefined;
});

export const getUserTreeInfoSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info) {
    const data = {
      name: info.name,
      surname: info.surname,
      nickname: info.nickname,
      role: info.role,
      active: info.active,
      uuid: info.uuid,
      email: info.email,
      treeLevel: info.treeLevel,
      botStatus: info.botStatus,
      evoStatus: info.evoStatus,
      pocStatus: info.pocStatus,
    };
    if (info.avatar) {
      data.avatar = info.avatar;
      data.avatarUrl = info.avatarUrl;
    }
    return data;
  }

  return undefined;
});

export const getUserTfaEnabledSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.tfaEnabled) {
    return info.tfaEnabled;
  }
  return undefined;
});

export const getKycPassedSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.passedKYC) {
    return info.passedKYC;
  }
  return undefined;
});

export const getKycRequiredSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.requiredKYC) {
    return info.requiredKYC;
  }
  return undefined;
});

export const getPreferendLanguageSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.lang !== undefined) {
    return info.lang;
  }

  return undefined;
});

export const getUserUuidSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.uuid) {
    return info.uuid;
  }

  return undefined;
});

export const getBotStatusSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.botStatus) {
    return info.botStatus;
  }
  return undefined;
});

export const getEvoStatusSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.evoStatus) {
    return info.evoStatus;
  }
  return undefined;
});

export const getPocStatusSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.pocStatus) {
    return info.pocStatus;
  }
  return undefined;
});

export const getGtrStatusSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.gtrStatus) {
    return info.gtrStatus;
  }
  return undefined;
});

export const getExtStatusSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.extStatus) {
    return info.extStatus;
  }
  return undefined;
});

export const getStatusDuplicatedSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.userDuplicated) {
    return info.userDuplicated;
  }
  return undefined;
});
