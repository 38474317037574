import { handleActions } from 'redux-actions';

import {
  SET_NAV_FROM,
  CLEAN_NAV,
  SET_TIME_ZONE,
  SET_LANGUAGE,
  HIDDEN_INIT_TOUR,
} from '../../constants/index';

export const nav = handleActions(
  {
    [CLEAN_NAV]: (state) => ({ ...state, from: undefined, idPlatform: undefined }),
    [SET_NAV_FROM]: (state, action) => ({
      ...state,
      from: action.payload.from,
      idPlatform: action.payload.idPlatform,
    }),
    [SET_TIME_ZONE]: (state, action) => ({
      ...state,
      timeZone: action.payload.timeZone,
    }),
    [SET_LANGUAGE]: (state, action) => ({
      ...state,
      language: action.payload.lang,
    }),
    [HIDDEN_INIT_TOUR]: (state, action) => ({
      ...state,
      hiddenInitTour: true,
    }),
  },
  {}
);
