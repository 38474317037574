import styled from 'styled-components';

export const ImageBanner = styled.img`
  width: 100%;
  border-radius: 25px;
`;

export const ImageLogo = styled.img`
  width: 100%;
  border-radius: 25px;
`;

export const Title = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 0.875rem;
`;

export const List = styled.ul`
  list-style: none;
  font-size: 0.875rem;
`;

export const Icon = styled.i`
  color: #26abe1;
  margin-right: 10px;
`;

export const ButtonTour = styled.button`
  width: 65px;
  height: 65px;
  background-color: #e83c7e;
  color: #fff;
  border: 1px solid #e83c7e;
  border-radius: 45px;
  font-size: 1.7rem;
  -webkit-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 30px;
  z-index: 9;
  right: 30px;

  &:hover {
    background-color: #fff;
    color: #e83c7e;
    border: 1px solid #e83c7e;
  }
`;

export const ButtonFinishTour = styled.button`
  color: #26abe1;
  border: 0px solid #e83c7e;
  background-color: transparent;
  font-size: 0.7rem;

  &:hover {
    text-decoration: underline;
  }
`;
