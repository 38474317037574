import React from 'react';

import Contact from './contact.view';

const contactContainer = () => {
  return <Contact />;
};

contactContainer.propTypes = {};

export default contactContainer;
