/* eslint-disable indent */
import React from 'react';
import Maintenance from '../../../components/others/maintenance';
import Layout from '../../../components/layouts/centerLayout';

const MaintenancePage = () => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <Maintenance />
        </>
      }
    />
  );
};
MaintenancePage.propTypes = {};

export default MaintenancePage;
