import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';

const ControlTimeSecurity = (props) => {
  const { initTime, timeOut, onComplete } = props;
  const timeLimit = initTime + timeOut || 0;
  const currentTime = Date.now();

  let reminingTime = 0;
  if (!timeOut) {
    reminingTime = initTime;
  } else {
    const diff = timeLimit - currentTime;
    reminingTime = Date.now() + diff;
  }

  return (
    <Countdown
      date={reminingTime}
      onComplete={onComplete}
      onMount={({ completed }) => {
        if (completed) {
          onComplete();
        }
      }}
    />
  );
};

ControlTimeSecurity.propTypes = {
  initTime: PropTypes.number.isRequired,
  timeOut: PropTypes.number,
  onComplete: PropTypes.func,
};

export default ControlTimeSecurity;
