import styled from 'styled-components';

export const ContainerTitleNews = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  width: 100%;
  font-weight: bold;
  color: #26abe1;
  text-transform: uppercase;
  font-weight: 900;
`;

export const ContainerInfinite = styled.div`
  height: 810px;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 20px !important;
`;

export const ContainerListNews = styled.div`
  padding: 2% 10%;
`;

export const ImageList = styled.img`
  border: 2px solid ${({ theme, platform }) => (platform ? theme.platforms[platform] : '#26abe1')};
`;

export const ContainerImage = styled.div`
  border-left: 10px solid
    ${({ theme, platform }) => (platform ? theme.platforms[platform] : '#26abe1')};
  margin-right: 20px;
`;
export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleNews = styled.div`
  font-weight: 900;
  font-size: 1.2rem;
`;

export const DateNews = styled.div`
  font-weight: bold;
  font-size: 0.6rem;
  color: #bdbdbd;
  margin-bottom: 15px;
`;

export const ResumeNews = styled.div`
  font-weight: normal;
  font-size: 0.875rem;
`;
