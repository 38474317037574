import React from 'react';
import PropTypes from 'prop-types';
import EmailVerify from '../../../components/user/emailVerify';
import Layout from '../../../components/layouts/simpleLayout';

const EmailVerifyPage = (props) => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <EmailVerify token={props.token} />
        </>
      }
    />
  );
};

EmailVerifyPage.propTypes = {
  token: PropTypes.string,
};

export default EmailVerifyPage;
