/* eslint-disable indent */
import React from 'react';
import TFA from '../../../components/security/tfa';
import Layout from '../../../components/layouts/centerLayout';

const TFAPage = () => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <TFA />
        </>
      }
    />
  );
};

TFAPage.propTypes = {};

export default TFAPage;
