import * as Yup from 'yup';

export const dataModel = {
  name: '',
  surname: '',
  nationality: '',
  avatar: '',
  nickname: '',
  lang: '',
};

export const validationDataModel = Yup.object().shape({
  name: Yup.string().required('forms.validations.required'),
  nickname: Yup.string().required('forms.validations.required'),
  surname: Yup.string().required('forms.validations.required'),
  nationality: Yup.string().required('forms.validations.required'),
  lang: Yup.string().optional('forms.validations.required'),
  avatar: Yup.string().optional('forms.validations.required'),
});

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
