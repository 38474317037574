//CONSTANTS
export const PAGE_SIZE_10 = 10;
export const PAGE_SIZE_25 = 25;
export const PAGE_SIZE_50 = 50;
export const TFA_TIME_SECURITY = 120000;
export const LOGIN_ATTEMPS = 3600000;
export const MINUS_TIME_LIMIT = 6300000;
export const TIME_REMAINED = 900000;
export const MIN_BOT = 300;
//KYC STATUS
export const KYC_STATUS_PENDING = 'pending';
export const KYC_STATUS_APPROVED = 'approved';
export const KYC_STATUS_DECLINED = 'declined';
export const KYC_STATUS_NEED_REVIEW = 'needs-review';

// USER ROLE
export const ROLE_SUPPORT = 'support';
export const ROLE_CLIENT = 'client';

// ACTIONS STATUS
export const GET_STATUS = 'GET_STATUS';

// ACTIONS AUTH
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CLEAN_AUTH = 'CLEAN_AUTH';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const SET_AUTH_PROFILE = 'SET_AUTH_PROFILE';
export const FORGOT_PASS = 'FORGOT_PASS';
export const RECOVERY_PASS = 'RECOVERY_PASS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const MANAGE_2FA = 'MANAGE_2FA';
export const VERIFY_2FA = 'VERIFY_2FA';
export const DISABLE_2FA = 'DISABLE_2FA';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const GET_AVATAR = 'GET_AVATAR';
export const GET_INFO_DASHBOARD = 'GET_INFO_DASHBOARD';
export const SET_TIME_TFA = 'SET_TIME_TFA';
export const RESET_TFA = 'RESET_TFA';
export const RECOVERY_TFA_USER = 'RECOVERY_TFA_USER';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_WALLET = 'UPDATE_WALLET';
export const VERIFY_ACTIVATION_USER = 'VERIFY_ACTIVATION_USER';
export const GET_UPLINE = 'GET_UPLINE';
export const SET_USER_SUPPORT = 'SET_USER_SUPPORT';

// ACTIONS USER
export const USER_INFO = 'USER_INFO';
export const CLEAN_USER = 'CLEAN_USER';
export const USER_REGISTER = 'USER_REGISTER';
export const CONFIRM_USER_REGISTER = 'CONFIRM_USER_REGISTER';
export const SET_TEMPORAL = 'SET_TEMPORAL';
export const CLEAN_TEMPORAL = 'CLEAN_TEMPORAL';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const REGISTER_DATA_KYC = 'REGISTER_DATA_KYC';
export const ACTIVE_USER = 'ACTIVE_USER';
export const ACTIVE_USER_PLATFORM = 'ACTIVE_USER_PLATFORM';

// ACTIONS NAVS
export const SET_NAV_FROM = 'SET_NAV_FROM';
export const SET_TIME_ZONE = 'SET_TIME_ZONE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const CLEAN_NAV = 'CLEAN_NAV';
export const HIDDEN_INIT_TOUR = 'HIDDEN_INIT_TOUR';

export const FILTER_PLATFORM = 'FILTER_PLATFORM';

// GLOBALS ACTIONS
export const GET_NATIONALITIES = 'GET_NATIONALITIES';
export const GET_UNILEVEL_TREE = 'GET_UNILEVEL_TREE';

export const ENE = { code: '0', label: 'months.ene' };
export const FEB = { code: '1', label: 'months.feb' };
export const MAR = { code: '2', label: 'months.mar' };
export const ABR = { code: '3', label: 'months.apr' };
export const MAY = { code: '4', label: 'months.may' };
export const JUN = { code: '5', label: 'months.jun' };
export const JUL = { code: '6', label: 'months.jul' };
export const AGO = { code: '7', label: 'months.ago' };
export const SEP = { code: '8', label: 'months.sep' };
export const OCT = { code: '9', label: 'months.oct' };
export const NOV = { code: '10', label: 'months.nov' };
export const DIC = { code: '11', label: 'months.dic' };

export const MESES = [ENE, FEB, MAR, ABR, MAY, JUN, JUL, AGO, SEP, OCT, NOV, DIC];

export const LANGUAGE_ES = {
  code: 'es',
  label: 'language.languages.es',
};
export const LANGUAGE_EN = {
  code: 'en',
  label: 'language.languages.en',
};

export const LANGUAGES = [LANGUAGE_ES, LANGUAGE_EN];

export const TOKENS_FB_CAMPAIGN = {
  '01H6NE1WNCMKGV0FBNHKPVDPF0': 'ONE',
  '01H6NE1WNFXNQ7DQQ2HWBK1KFF': 'VIDA',
};

/* GENERATOR_INJECT_CONSTANTS */
