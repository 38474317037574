import _ from 'lodash';
import { axiosInstanceNormal } from './axios';

function urlWithParams(url, params) {
  if (params) {
    const urlParams = url.match(/{param}/g) || [];
    if (params instanceof Array) {
      params.forEach((value, index) => {
        if (index < _.size(urlParams)) {
          url = _.replace(url, '{param}', value);
        } else {
          url = `${url}/${value}`;
        }
      });
    } else {
      if (_.size(urlParams)) {
        url = _.replace(url, '{param}', params);
      } else {
        url = `${url}/${params}`;
      }
    }
  }
  return url;
}

/**
 * Call api post request.
 * @param {*} url
 * @param {*} obj
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiPost =
  (url, obj, params, sendProcessToken = true, loading = false) =>
  () => {
    return axiosInstanceNormal({
      method: 'post',
      url: urlWithParams(url, params),
      data: obj,
      headers: sendProcessToken
        ? {
            'Content-type': 'application/json',
            Authorization: sendProcessToken,
            loading,
          }
        : { 'Content-type': 'application/json', loading },
    })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((errorResponse) => {
        throw errorResponse.response;
      });
  };

/**
 * Call api GET request.
 * @param {*} url
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiGet =
  (url, params, sendProcessToken = true, loading = false) =>
  () => {
    try {
      return axiosInstanceNormal({
        method: 'get',
        url: urlWithParams(url, params),
        headers: sendProcessToken
          ? {
              'Content-type': 'application/json',
              Authorization: true,
              loading,
            }
          : { 'Content-type': 'application/json', loading },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };

/**
 * Call api GET request.
 * @param {*} url
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiGetFile =
  (url, params, sendProcessToken = true, loading = false) =>
  () => {
    try {
      return axiosInstanceNormal({
        method: 'get',
        url: urlWithParams(url, params),
        responseType: 'blob',
        headers: sendProcessToken
          ? {
              'Content-type': 'application/json',
              Authorization: true,
              loading,
            }
          : { 'Content-type': 'application/json', loading },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };

/**
 * Call api post request.
 * @param {*} url
 * @param {*} obj
 * @param {*} params
 * @param {*} sendProcessToken
 */
export const apiPut =
  (url, obj, params, sendProcessToken = true, loading = false) =>
  () => {
    return axiosInstanceNormal({
      method: 'put',
      url: urlWithParams(url, params),
      data: obj,
      headers: sendProcessToken
        ? {
            'Content-type': 'application/json',
            Authorization: sendProcessToken,
            loading,
          }
        : { 'Content-type': 'application/json', loading },
    })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((errorResponse) => {
        throw errorResponse.response;
      });
  };

export const apiDelete =
  (url, params, sendProcessToken = true, loading = false) =>
  () => {
    try {
      return axiosInstanceNormal({
        method: 'delete',
        url: urlWithParams(url, params),
        headers: sendProcessToken
          ? {
              'Content-type': 'application/json',
              Authorization: true,
              loading,
            }
          : { 'Content-type': 'application/json', loading },
      })
        .then((response) => {
          if (response.status && response.status === 200) {
            return response.data;
          }
        })
        .catch((errorResponse) => {
          return errorResponse;
        });
    } catch (error) {
      return error;
    }
  };
