import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Title, Button } from '@smartups-sl/react-basic-components';
import { ButtonActivationTerms } from './marketplace.styled';

const ActivationsTerms = (props) => {
  const { t } = useTranslation();
  const { cancelAction, onActive, platform, showTerms } = props;
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptPersonalData, setAcceptPersonalData] = useState(false);

  const handleOnChangeAcceptTerms = () => {
    setAcceptTerms(!acceptTerms);
  };
  const handleOnChangeAcceptPersonalData = () => {
    setAcceptPersonalData(!acceptPersonalData);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 marg-top-40px">
            <Title type="h3" variant="primary">{`${t('forms.marketplace.titleActivation')} ${
              platform.name
            }`}</Title>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{t('forms.marketplace.messages.activeServiceText')}</div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-10">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="acceptTerms"
                checked={acceptTerms}
                onChange={handleOnChangeAcceptTerms}
              />
              <label className="form-check-label" htmlFor="acceptTerms">
                {t('forms.marketplace.acceptTerms', { platform: platform.name })}
                {platform.urlTerms && (
                  <ButtonActivationTerms type="button" onClick={() => showTerms(platform.urlTerms)}>
                    {t('forms.marketplace.showTerms')}
                  </ButtonActivationTerms>
                )}
              </label>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-5">
          <div className="col-10">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="acceptPersonalData"
                checked={acceptPersonalData}
                onChange={handleOnChangeAcceptPersonalData}
              />
              <label className="form-check-label" htmlFor="acceptPersonalData">
                {t('forms.marketplace.acceptPersonalData', { platform: platform.name })}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Button
              type="button"
              className="btn btn-md btn-block"
              variant="secondary"
              onClick={() => cancelAction()}
            >
              {`${t('forms.marketplace.buttons.back')}`}
            </Button>
            <Button
              type="button"
              className="btn btn-md btn-block"
              variant="primary"
              onClick={() => onActive(acceptTerms, acceptPersonalData)}
            >
              {`${t('forms.marketplace.buttons.activate')}`}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

ActivationsTerms.propTypes = {
  cancelAction: PropTypes.func,
  onActive: PropTypes.func,
  showTerms: PropTypes.func,
};

export default ActivationsTerms;
