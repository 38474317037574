/* eslint-disable indent */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import View from './login.view';
import * as GeneralServices from '../../../services/GeneralServices';
import * as AuthService from '../../../services/AuthService';
import * as UserService from '../../../services/UserService';
import * as NavService from '../../../services/NavService';

import PATHS from '../../../constants/paths';
import { ROLE_CLIENT, KYC_STATUS_PENDING } from '../../../constants/index';
import { getAuthTokenSelector } from '../../../store/selectors/authSelector';
import { showDialog } from '@smartups-sl/react-utils-components';
import { show } from 'redux-modal';
import ErrorModal from '../../utils/modals/ErrorModal';
import PropTypes from 'prop-types';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.handleClear = this.handleClear.bind(this);
    this.handleClear();
  }

  componentDidMount() {
    const { idPlatform } = this.props;
    if (this.props.clientToken) {
      this.loginImpersonateClient();
    } else {
      if (!idPlatform) {
        this.controllogin();
      }
    }
  }

  loginImpersonateClient = async () => {
    const result = await GeneralServices.getItemTemp(this.props.clientToken);
    if (result && result.token) {
      const resultLogin = await AuthService.loginImpersonate(result.token);
      const resultInfo = await UserService.getAuthUserProfile(true);
      if (resultInfo?.data?.role) {
        this.props.history.push(PATHS.CLIENT_HOME);
      } else {
        this.props.history.push(PATHS.LOGIN);
      }
    } else {
      this.props.history.push(PATHS.LOGIN);
    }
  };

  controllogin = () => {
    const token = localStorage.getItem('smartToken');
    if (token) {
      this.confirmAccess(token);
    }
  };

  confirmAccess = async (token) => {
    await AuthService.confirmLocalLogin(token);
    const resultInfo = await UserService.getAuthUserProfile();
    if (resultInfo.data.userDuplicated) {
      this.props.history.push(PATHS.DUPLICATED);
    } else {
      if (resultInfo.data.role === ROLE_CLIENT) {
        if (
          resultInfo.data.passedKYC === KYC_STATUS_PENDING &&
          resultInfo.data.requiredKYC === 'REQUIRED' &&
          !resultInfo.data.kycUserUuid
        ) {
          this.props.history.push(PATHS.KYC);
        } else {
          this.props.history.push(PATHS.CLIENT_HOME);
        }
      } else {
        this.props.history.push(PATHS.LOGIN);
      }
    }
  };

  handleClear = () => {
    try {
      if (this.props.token) {
        // localStorage.removeItem('smartToken');
        AuthService.logout();
      }
      UserService.cleanUserData();
      NavService.setCleanNav();
    } catch (error) {
      console.error(error);
      this.props.history.push(PATHS.ERROR);
    }
  };

  handleSubmit = async (values) => {
    const { idPlatform } = this.props;
    try {
      const dataNav = { from: PATHS.LOGIN };
      if (idPlatform) {
        dataNav.idPlatform = idPlatform;
      }
      NavService.setFromPage(dataNav);
      const result = await AuthService.login(values, idPlatform);
      if (result && result.token) {
        const resultInfo = await UserService.getAuthUserProfile();
        if (resultInfo.data.userDuplicated) {
          this.props.history.push(PATHS.DUPLICATED);
        } else {
          localStorage.setItem('smartToken', result.token);

          // if (resultInfo.data.registerPayment) {
          //   const resultPayment = await PaymentService.getRegisterPaymentInfo([
          //     resultInfo.data.registerPayment,
          //   ]);
          //   if (resultPayment?.data?.status) {
          //     PaymentService.setStatusPayment(resultPayment?.data?.status);
          //   }
          // }
          if (idPlatform && result.externalToken) {
            window.location.href = `${result.uriRedirect}/${result.externalToken}`;
          } else if (result.accessNotAllowed) {
            const okAction = async () => {
              this.props.history.push(PATHS.MARKET_PLACE);
            };
            const cancelAction = async () => {
              localStorage.removeItem('smartToken');
              this.props.history.push(`/auth/login/${idPlatform}`);
            };
            showDialog(
              this.props.t('forms.login.dialog.accessNotAllowed.title'),
              this.props.t('forms.login.dialog.accessNotAllowed.text'),
              'warning',
              true,
              this.props.t('forms.login.dialog.accessNotAllowed.buttonOk'),
              false,
              okAction,
              this.props.t('forms.login.dialog.accessNotAllowed.buttonNo'),
              cancelAction
            );
          } else {
            if (resultInfo.data.role === ROLE_CLIENT) {
              if (
                resultInfo.data.passedKYC === KYC_STATUS_PENDING &&
                resultInfo.data.requiredKYC === 'REQUIRED' &&
                !resultInfo.data.kycUserUuid
              ) {
                this.props.history.push(PATHS.KYC);
              } else {
                this.props.history.push(PATHS.CLIENT_HOME);
              }
            } else {
              this.props.history.push(PATHS.LOGIN);
            }
          }
        }
      } else {
        const text = this.props.t('forms.login.errors.login-incorrect');
        showDialog(
          'Error',
          text,
          'error',
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          '#E83C7E',
          '#12374e'
        );
      }
    } catch (error) {
      if (
        error?.data?.error?.statusCode === 403 &&
        error?.data?.error?.code?.code === 'USER.LOGIN.REACHED_LIMIT_LOGIN'
      ) {
        const text = this.props.t('server.errors.user.login.reached_limit_login');
        this.props.show('ErrorModal', {
          text: text,
          time: error?.data?.error?.code?.date,
        });
      }
    }
  };

  handlerPlatformForgotPassword = (platform) => {
    const okAction = async () => {
      this.props.history.push(PATHS.FORGOT_PASSWORD);
    };
    const cancelAction = async () => {};
    showDialog(
      this.props.t('forms.login.dialog.forgotPassword.title'),
      this.props.t(`forms.login.dialog.forgotPassword.text_${platform}`),
      'info',
      true,
      this.props.t('forms.login.dialog.forgotPassword.buttonOk'),
      false,
      okAction,
      this.props.t('forms.login.dialog.forgotPassword.buttonNo'),
      cancelAction
    );
  };

  render() {
    return (
      <>
        <ErrorModal />
        <View
          onSubmit={this.handleSubmit}
          idPlatform={this.props.idPlatform}
          forgotPlatform={this.handlerPlatformForgotPassword}
        />
      </>
    );
  }
}

LoginContainer.propTypes = {
  idPlatform: PropTypes.string,
  clientToken: PropTypes.string,
};

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
});

export default withRouter(connect(mapStateToProps, { show })(withTranslation()(LoginContainer)));
