import styled from 'styled-components';

export const ButtonPlt = styled.button`
  /* border: 1px solid #12374e; */
  background-color: ${({ theme, platform }) =>
    platform && theme.colors[`${platform}Primary`]
      ? theme.colors[`${platform}Primary`]
      : '#26abe1'};
  color: #fff;

  &:hover {
    background-color: ${({ theme, platform }) =>
      platform && theme.colors[`${platform}Light`] ? theme.colors[`${platform}Light`] : '#26abe1'};
    color: #fff;
  }
`;

export const TextGtr = styled.div`
  color: #27ea86;
`;
