import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ImagePlatform, Author, BodyNews, ImageNews, CustomTiTle } from './detailsNews.styled';
import { Button } from '@smartups-sl/react-basic-components';

const DetailsNews = (props) => {
  const { news, gotoHome } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11 pt-5 pb-4">
          <CustomTiTle>{news?.title}</CustomTiTle>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-12 col-md-11 d-flex justify-content-start ">
          <ImageNews src={news?.imageUrl ? news.imageUrl : ''}></ImageNews>
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11">
          <BodyNews
            dangerouslySetInnerHTML={{
              __html: news?.body,
            }}
          />
        </div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11 d-flex justify-content-end ">
          <Author>{news?.author}</Author>
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-12 col-md-11 d-flex justify-content-end ">
          <ImagePlatform src={`/assets/images/news_${news?.platform}.png`}></ImagePlatform>
        </div>
      </div>
      <div className="row justify-content-center mt-5 mb-5">
        <div className="col-12 col-md-11">
          <Button
            type="button"
            className="btn btn-md btn-block"
            variant="secondary"
            onClick={() => {
              gotoHome();
            }}
          >
            {t('forms.news.buttons.back')}
          </Button>
        </div>
      </div>
    </>
  );
};

DetailsNews.propTypes = {
  news: PropTypes.object,
  gotoHome: PropTypes.func,
};

export default DetailsNews;
