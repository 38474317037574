import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import DataUniLevelTree from '../dashboard/DataUnilevelTree';
import SimpleInfo from '../dashboard/SimpleInfo';
import Sponsor from '../dashboard/Sponsor';
// import ProgressBar from '../dashboard/ProgressBar';
// // import AddressWallet from '../dashboard/AddressWallet';
// import SimpleAmountItem from '../dashboard/SimpleAmountItem';
// import GraphAmountItem from '../dashboard/GraphAmountItem';
// import UserLevel from '../dashboard/UserLevel';
// // import { Title } from '@smartups-sl/react-basic-components';
import { DashboardRow, DashBoardItem } from '@smartups-sl/react-utils-components';
import ListNews from '../dashboard/ListNews';
import {
  ImageBanner,
  ImageLogo,
  Text,
  Title,
  Icon,
  List,
  ButtonTour,
  ButtonFinishTour,
} from './clientHome.styled';
import Tour from 'reactour';
import * as NavServices from '../../../services/NavService';
import { getHiddenInitTourSelector } from '../../../store/selectors/navSelector';
import { useSelector } from 'react-redux';

const Home = (props) => {
  const { t } = useTranslation();
  const { urlSponsor, upline } = props;
  const hiddenInitTour = useSelector(getHiddenInitTourSelector);
  const [uplineUser, setUplineUser] = useState(upline?.nickname);
  const [isTourOpenInit, setIsTourOpenInit] = useState(!hiddenInitTour ? true : false);
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    if (upline) {
      setUplineUser(upline.nickname);
    }
  }, [upline]);

  const openTour = () => {
    setIsTourOpen(true);
  };

  const finishToutIni = async () => {
    await NavServices.setFinishInitTour();
    setIsTourOpenInit(false);
  };

  const closeTourInit = () => {
    setIsTourOpenInit(false);
  };

  const closeTour = () => {
    setIsTourOpen(false);
  };

  const tourConfigInit = [
    {
      selector: '[data-tut="reactour__welcome"]',
      content: () => (
        <div>
          <div className="row">
            <div className="col-12">
              <ImageLogo
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_header.png`}
              ></ImageLogo>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <Title className="text-center">{t('tour.title')}</Title>
              <Text className="mt-2 text-center">{t('tour.textIni')}</Text>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-center">
              <ButtonFinishTour type="button" onClick={() => finishToutIni()}>
                {t('tour.buttonFinish')}
              </ButtonFinishTour>
            </div>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__help"]',
      content: () => (
        <div>
          <div className="row">
            <div className="col-12">{t('tour.textIniDesc')}</div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-center">
              <ButtonFinishTour type="button" onClick={() => finishToutIni()}>
                {t('tour.buttonFinish')}
              </ButtonFinishTour>
            </div>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__help2"]',
      content: () => (
        <div>
          <div className="row">
            <div className="col-12">{t('tour.textIniDesc2')}</div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-center">
              <ButtonFinishTour type="button" onClick={() => finishToutIni()}>
                {t('tour.buttonFinish')}
              </ButtonFinishTour>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const tourConfig = [
    {
      selector: '[data-tut="reactour__welcome"]',
      content: () => (
        <>
          <div className="row">
            <div className="col-12">
              <strong>{t('tour.home.step0')}</strong>
            </div>
          </div>
          <div className="row">
            <div className="col-12">{t('tour.home.step00')}</div>
          </div>
        </>
      ),
    },
    {
      selector: '[data-tut="reactour__referer"]',
      content: () => <>{t('tour.home.step1')}</>,
    },
    {
      selector: '[data-tut="reactour__banner"]',
      content: () => <>{t('tour.home.step2')}</>,
    },
    {
      selector: '[data-tut="reactour__news"]',
      content: () => (
        <div>
          <div className="row">
            <div className="col-12">
              <Title className="text-center">{t('tour.home.step3.1')}</Title>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <Text className="">{t('tour.home.step3.2')}</Text>
              <Text className="mt-2">{t('tour.home.step3.3')}</Text>
            </div>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__menu"]',
      content: () => (
        <div>
          <div className="row">
            <div className="col-12">
              <Title className="text-center">{t('tour.home.step4.1')}</Title>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <Text className="">{t('tour.home.step4.2')}</Text>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <List>
                <li>
                  <div>
                    <Icon className="fas fa-home" />
                    <b>{t('home')}</b>
                  </div>
                  <div>{t('tour.home.step4.3')}</div>
                </li>
                <li className="mt-2">
                  <div>
                    <Icon className="fas fa-sitemap" />
                    <b>{t('user_tree')}</b>
                  </div>
                  <div>{t('tour.home.step4.4')}</div>
                </li>
                <li className="mt-2">
                  <div>
                    <Icon className="fas fa-desktop" />
                    <b>{t('market')}</b>
                  </div>
                  <div>{t('tour.home.step4.5')}</div>
                </li>
                <li className="mt-2">
                  <div>
                    <Icon className="fas fa-user-cog" />
                    <b>{t('settings')}</b>
                  </div>
                  <div>{t('tour.home.step4.6')}</div>
                </li>
                <li className="mt-2">
                  <div>
                    <Icon className="fa-solid fa-headset" />
                    <b>{t('support')}</b>
                  </div>
                  <div>{t('tour.home.step4.7')}</div>
                </li>
                <li className="mt-2">
                  <div>
                    <Icon className="fas fa-power-off" />
                    <b>{t('close_session')}</b>
                  </div>
                  <div>{t('tour.home.step4.8')}</div>
                </li>
              </List>
            </div>
          </div>
        </div>
      ),
    },
  ];

  // const disableBody = (target) => disableBodyScroll(target);
  // const enableBody = (target) => enableBodyScroll(target);

  const accentColor = '#26abe1';

  return (
    <>
      <Tour
        onRequestClose={closeTourInit}
        steps={tourConfigInit}
        isOpen={isTourOpenInit}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor={accentColor}
        // onAfterOpen={disableBody}
        // onBeforeClose={enableBody}
      />
      <Tour
        onRequestClose={closeTour}
        steps={tourConfig}
        isOpen={isTourOpen}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor={accentColor}
        // onAfterOpen={disableBody}
        // onBeforeClose={enableBody}
      />

      <div id="dashboard">
        <ButtonTour type="button" onClick={() => openTour()} data-tut="reactour__help">
          <i className="fa-solid fa-question"></i>
        </ButtonTour>
        {/* <DashboardRow>
        <DashBoardItem
          numColsXs={12}
          numColsMd={12}
          numColsLg={6}
          className="dashboardContainer50 dark-container"
        >
          <SimpleInfo text={uplineUser || 'No nickname'} title={t('patrocinador')} textHighlight />
        </DashBoardItem>
        <DashBoardItem
          numColsXs={12}
          numColsMd={12}
          numColsLg={6}
          className="dashboardContainer50 dark-container"
        >
          <Sponsor urls={urlSponsor} copyClipboard title={t('urlPatrocinio')} />
        </DashBoardItem>
      </DashboardRow> */}
        <div className="row mt-4 mb-4 mb-md-0" data-tut="reactour__referer">
          <div className="col-12 col-lg-6">
            <div className="row no-margin">
              <div className="dashboardContainer50 dark-container">
                <SimpleInfo
                  text={uplineUser || 'No nickname'}
                  title={t('patrocinador')}
                  textHighlight
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-4 mt-lg-0">
            <div className="row no-margin">
              <div className="col-12 dashboardContainer50 dark-container">
                <Sponsor urls={urlSponsor} copyClipboard title={t('urlPatrocinio')} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center mt-0 mt-md-4 mb-0 mb-md-4"
          data-tut="reactour__banner"
        >
          <div className="col-12 col-md-12 col-lg-10">
            <ImageBanner
              className="d-none d-md-block"
              src={`/assets/images/banner/${t('banner')}`}
              alt="Banner"
            />
          </div>
        </div>
        <DashboardRow className="mb-5" data-tut="reactour__news">
          <DashBoardItem
            numColsXs={12}
            numColsMd={12}
            numColsLg={12}
            className="dashboardContainerNews dark-container-hidden"
          >
            <ListNews />
          </DashBoardItem>
        </DashboardRow>
      </div>
    </>
  );
};

Home.propTypes = {
  urlSponsor: PropTypes.object,
  lang: PropTypes.string,
  upline: PropTypes.object,
};

export default Home;
