import { handleActions } from 'redux-actions';

import {
  CLEAN_AUTH,
  LOGIN_SUCCESS,
  UPDATE_TOKEN,
  SET_AUTH_PROFILE,
  UPDATE_PROFILE,
  SET_TIME_TFA,
  UPDATE_WALLET,
  SET_USER_SUPPORT,
} from '../../constants/index';

export const auth = handleActions(
  {
    [CLEAN_AUTH]: () => ({}),
    [LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      status: {
        ...state.status,
        token: action.payload.token,
        auh2fa: action.payload.auth2fa,
      },
    }),
    [SET_AUTH_PROFILE]: (state, action) => {
      const newInfo = { ...state.info };
      Object.entries(action.payload.data).forEach((element) => {
        newInfo[element[0]] = element[1];
      });
      if (action.payload.treeLevel) {
        newInfo.treeLevel = action.payload.treeLevel;
      }
      return {
        ...state,
        info: newInfo,
      };
    },
    [UPDATE_PROFILE]: (state, action) => {
      const newInfo = { ...state.info };
      Object.entries(action.payload).forEach((element) => {
        newInfo[element[0]] = element[1];
      });
      return {
        ...state,
        info: newInfo,
      };
    },
    [UPDATE_WALLET]: (state, action) => {
      const newInfo = { ...state.info.wallets };
      Object.entries(action.payload.data).forEach((element) => {
        if (newInfo[element[0]]) {
          newInfo[element[0]] = element[1];
        }
      });
      return {
        ...state,
        info: {
          ...state.info,
          wallets: newInfo,
        },
      };
    },
    [SET_USER_SUPPORT]: (state, action) => ({
      ...state,
      info: {
        ...state.info,
        role: action.payload.role,
      },
    }),
    [UPDATE_TOKEN]: (state, action) => ({
      ...state,
      status: {
        ...state.status,
        token: action.payload.token,
        auh2fa: action.payload.auh2fa,
      },
    }),
    [SET_TIME_TFA]: (state, action) => ({
      ...state,
      status: {
        ...state.status,
        tfaTime: action.payload.time,
      },
    }),
  },
  {}
);
