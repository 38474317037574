import styled from 'styled-components';

export const ContainerPage = styled.div`
  /* border: 1px solid #12374e; */
  /* width: 100%;
  background: linear-gradient(
    195deg,
    rgba(84, 101, 174, 1) 0%,
    rgba(101, 45, 144, 1) 35%,
    rgba(146, 39, 143, 1) 100%
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0px;
  min-height: calc(100vh - 87px); */
  margin: 0px;
`;

export const ImageLogo = styled.img`
  /* border: 1px solid #12374e; */
  width: 28%;
`;

export const TextRegister = styled.div`
  /* border: 1px solid #12374e; */
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  font-family: 'Saira Condensed Bold';
`;

export const SubmitButton = styled.button`
  /* border: 1px solid #12374e; */
  /* background-color: #000;
  color: #fff; */

  &:hover {
    /* background-color: #2b2b2b !important;
    color: #fff; */
  }
`;

export const ContainerDataImage = styled.div`
  width: 100%;
  position: absolute;
  top: 40px;
  right: 100px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const TextDataImage = styled.div`
  color: #fff;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28%;
  text-align: center;
  font-size: 1rem;
`;

export const NetShipping = styled.span`
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
`;
