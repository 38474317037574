import styled from 'styled-components';

export const RowHeaderStyle = styled.div`
  /* border: 1px solid #12374e; */
  background-color: ${({ theme, platform }) =>
    platform && theme.colors[`${platform}Light`] ? theme.colors[`${platform}Light`] : '#12374e'};
  color: #fff;
`;

export const CellStyle = styled.div`
  border: 1px solid
    ${({ theme, platform }) =>
      platform && theme.colors[`${platform}Light`] ? theme.colors[`${platform}Light`] : '#12374e'};
  align-items: center;
  align-items: center;
  justify-content: center;
  height: 50px;
  display: flex;
  font-size: 0.75rem;
`;

export const SubmitButton = styled.button`
  /* border: 1px solid #12374e; */
  background-color: ${({ theme, platform }) =>
    platform && theme.colors[`${platform}Primary`]
      ? theme.colors[`${platform}Primary`]
      : '#26abe1'};
  color: #fff;

  &:hover {
    background-color: ${({ theme, platform }) =>
      platform && theme.colors[`${platform}Light`] ? theme.colors[`${platform}Light`] : '#26abe1'};
    color: #fff;
  }
`;
