import styled from 'styled-components';

export const LabelStatus = styled.label`
  color: #ff438b;
  font-size: 0.85rem;
  margin-left: 10px;
`;

export const SwitchStatus = styled.input`
  background-color: ${({ statusCheck }) =>
    statusCheck ? '#ff438b !important' : '#fff !important'};
  border-color: ${({ statusCheck }) => (statusCheck ? '#ff438b !important' : '#bfbfbf !important')};
  box-shadow: rgb(74 74 104 / 20%) 0px 2px 2px 0px inset !important;
  /* width: 3rem !important; */
`;

export const ImagePlatform = styled.img`
  max-height: 70px;
  filter: ${({ active }) => (active ? 'none' : 'url(filters.svg#grayscale)')}; /* Firefox 3.5+ */
  filter: ${({ active }) => (active ? 'none' : 'gray')}; /* IE5+ */
  -webkit-filter: ${({ active }) => (active ? 'none' : 'grayscale(1)')};
`;

export const ButtonPlatform = styled.button`
  background: transparent !important;
  border: ${({ selected }) => (selected ? '2px solid #ff438b' : 'none')} !important;

  border-radius: 100px;
  padding: 8px !important;
  /* Webkit Nightlies & Chrome Canary */
  /* -webkit-transition: all 0.5s ease-in-out; */

  &:hover {
    border: 2px solid #ff438b !important;
  }
`;
