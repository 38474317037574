import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { InputTextFormik, InputCheckFormik } from '@smartups-sl/react-forms-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { dataModel, validationDataModel, setInitialValues } from './register.data.form';
import { Button } from '@smartups-sl/react-basic-components';
import { ContainerTitle, CustomTiTle } from '../../../design/styleComponents/globalComponents';
import { AlertText } from './register.styled';

const RegisterForm = (props) => {
  const { t, handleSubmit, handleShowTermAndConditions, handleOnchangeCaptcha, activateToken } =
    props;
  return (
    <>
      <ContainerTitle className="row justify-content-center">
        <div className="col-10 py-5">
          <CustomTiTle type="h3" variant="white">
            {activateToken ? t('forms.register.preregister') : t('forms.register.title')}
          </CustomTiTle>
        </div>
      </ContainerTitle>

      <div className="row justify-content-center">
        <div className="col-11 col-md-8 col-lg-6 py-5">
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            {/* {activateToken && (
              <div className="row">
                <div className="col-12">
                  <AlertText>{t('forms.register.alertAccount')}</AlertText>
                </div>
              </div>
            )} */}
            <div className="row">
              <div className="col-12">
                <Field
                  id="email"
                  className="form-control"
                  labelField={t('forms.register.labels.email')}
                  name="email"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                  readOnly={activateToken ? true : false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="password"
                  className="form-control"
                  labelField={t('forms.register.labels.password')}
                  name="password"
                  component={InputTextFormik}
                  fieldType="password"
                  showHidePassword
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="passwordConfirm"
                  className="form-control"
                  labelField={t('forms.register.labels.passwordConfirm')}
                  name="passwordConfirm"
                  component={InputTextFormik}
                  fieldType="password"
                  showHidePassword
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="termAndConditions"
                  labelField={t('forms.register.labels.termAndConditions')}
                  name="termAndConditions"
                  component={InputCheckFormik}
                  withLink={handleShowTermAndConditions}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 recaptcha text-center">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleOnchangeCaptcha}
                />
              </div>
            </div>
            <div className="bg-default content-box text-center">
              <Button type="submit" className="btn btn-lg" variant="primary">
                {activateToken
                  ? t('forms.register.buttons.confirm')
                  : t('forms.register.buttons.register')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleShowTermAndConditions: PropTypes.func,
  handleOnchangeCaptcha: PropTypes.func,
  activateToken: PropTypes.string,
};

const RegisterFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'RegisterForm',
})(withTranslation()(RegisterForm));

export default RegisterFormF;
