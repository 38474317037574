import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ViewTFAInfo from '../tfa/tfa.view';
import Security from './manageSecurity.view';
import * as AuthServices from '../../../services/AuthService';
import * as UserService from '../../../services/UserService';
// import PATHS from '../../../constants/paths';
import { getAuthInfoSelector } from '../../../store/selectors/authSelector';
import { showDialog } from '@smartups-sl/react-utils-components';
import PATHS from '../../../constants/paths';

class SecurityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTFA: undefined,
    };
  }

  handleKYCManage = async () => {
    this.props.history.push(PATHS.KYC_VERIFY);
  };

  handleTFAManage = async () => {
    if (this.props.info.tfaEnabled) {
      let okAction = undefined;
      okAction = () => {
        this.setState({ dataTFA: 'disable' });
      };

      showDialog(
        this.props.t('forms.security.messages.disable'),
        this.props.t('forms.security.messages.question.disable'),
        'info',
        true,
        'Yes',
        false,
        okAction,
        'No',
        undefined,
        '#E83C7E',
        '#12374e'
      );
    } else {
      let okAction = undefined;
      okAction = async () => {
        const result = await AuthServices.enableTwoFA();
        if (result && result.tfacode) {
          this.setState({ dataTFA: result });
        }
      };

      showDialog(
        this.props.t('forms.security.messages.enable'),
        this.props.t('forms.security.messages.question.enable'),
        'info',
        true,
        'Yes',
        false,
        okAction,
        'No',
        undefined,
        '#E83C7E',
        '#12374e'
      );
    }
  };

  verifyTfaCode = async (values) => {
    const obj = {
      tfacode: values.twofa,
    };
    const result = await AuthServices.verifyTfaToken(obj);
    if (result?.token) {
      UserService.getAuthUserProfile();
      this.setState({ dataTFA: undefined });
    }
  };

  disableTfa = async (values) => {
    const obj = {
      tfacode: values.twofa,
    };
    const result = await AuthServices.disableTwoFA(obj);
    if (result && result.tfaEnabled === false) {
      this.setState({ dataTFA: undefined });
    }
  };

  handleRefreshKYC = async () => {
    const result = await UserService.getAuthUserProfile();
    let icon = 'warning';
    if (result && result.data) {
      if (result.data.userDuplicated) {
        this.props.history.push(PATHS.DUPLICATED);
      } else {
        const status = this.props.t(
          `forms.kyc.status.${result.data.passedKYC ? result.data.passedKYC : 'pending'}`
        );
        if (status === 'approved') {
          icon = 'success';
        }
        showDialog(
          this.props.t('forms.security.messages.kycCheck'),
          this.props.t('forms.security.messages.kycCheckText', { status }),
          icon,
          false,
          'OK',
          false,
          undefined,
          'No',
          undefined,
          '#E83C7E',
          '#12374e'
        );
      }
    }
  };

  render() {
    return (
      <>
        {this.state.dataTFA ? (
          <ViewTFAInfo
            fromManage
            dataTfa={this.state.dataTFA || {}}
            onSubmit={
              this.state.dataTFA && this.state.dataTFA === 'disable'
                ? this.disableTfa
                : this.verifyTfaCode
            }
          />
        ) : (
          <Security
            fromManage
            info={this.props.info || {}}
            onManageTFA={this.handleTFAManage}
            onManageKYC={this.handleKYCManage}
            onRefreshKYC={this.handleRefreshKYC}
          />
        )}
      </>
    );
  }
}

SecurityContainer.propTypes = {};

const mapStateToProps = (state) => ({
  info: getAuthInfoSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(SecurityContainer)));
