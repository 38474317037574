import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 70px;
  background: ${(props) => (props.type === 'login' ? 'none' : props.theme.header.backgroundColor)};
  border-bottom: ${({ theme, idPlatform, type }) =>
    type === 'login'
      ? '0px'
      : `1px solid ${
          idPlatform ? theme.platforms[`${idPlatform}border`] : theme.header.borderColor
        }`};
  -webkit-box-shadow: ${(props) =>
    props.type === 'login' ? 'none' : '0px 3px 19px 0px rgba(0, 0, 0, 0.35)'};
  box-shadow: ${(props) =>
    props.type === 'login' ? 'none' : '0px 3px 19px 0px rgba(0, 0, 0, 0.35)'};
`;

export const Logo = styled.img`
  height: 44px;
`;

export const LogoMobile = styled.img`
  height: 45px;
`;

export const SidebarButtonContainer = styled.div`
  display: flex;
  outline: 0px auto !important;
  color: #fff;
`;

export const SidebarButton = styled.button`
  outline: 0px auto !important;
  color: #fff;
  font-size: 1.5rem;
  padding: 0px 5px 0px 15px;
  background: transparent;
  border: 0px;
  margin-top: 2px;
`;
