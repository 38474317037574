import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as AuthService from '../../../../services/AuthService';
import PATHS from '../../../../constants/paths';
import 'rc-menu/assets/index.css';
import { IconStyled, ButtonOption, Logo, OffCanvasHeader, NavLink } from './styled';
import { showDialog } from '@smartups-sl/react-utils-components';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  handlerSelectOption = async (key) => {
    const { nickname, t } = this.props;

    switch (key) {
      case 'home':
        this.props.history.push(PATHS.CLIENT_HOME);
        break;
      case 'my_profile':
        this.props.history.push(PATHS.MY_PROFILE);
        break;
      case 'unilevelTree':
        if (nickname) {
          this.props.history.push(PATHS.UNI_TREENODES);
        } else {
          showDialog(
            t('unilevel'),
            t('nickname_required'),
            'warning',
            false,
            'OK',
            false,
            undefined,
            undefined,
            undefined,
            '#E83C7E',
            '#12374e'
          );
        }
        break;
      case 'security':
        this.props.history.push(PATHS.SECURITY);
        break;
      case 'market':
        this.props.history.push(PATHS.MARKET_PLACE);
        break;
      case 'settings':
        this.props.history.push(PATHS.MY_PROFILE);
        break;
      case 'close_session':
        localStorage.removeItem('smartToken');
        await AuthService.logout();
        this.props.history.push(PATHS.LOGIN);
        break;
      default:
        break;
    }
  };

  render() {
    const { t, offCanvas } = this.props;

    return (
      <>
        <ul className="nav flex-column text-white w-100">
          {offCanvas && (
            <OffCanvasHeader className="offcanvas-header">
              {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5> */}
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </OffCanvasHeader>
          )}
          <a
            href={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
              process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
            }/client/home`}
            className="nav-link my-2 text-center"
          >
            <Logo
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_login.png`}
              alt="logo"
              className="logo"
              offCanvas={offCanvas}
            />
          </a>
          <NavLink href="#" className="nav-link">
            <span className="mx-2">
              <ButtonOption
                onClick={() => {
                  this.handlerSelectOption('home');
                }}
              >
                <IconStyled className="fas fa-tachometer-alt" />
                {t('dashboard')}
              </ButtonOption>
            </span>
          </NavLink>
          <NavLink href="#" className="nav-link">
            <span className="mx-2">
              <ButtonOption
                onClick={() => {
                  this.handlerSelectOption('user_tree');
                }}
              >
                <IconStyled className="fas fa-sitemap" />
                {t('user_tree')}
              </ButtonOption>
            </span>
          </NavLink>
          <NavLink href="#" className="nav-link">
            <span className="mx-2">
              <ButtonOption
                onClick={() => {
                  this.handlerSelectOption('market');
                }}
              >
                <IconStyled className="fas fa-desktop" />
                {t('market')}
              </ButtonOption>
            </span>
          </NavLink>
          <NavLink href="#" className="nav-link">
            <span className="mx-2">
              <ButtonOption
                onClick={() => {
                  this.handlerSelectOption('settings');
                }}
              >
                <IconStyled className="fas fa-user-cog" />
                {t('settings')}
              </ButtonOption>
            </span>
          </NavLink>
        </ul>
        <span href="#" className="nav-link w-100 mb-2">
          <ButtonOption
            onClick={() => {
              this.handlerSelectOption('close_session');
            }}
          >
            <IconStyled className="fas fa-power-off" />
            {t('close_session')}
          </ButtonOption>
        </span>
      </>
    );
  }
}

Sidebar.propTypes = {
  nickname: PropTypes.string,
  offCanvas: PropTypes.bool,
};

export default withRouter(withTranslation()(Sidebar));
