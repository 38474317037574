import styled from 'styled-components';
import { Link } from '@smartups-sl/react-basic-components';

export const BodyContainer = styled.div`
  min-height: ${({ idPlatform }) =>
    idPlatform === 'gtrader' ? `100vh` : 'calc(100vh - 50px) !important'};
  background-color: ${({ theme }) => (theme ? theme.body.backgroundColor : '#fff')};
  /* background-image: ${({ theme, idPlatform }) =>
    idPlatform
      ? `url('${process.env.PUBLIC_URL}/assets/themes/${process.env.REACT_APP_THEME}/images/background_${idPlatform}.png') !important`
      : 'null'}; */
`;

export const SideBarContainer = styled.div`
  width: 180px;
  height: 100%;
  position: fixed;
  background-color: ${({ theme }) => (theme ? theme.sidebar.backgroundColor : '#fff')};
  transition: 0.5s;
`;

export const OffCanvas = styled.div`
  top: 0;
  left: 0;
  width: 180px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => (theme ? theme.sidebar.backgroundColor : '#fff')};
  transform: translateX(-100%);
`;

export const Footer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: ${({ platform, theme }) => (platform ? theme.platforms[platform] : '#26abe1')};
  color: #fff;
`;

export const LinkTerms = styled(Link)`
  margin-right: 20px;
  color: #fff;
  margin-bottom: 15px;

  &:hover {
    color: #fff;
  }
`;
