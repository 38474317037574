import React from 'react';
import PreRegister from '../../../components/user/preRegister';
import Layout from '../../../components/layouts/centerLayout';

const PreRegisterPage = () => {
  return (
    <Layout
      showLanguage={false}
      showUserMenu={false}
      body={
        <>
          <PreRegister />
        </>
      }
    />
  );
};

PreRegisterPage.propTypes = {};

export default PreRegisterPage;
