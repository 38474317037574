import React, { useEffect, useState } from 'react';
import * as GeneralServices from '../../../services/GeneralServices';
import DetailsNews from './detailsNews.view';
import { useHistory } from 'react-router-dom';
import PATHS from '../../../constants/paths';

const DetailsNewsContainer = (props) => {
  const [news, setNews] = useState(null);
  let history = useHistory();

  useEffect(() => {
    if (props.uuid) {
      GeneralServices.getNews(props.uuid).then((result) => {
        if (result) {
          setNews(result);
        }
      });
    }
  }, [props.uuid]);

  const handlerGotoHome = async () => {
    history.push(PATHS.CLIENT_HOME);
  };

  return <DetailsNews news={news} gotoHome={handlerGotoHome} />;
};

DetailsNewsContainer.propTypes = {};

export default DetailsNewsContainer;
