import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  CenterContainer,
  ButtonsContainer,
} from '../../../design/styleComponents/globalComponents';
import { Button, Link, Text, Title } from '@smartups-sl/react-basic-components';

const SendedForgotPassword = (props) => {
  const { t, onResend, onBackLogin } = props;
  return (
    <CenterContainer className="row justify-content-center align-items-center">
      <div className="col-10 col-sm-8 col-lg-5">
        <Title type="h1" className="text-center" variant="secondary">
          {t('forms.forgotPassword.title')}
        </Title>
        <div className="row">
          <div className="col-12">
            <Text type="p" className="alert alert-dark">
              {t('forms.forgotPassword.messages.sended')}
            </Text>
          </div>
        </div>
        <ButtonsContainer className="text-center">
          <Button
            type="button"
            className="btn btn-lg btn-block"
            variant="primary"
            onClick={() => {
              onBackLogin();
            }}
          >
            {t('forms.forgotPassword.buttons.finish')}
          </Button>
        </ButtonsContainer>
        <div className="row">
          <div className="col-12 text-center">
            <Link
              className="show-pane-forgot-password"
              type="normal"
              variant="secondary"
              onClick={() => {
                onResend();
              }}
            >
              <i className="fas fa-long-arrow-alt-left"></i>{' '}
              {t('forms.forgotPassword.buttons.reSend')}
            </Link>
          </div>
        </div>
      </div>
    </CenterContainer>
  );
};

SendedForgotPassword.propTypes = {
  onResend: PropTypes.func,
  onBackLogin: PropTypes.func,
};

export default withTranslation()(SendedForgotPassword);
