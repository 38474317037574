import React from 'react';
import PropTypes from 'prop-types';
import { SimpleTree } from '@smartups-sl/react-utils-components';
import UniLevelTreeNode from './components/uniLevelTreeNode';
import SimpleTreeNode from './components/simpleTreeNode';
import { ContainerTitle, CustomTiTle } from '../../../design/styleComponents/globalComponents';
import { SwitchStatus, LabelStatus, ImagePlatform, ButtonPlatform } from './unilevelTree.styled';
import { checkBootstrapSizeBreak } from '../../../utils/device/checkDevice';
import { Tree } from '@smartups-sl/react-component-library';


const coreTheme = {
  dark: {
    text: '#fff',
    bg: '#26abe1',
    highlight: '#1d95c5',
    decal: '#fff',
    accent: '#fe0',
  },
};

const unilevelTree = (props) => {
  const {
    t,
    data,
    getNodes,
    parentNode,
    nodeVisited,
    viewType,
    onChangeType,
    platforms,
    platformSelected,
    onChangePlatform,
  } = props;
  const breakDevice = checkBootstrapSizeBreak();
  let imageFinal = '';
  if (parentNode?.avatarUrl) {
    imageFinal = parentNode.avatarUrl;
  } else {
    imageFinal = `/assets/themes/${process.env.REACT_APP_THEME}/images/no-image.png`;
  }
  const parent = {
    userUuid: parentNode?.uuid,
    name: `${parentNode?.nickname || ''}`,
    email: `${parentNode?.email || ''}`,
    image: imageFinal,
    networkActive:
      parentNode[`${platformSelected?.prefix}Status`] === 'ACTIVE' ||
      platformSelected?.prefix === 'smartnet'
        ? true
        : false,
  };

  const hasActive = (platform) => {
    let active = false;
    if (
      platform.prefix === 'smartnet' ||
      (parentNode[`${platform.prefix}Status`] &&
        parentNode[`${platform.prefix}Status`] === 'ACTIVE')
    ) {
      active = true;
    }
    return active;
  };

  const renderSelectorPlatform = () => {
    return platforms.map((platform) => {
      if (platform.status === 'ACTIVE' || platform.prefix === 'smartnet') {
        return (
          <div key={platform.uuid} className="col-2">
            <ButtonPlatform
              type="button"
              className=""
              selected={platformSelected.uuid === platform.uuid}
              onClick={() => onChangePlatform(platform)}
            >
              <ImagePlatform
                prefix={platform.prefix}
                active={hasActive(platform) === true}
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${platform.prefix}_uni.png`}
                alt="logo"
                title={`Ir a ${platform.name}`}
              />
            </ButtonPlatform>
          </div>
        );
      }
    });
  };

  const generateData = () => {
    const nodes = [];
    if (parentNode && data) {
      const parent = {
        label: parentNode?.email,
        id: parentNode?.uuid,
        parentId: null,
        items: null,
        info: parentNode,
      };
      nodes.push(parent);
      data.forEach((node) => {
        const temp = {
          label: node.email,
          id: node.userUuid,
          parentId: node.fatherUuid,
          items: null,
          info: node,
        };
        nodes.push(temp);
      });
    }
    return nodes;
  };

  return (
    <>
      <ContainerTitle className="row justify-content-center">
        <div className="col-12 col-md-10 py-5">
          <CustomTiTle type={breakDevice < 2 ? 'h5' : 'h3'} variant="white">
            {t('unilevel')}
          </CustomTiTle>
        </div>
      </ContainerTitle>

      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-10 col-lg-10 text-center">
          <div className="alert alert-dark" role="alert">
            {t('unilevelTreeAlert')}
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-10 col-lg-10 text-center">
          {platforms && platforms.length > 0 && (
            <div className="row justify-content-center">{renderSelectorPlatform()}</div>
          )}
        </div>
      </div>
      <div className="row justify-content-center mt-4">
        <div className="col-12 col-md-5 col-lg-5 marg-btm-1rem text-center">
          {/* <div>{t('simpleTree')}</div> */}
          <div className="serviceStatusContainer form-switch">
            <SwitchStatus
              statusCheck={viewType === 'rama'}
              className="serviceStatus form-check-input"
              type="checkbox"
              id="selectUnilevel"
              onChange={(event) => {
                if (event.target.checked) {
                  onChangeType('rama');
                } else {
                  onChangeType('simple');
                }
              }}
              checked={viewType === 'rama'}
            />
            <LabelStatus className="form-check-label">{t('complexTree')}</LabelStatus>
          </div>
        </div>
      </div>
      {viewType === 'simple' ? (
        <SimpleTree
          customTheme={coreTheme}
          nodes={generateData()}
          icon="fas fa-chevron-right"
          onSelect={getNodes}
        >
          <SimpleTreeNode firstLevel={parentNode.treeLevel}></SimpleTreeNode>
        </SimpleTree>
      ) : (
        <Tree
          nameParentId="fatherUuid"
          idNode="userUuid"
          data={data && data.length > 0 ? data : [parent]}
          onSelectNode={getNodes}
          parentNode={parent}
          idNodeTitle="name"
          idNodeText="email"
          idImageNode="image"
          connectorColor="#26abe1"
          nodeVisited={nodeVisited}
        >
          <UniLevelTreeNode
            firstData={data && data.length > 0 ? data[0] : [parent]}
            firstLevel={parentNode.treeLevel}
            nodeVisited={nodeVisited}
          />
        </Tree>
      )}
    </>
  );
};

unilevelTree.propTypes = {
  data: PropTypes.array,
  getNodes: PropTypes.func,
  parentNode: PropTypes.object,
  t: PropTypes.any,
  nodeVisited: PropTypes.array,
  onChangeType: PropTypes.func,
  viewType: PropTypes.string,
  platforms: PropTypes.any,
  platformSelected: PropTypes.object,
  onChangePlatform: PropTypes.func,
};

export default unilevelTree;
