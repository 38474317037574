import { getGtraderPixel, getOnePixel, getVidaPixel } from '../campaingPixel';
import { TOKENS_FB_CAMPAIGN } from '../constants/index';
/**
 * Track register
 * @param {*} values
 */
export const registerTrack = async (platform, extra, values) => {
  try {
    let Pixel;

    switch (platform) {
      case 'gtrader':
        Pixel = getGtraderPixel();
        break;
      case 'evolution':
        if (TOKENS_FB_CAMPAIGN[extra] && TOKENS_FB_CAMPAIGN[extra] === 'ONE') {
          Pixel = getOnePixel();
        } else if (TOKENS_FB_CAMPAIGN[extra] && TOKENS_FB_CAMPAIGN[extra] === 'VIDA') {
          Pixel = getVidaPixel();
        }

        break;

      default:
        break;
    }

    if (Pixel) {
      Pixel.fbq('track', 'CompleteRegistration');
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

/**
 * Track Lead
 * @param {*} values
 */
export const trackActivate = async (platform) => {
  try {
    let Pixel;

    switch (platform) {
      case 'gtrader':
        Pixel = getGtraderPixel();
        break;

      default:
        break;
    }

    if (Pixel) {
      Pixel.fbq('track', 'CompleteRegistration');
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};
