/* eslint-disable indent */
import React from 'react';
import RegisterPlt from '../../../components/user/registerPlt';
import Layout from '../../../components/layouts/simpleLayout';
import PropTypes from 'prop-types';

const RegisterPltPage = (props) => {
  return (
    <Layout
      idPlatform={props.platform}
      showUserMenu={false}
      body={
        <>
          <RegisterPlt sponsorUId={props.sponsorUId} platform={props.platform} />
        </>
      }
    />
  );
};

RegisterPltPage.propTypes = {
  sponsorUId: PropTypes.string,
};

export default RegisterPltPage;
