import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 50vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Text = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: #e83c7e;
`;

export const DostContainer = styled.div`
  width: fit-content;
  background: #26abe1;
  padding: 0px 50px;
  border-radius: 20px;
`;
