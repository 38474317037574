import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Title, Button, Text } from '@smartups-sl/react-basic-components';
import {
  KYC_STATUS_APPROVED,
  KYC_STATUS_DECLINED,
  KYC_STATUS_PENDING,
  KYC_STATUS_NEED_REVIEW,
} from '../../../constants/index';
import { ButonAction } from './manageSecurity.styled';
import { AbilityContext, Can } from '../../permissions/Can';
import { USER_PERMISSIONS } from '../../permissions/rolesPermission';

const Security = (props) => {
  const { t } = useTranslation();
  const { info, onManageTFA, onManageKYC, onRefreshKYC, fromManage } = props;
  const context = useContext(AbilityContext);
  const hasUpdate = context.can(USER_PERMISSIONS.UPDATE_SECURITY);

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-7 col-lg-8">
          <div className="row">
            <div className="col-12 mb-2">
              <Title type="h3" variant="secondary">
                {fromManage ? <u>{t('forms.security.title')}</u> : t('forms.security.title')}
              </Title>
            </div>
          </div>
          <div className="row margBottom15">
            {/* <div className="col-12 col-sm-6 align-self-center levelItem mb-3">
                <i className="fas fa-angle-right"></i>
                {t('forms.security.labels.2faActivate')}
              </div> */}
            <div className="col-12 col-md- col-lg-12 text-center mb-3">
              {info.tfaEnabled ? (
                <ButonAction type="button" variant="primary" size="sm" expand disabled>
                  {t('forms.security.buttons.isEnable')}
                </ButonAction>
              ) : (
                <ButonAction
                  type="button"
                  variant="primary"
                  size="sm"
                  expand
                  onClick={() => (hasUpdate ? onManageTFA() : '')}
                  disabled={!hasUpdate}
                >
                  {t('forms.security.buttons.enable')}
                </ButonAction>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-2 mt-5">
              <Title type="h3" variant="secondary">
                {fromManage ? (
                  <u>{t('forms.security.labels.kyc')}</u>
                ) : (
                  t('forms.security.labels.kyc')
                )}
              </Title>
            </div>
          </div>
          {/* {info.requiredKYC !== 'NOT_REQUIRED' && ( */}
          <div className="row margBottom15">
            {/* <div className="col-12 col-sm-6 align-self-center levelItem mb-3">
                <i className="fas fa-angle-right"></i>
                {t('forms.security.labels.kyc')}
              </div> */}
            {info.passedKYC === KYC_STATUS_PENDING && info.kycUserUuid && (
              <div className="col-12 col-md-12 col-lg-12 text-center mb-3">
                <ButonAction
                  type="button"
                  variant="primary"
                  size="sm"
                  expand
                  onClick={() => (hasUpdate ? onRefreshKYC() : '')}
                  disabled={!hasUpdate}
                >
                  {t('forms.security.buttons.check')}
                </ButonAction>
              </div>
            )}
            <div className="col-12 col-md-12 col-lg-12 text-center mb-3">
              {info.passedKYC === KYC_STATUS_APPROVED ||
              info.passedKYC === KYC_STATUS_DECLINED ||
              info.passedKYC === KYC_STATUS_NEED_REVIEW ||
              (info.passedKYC === KYC_STATUS_PENDING && info.kycUserUuid) ? (
                <ButonAction type="button" variant="primary" size="sm" expand disabled>
                  {info.passedKYC === KYC_STATUS_APPROVED &&
                    t('forms.security.buttons.kycVerified')}
                  {info.passedKYC === KYC_STATUS_DECLINED && t('forms.security.buttons.declined')}
                  {info.passedKYC === KYC_STATUS_PENDING &&
                    info.kycUserUuid &&
                    t('forms.security.buttons.pending')}
                  {info.passedKYC === KYC_STATUS_NEED_REVIEW &&
                    t('forms.security.buttons.needReview')}
                </ButonAction>
              ) : (
                <ButonAction
                  type="button"
                  variant="primary"
                  size="sm"
                  expand
                  onClick={() => (hasUpdate ? onManageKYC() : '')}
                  disabled={!hasUpdate}
                >
                  {/* {t('forms.security.buttons.disbaled')} */}
                  {t('forms.security.buttons.verify')}
                </ButonAction>
              )}
            </div>
          </div>
          {/* )} */}

          {/* {info.requiredKYC !== 'NOT_REQUIRED' && ( */}
          {info.passedKYC !== KYC_STATUS_APPROVED && (
            <div className="row">
              <div className="col-12 note">
                <Text type="p" className="marg-btm-1rem" variant="yellow">
                  {t('forms.security.messages.kyc')}
                </Text>
              </div>
            </div>
          )}

          {/* )} */}
        </div>
      </div>
    </>
  );
};

Security.propTypes = {
  info: PropTypes.object,
  onManageTFA: PropTypes.func,
  onManageKYC: PropTypes.func,
  onRefreshKYC: PropTypes.func,
  fromManage: PropTypes.bool,
};

export default Security;
