import React from 'react';
import Header from '../commons/header';
import { CenterPageContent, Footer, LinkTerms } from './styled';
import { useTranslation } from 'react-i18next';

const CenterLayout = ({ ...prop }) => {
  const { t, i18n } = useTranslation();
  let namePageLegal = '';
  let namePagePolicy = '';
  if (i18n.language === 'es') {
    namePageLegal = 'es/legal.html';
    namePagePolicy = 'es/politicas.html';
  } else if (i18n.language === 'en') {
    namePageLegal = 'en/legal.html';
    namePagePolicy = 'en/policy.html';
  } else {
    namePageLegal = 'en/legal.html';
    namePagePolicy = 'en/policy.html';
  }

  let urlLegal = '';
  let urlPolicy = '';

  if (!prop.idPlatform) {
    urlLegal = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
      process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
    }/docs/${namePageLegal}`;

    urlPolicy = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
      process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
    }/docs/${namePagePolicy}`;
  } else {
    urlLegal = t(`urls.${prop.idPlatform}.legal`);
    urlPolicy = t(`urls.${prop.idPlatform}.privacy`);
  }

  return (
    <div id="sb-site">
      <div id="page-wrapper-login" className="container-fluid">
        <Header
          type="login"
          showUserMenu={prop.showUserMenu !== undefined ? prop.showUserMenu : undefined}
          showLanguage={prop.showLanguage !== undefined ? prop.showLanguage : undefined}
        />
        <CenterPageContent id="page-content">{prop.body}</CenterPageContent>
      </div>
      <Footer platform={prop.idPlatform}>
        <div>
          <LinkTerms variant="primary" to={urlPolicy} target="_blank">
            {t('policy')}
          </LinkTerms>
          <LinkTerms variant="primary" to={urlLegal} target="_blank">
            {t('legal')}
          </LinkTerms>
        </div>
        <div>{prop.idPlatform ? t(`reserved.${prop.idPlatform}`) : t('reserved.smartnet')}</div>
      </Footer>
    </div>
  );
};
export default CenterLayout;
