import styled from 'styled-components';
import { Link } from '@smartups-sl/react-basic-components';

export const CenterPageContent = styled.div`
  min-height: calc(100vh - 120px);
`;

export const Footer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: ${({ platform, theme }) => (platform ? theme.platforms[platform] : '#26abe1')};
  color: #fff;
`;

export const LinkTerms = styled(Link)`
  margin-right: 20px;
  color: #fff;
  margin-bottom: 15px;

  &:hover {
    color: #fff;
  }
`;
