import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { Row, ColNopadding, NodeContainer, Arrow, ColArrow } from './styled';
import { formatMoney } from '../../../../../utils/formats/numbers';
const TreeNode = (props) => {
  const { node, firstLevel, nodeVisited, firstData } = props;
  // const { t } = useTranslation();
  const level1 = firstLevel + 1;
  const firstLevelType = firstData.level % 2 === 0 ? 'par' : 'impar';
  const currentTypeLevel = node.level % 2 === 0 ? 'par' : 'impar';
  const visited = nodeVisited.includes(node.userUuid);

  const showArrow = firstLevelType !== currentTypeLevel;
  const noImage = `/assets/themes/${process.env.REACT_APP_THEME}/images/no-image.png`;
  return (
    <div id={node.userUuid} className={`node-hidden customNode`}>
      <NodeContainer className="nodeAction">
        <Row className="row align-items-center">
          {node.image && node.image.length > 0 ? (
            <ColNopadding className="col-4">
              <img alt="Avatar" src={node.image}></img>
            </ColNopadding>
          ) : (
            <ColNopadding className="col-4">
              <img alt="Avatar" src={noImage}></img>
            </ColNopadding>
          )}
          <ColNopadding className="col-8">
            {/* <Row className="row">
              <ColNopadding className="col-6 node-Text text-right">
                <Medal
                  src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Bronce.png`}
                ></Medal>
              </ColNopadding>
            </Row> */}
            {node.nickname && node.nickname.length > 0 && (
              <Row className="row">
                <ColNopadding className="col-12 node-Text truncate">{node.nickname}</ColNopadding>
              </Row>
            )}
            {/* {node.level === level1 && ( */}
            <>
              <Row className="row">
                <ColNopadding className="col-12 node-Text">
                  <span className="truncate" title={node?.email}>
                    {node?.email}
                  </span>
                </ColNopadding>
              </Row>
              {/* {node.investment !== undefined && (
                  <Row className="row">
                    <ColNopadding className="col-12 node-Text">
                      <span title={node.investment}>
                        {node.investment === 0
                          ? `0,00 USDT`
                          : `${formatMoney(Number(node.investment))} USDT`}
                      </span>
                    </ColNopadding>
                  </Row>
                )} */}
            </>
            {/* )} */}
          </ColNopadding>
        </Row>
      </NodeContainer>
      {showArrow && visited === false && (
        <Row className="row">
          <ColArrow className="col-12 text-center">
            <Arrow
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/arrow-down.png`}
              alt="more nodes"
              data-get-more="moreNodes"
            ></Arrow>
          </ColArrow>
        </Row>
      )}
    </div>
  );
};

TreeNode.propTypes = {
  node: PropTypes.object,
  onClickNode: PropTypes.func,
  firstLevel: PropTypes.number,
  nodeVisited: PropTypes.array,
};

export default TreeNode;
