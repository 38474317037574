import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { InputTextFormik } from '@smartups-sl/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './register.SelectNode.data.form';
import { Button, Text } from '@smartups-sl/react-basic-components';
import { ContainerTitle, CustomTiTle } from '../../../design/styleComponents/globalComponents';
import { RowHeaderStyle, CellStyle } from './register.styled';

const SelectNode = (props) => {
  const {
    t,
    handleSubmit,
    nodes,
    onChangeNodeSelected,
    nodeSelected,
    // from,
    allowSelectSmartnetNode,
  } = props;

  const rendernodes = () => {
    if (nodes && nodes.length > 0) {
      return nodes.map((node) => {
        return (
          <div key={`node_${node.uuid}`}>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id={node.uuid}
                onChange={(event) => onChangeNodeSelected(node.uuid, event)}
                checked={nodeSelected === node.uuid}
              />
              <label className="custom-control-label" htmlFor={node.uuid}>
                {node.network.toUpperCase()}
              </label>
            </div>
          </div>
        );
      });
    }
    return '';
  };

  const renderNodesTable = () => {
    if (nodes && nodes.length > 0) {
      return (
        <>
          <RowHeaderStyle className="row">
            <CellStyle className="col-md-6">
              {t('forms.register.labels.networkName').toUpperCase()}
            </CellStyle>
            <CellStyle className="col-md-2 text-center">
              {t('forms.register.labels.activeUser').toUpperCase()}
            </CellStyle>
            <CellStyle className="col-md-2 text-center">
              {t('forms.register.labels.disableUser').toUpperCase()}
            </CellStyle>
            <CellStyle className="col-md-2 text-center">
              {t('forms.register.labels.totalUser').toUpperCase()}
            </CellStyle>
          </RowHeaderStyle>
          {allowSelectSmartnetNode && (
            <div className="row">
              <CellStyle className="col-md-6">
                {t('forms.register.currentAccount').toUpperCase()}
              </CellStyle>
              <CellStyle className="col-md-2 text-center">0</CellStyle>
              <CellStyle className="col-md-2 text-center">0</CellStyle>
              <CellStyle className="col-md-2 text-center">0</CellStyle>
            </div>
          )}

          {nodes.map((node) => {
            return (
              <div key={`node_${node.uuid}`} className="row">
                <CellStyle className="col-md-6">{node.network.toUpperCase()}</CellStyle>
                <CellStyle className="col-md-2 text-center">{node.networkCount.active}</CellStyle>
                <CellStyle className="col-md-2 text-center">{node.networkCount.inactive}</CellStyle>
                <CellStyle className="col-md-2 text-center">
                  {node.networkCount.active + node.networkCount.inactive}
                </CellStyle>
              </div>
            );
          })}
        </>
      );
    }
    return '';
  };

  return (
    <>
      <ContainerTitle className="row justify-content-center">
        <div className="col-10 py-5">
          <CustomTiTle type="h3" variant="white">
            {t('forms.register.selectNode')}
          </CustomTiTle>
        </div>
      </ContainerTitle>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 mb-4 mt-5">
          <div className="row">
            <div className="col-12 mb-4">
              <Text type="p" variant="secondary" className="mb-4">
                {t('forms.register.selectNodeText')}
              </Text>
              <Text type="p" variant="secondary" className="mb-4">
                {t('forms.register.selectNodeText2')}
              </Text>
              <Text type="p" variant="secondary">
                {t('forms.register.selectNodeText3')}
              </Text>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">{renderNodesTable()}</div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              {allowSelectSmartnetNode && (
                <div key={`node_current`}>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="newSmartnetAccount"
                      onChange={(event) => onChangeNodeSelected('newSmartnetAccount', event)}
                      checked={nodeSelected === 'newSmartnetAccount'}
                    />
                    <label className="custom-control-label" htmlFor={'newSmartnetAccount'}>
                      {t('forms.register.currentAccount').toUpperCase()}
                    </label>
                  </div>
                </div>
              )}

              {rendernodes()}
            </div>
          </div>

          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <Field
                  id="code"
                  className="form-control"
                  labelField={t('forms.register.labels.code')}
                  name="code"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="bg-default content-box text-center">
              <Button type="submit" className="btn btn-lg" variant="primary">
                {t('forms.register.buttons.verify')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

SelectNode.propTypes = {
  handleSubmit: PropTypes.func,
  nodes: PropTypes.array,
  nodeSelected: PropTypes.string,
  onChangeNodeSelected: PropTypes.func,
  oldUser: PropTypes.object,
  from: PropTypes.string,
  allowSelectSmartnetNode: PropTypes.bool,
};

const SelectNodeF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'SelectNode',
})(withTranslation()(SelectNode));

export default SelectNodeF;
