import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RegisterForm from './registerPlt.view';
import TermConditions from '../../others/termAndConditions';
import PATHS from '../../../constants/paths';
import { withTranslation } from 'react-i18next';
import * as UserService from '../../../services/UserService';
import { showDialog } from '@smartups-sl/react-utils-components';
import { Link } from '@smartups-sl/react-basic-components';
import PropTypes from 'prop-types';
import EvoRegister from './platforms/evolution.view';
import EvoShockRegister from './platforms/evolutionShock.view';
import GTraderRegister from './platforms/gtrader.view';
import queryString from 'query-string';
import * as FaceBookCampaignService from '../../../services/FaceBookCampaign';
import * as GeneralServices from '../../../services/GeneralServices';

class RegisterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: false,
      values: null,
      captcha: null,
      nationalities: undefined,
      // nodeSelected: null,
      // temporalData: null,
    };
  }

  componentDidMount() {
    GeneralServices.getAllNationalities().then((result) => {
      if (result && result.length > 0) {
        this.setState({ nationalities: result });
      }
    });
  }

  handleSubmit = async (values) => {
    try {
      let extra;
      let extraUser;
      const query = queryString.parse(this.props.location.search);
      if (query && query.extra) {
        extra = query.extra;
      }

      if (query && query.extraUser) {
        extraUser = query.extraUser;
      }

      if (this.state.captcha) {
        if (this.props.sponsorUId) {
          values.referral = this.props.sponsorUId;
          if (this.props.platform) {
            values.fromPlatform = this.props.platform;
          }
          values.lang = this.props.i18n.language;
          const result = await UserService.userRegisterExt(values, extra, extraUser);
          if (result) {
            if (this.props.platform && extra) {
              FaceBookCampaignService.registerTrack(this.props.platform, extra, values);
            }
            // if (result.temporalToken) {
            //   const setData = {
            //     temporalData: result,
            //   };
            //   if (result.allowSelectSmartnetNode) {
            //     setData.nodeSelected = 'newSmartnetAccount';
            //   } else if (result.nodes && result.nodes.length > 0) {
            //     setData.nodeSelected = result.nodes[0].uuid;
            //   }
            //   this.setState(setData);
            // } else {
            this.props.history.push(
              `${PATHS.CONFIRM_REGISTER}?platform=${this.props.platform}${
                extra ? `&extra=${extra}` : ''
              }`
            );
            // }
          } else {
            const text = this.props.t('forms.register.messages.error.general');
            showDialog(
              'Error',
              text,
              'error',
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              '#E83C7E',
              '#12374e'
            );
          }
        } else {
          this.props.history.push(
            this.props.platform ? `/auth/login/${this.props.platform}` : PATHS.LOGIN
          );
        }
      } else {
        const text = this.props.t('forms.register.messages.error.recaptcha');
        showDialog(
          'Error',
          text,
          'error',
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          '#E83C7E',
          '#12374e'
        );
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleShowTermAndConditions = (values) => {
    if (values) {
      this.setState(() => ({ showTerms: !this.state.showTerms, values }));
    } else {
      this.setState(() => ({ showTerms: !this.state.showTerms }));
    }
  };

  linkTermAndConditions = (values) => {
    const { t, i18n } = this.props;
    let namePage = t('termsFile');
    if (i18n.language === 'es') {
      namePage = 'es/terminos.html';
    } else if (i18n.language === 'en') {
      namePage = 'en/terms.html';
    } else {
      namePage = 'es/terminos.html';
    }
    const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
      process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
    }/docs/${namePage}`;
    return (
      <Link
        variant="primary"
        to={url}
        target="_blank"
        // onClick={() => {
        //   this.handleShowTermAndConditions(values);
        // }}
      >
        {t('forms.register.buttons.view_term')}
      </Link>
    );
  };

  linkTermAndConditionsGtrader = (values) => {
    const { t, i18n } = this.props;
    let namePage = t('termsFile');
    if (i18n.language === 'es') {
      namePage = 'es/terminos.html';
    } else if (i18n.language === 'en') {
      namePage = 'en/terms.html';
    } else {
      namePage = 'es/terminos.html';
    }
    const url = `https://gtrader.me/es/terminos.php`;
    return (
      <Link
        variant="primary"
        to={url}
        target="_blank"
        // onClick={() => {
        //   this.handleShowTermAndConditions(values);
        // }}
      >
        {t('forms.register.buttons.view_term_gtrader')}
      </Link>
    );
  };

  handleOnchangeCaptcha = (value) => {
    if (value) {
      this.setState({ captcha: value });
    } else {
      this.setState({ captcha: null });
    }
  };

  onChangeNodeSelected = (nodeUuid, event) => {
    if (event.target.checked) {
      this.setState({ nodeSelected: nodeUuid });
    }
  };

  render() {
    // const RegisterFormPLT = React.lazy(() => import(`./platforms/${this.props.platform}.view.jsx`));

    return (
      <>
        {this.state.showTerms && <TermConditions onBack={this.handleShowTermAndConditions} />}
        {!this.state.showTerms && (
          <>
            {this.props.platform === 'evolution' && (
              <EvoRegister
                platform={this.props.platform}
                initialValues={this.state.values || {}}
                onSubmit={this.handleSubmit}
                handleShowTermAndConditions={(values) => this.linkTermAndConditions(values)}
                handleOnchangeCaptcha={(value) => this.handleOnchangeCaptcha(value)}
              />
            )}
            {this.props.platform === 'evolution_shock' && (
              <EvoShockRegister
                platform={this.props.platform}
                initialValues={this.state.values || {}}
                onSubmit={this.handleSubmit}
                handleShowTermAndConditions={(values) => this.linkTermAndConditions(values)}
                handleOnchangeCaptcha={(value) => this.handleOnchangeCaptcha(value)}
              />
            )}
            {this.props.platform === 'gtrader' && (
              <GTraderRegister
                nationalities={this.state.nationalities || []}
                platform={this.props.platform}
                initialValues={this.state.values || {}}
                onSubmit={this.handleSubmit}
                handleShowTermAndConditions={(values) => this.linkTermAndConditions(values)}
                handleShowTermAndConditionsPlt={(values) =>
                  this.linkTermAndConditionsGtrader(values)
                }
                handleOnchangeCaptcha={(value) => this.handleOnchangeCaptcha(value)}
              />
            )}
            {/* <Suspense fallback={<div>Loading...</div>}>
              GTraderRegister
            </Suspense> */}
            {/* <RegisterForm
              platform={this.props.platform}
              initialValues={this.state.values || {}}
              onSubmit={this.handleSubmit}
              handleShowTermAndConditions={(values) => this.linkTermAndConditions(values)}
              handleOnchangeCaptcha={(value) => this.handleOnchangeCaptcha(value)}
            /> */}
          </>
        )}
      </>
    );
  }
}

RegisterContainer.propTypes = {
  sponsorUId: PropTypes.string,
  platform: PropTypes.string,
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(RegisterContainer)));
