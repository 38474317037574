import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import {
  getAuthTokenSelector,
  getNicknameSelector,
  getPreferendLanguageSelector,
} from '../../../../store/selectors/authSelector';
import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';
// import SelectTimeZone from './components/selectTimeZone';
import Languages from './components/languages';
import UserMenuHeader from './components/menu';
import LoadingModal from '../../../utils/modals/LoadingModal';
import { HeaderContainer, Logo, LogoMobile, SidebarButtonContainer, SidebarButton } from './styled';
import ModalInfo from '../../../utils/modalInfo/ModalInfo';
import ModalForm from '../../../utils/modalForm/ModalForm';

class Header extends Component {
  componentDidMount() {
    const { showUserMenu } = this.props;
    if (
      (showUserMenu !== undefined && showUserMenu === true && this.props.token) ||
      (this.props.token && showUserMenu === undefined)
    ) {
      // let script = document.getElementById('HelpCFWidgetScript');
      // if (!script) {
      //   script = document.createElement('script');
      //   script.id = 'HelpCFWidgetScript';
      //   script.src =
      //     'https://my.abhisi.com:443/app/HelpWidget/HelpWidgetScript.js?connectionSettings=3063cfd3-a3ec-bb6f-5d22-82e0b4bff78d@abhisimail.com&frontendUrl=https://my.abhisi.com:443&backendUrl=https://abhisibackend.azurewebsites.net/api/';
      //   document.head.appendChild(script);
      // }
    } else {
      // const script = document.getElementById('HelpCFWidgetScript');
      // if (script) {
      //   script.remove();
      //   window.location.reload();
      // }
    }
  }

  render() {
    const {
      showUserMenu,
      type,
      nickname,
      preferendLanguage,
      withSidebar,
      showLanguage,
      idPlatform,
    } = this.props;
    let showMenu = false;
    let showLanguageSelect = true;
    let logo = '';
    let logoMovil = '';

    if (
      (showUserMenu !== undefined && showUserMenu === true && this.props.token) ||
      (this.props.token && showUserMenu === undefined)
    ) {
      showMenu = true;
    }

    if (showLanguage !== undefined && showLanguage !== null) {
      showLanguageSelect = showLanguage;
    }

    if (idPlatform) {
      logo = `/assets/themes/${process.env.REACT_APP_THEME}/images/logo_header_${idPlatform}.png`;
      logoMovil = `/assets/themes/${process.env.REACT_APP_THEME}/images/logo_login_${idPlatform}.png`;
    } else {
      logo = `/assets/themes/${process.env.REACT_APP_THEME}/images/logo_header.png`;
      logoMovil = `/assets/themes/${process.env.REACT_APP_THEME}/images/logo_login.png`;
    }
    return (
      <>
        <ModalInfo></ModalInfo>
        <ModalForm></ModalForm>
        <LoadingModal />
        <HeaderContainer
          idPlatform={idPlatform}
          type={type || 'default'}
          className={`row sticky-top ${
            type === 'login' ? 'justify-content-end' : 'justify-content-between'
          } ${idPlatform ? `header_${idPlatform}` : ''}`}
        >
          {type !== 'login' && (
            <>
              <div className="col-2 col-md-6 col-lg-3 align-self-center ">
                <Logo src={logo} alt="logo" className="logo d-none d-md-block" />
                <LogoMobile src={logoMovil} alt="logo" className="logo d-block d-md-none" />
              </div>
            </>
          )}

          <div
            className={`${
              type === 'login' ? 'languages-login' : ''
            } col-10 col-md-6 col-lg-4 header-right-options align-self-center`}
          >
            {/* {showMenu && <SelectTimeZone timeZone={timeZone} />} */}
            {showLanguageSelect && (
              <Languages preferendLanguage={preferendLanguage} token={this.props.token} />
            )}
            {showMenu ? (
              <>
                {withSidebar ? (
                  <SidebarButtonContainer className="d-block d-md-none">
                    <SidebarButton
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                    >
                      <i className="fas fa-bars" />
                    </SidebarButton>
                    {/* <SidebarButton
                      className="btn border-0"
                      onClick={() => {
                        toggleFunc();
                      }}
                    >
                      <i className="fas fa-bars" />
                    </SidebarButton> */}
                  </SidebarButtonContainer>
                ) : (
                  <UserMenuHeader nickname={nickname} />
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </HeaderContainer>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          transition={Flip}
        />
      </>
    );
  }
}

Header.propTypes = {
  showUserMenu: PropTypes.bool,
  type: PropTypes.string,
  withSidebar: PropTypes.bool,
  toggleFunc: PropTypes.func,
  showLanguage: PropTypes.bool,
  idPlatform: PropTypes.string,
};

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
  nickname: getNicknameSelector(state),
  timeZone: getTimeZoneSelector(state),
  preferendLanguage: getPreferendLanguageSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(Header)));
