import { createSelector } from 'reselect';
import { getAuthInfoSelector } from './authSelector';
import PATHS from '../../constants/paths';

const getStateUserSelector = (state) => state.user;

export const getUserInfoSelector = createSelector(getStateUserSelector, (user) => {
  if (user && user.info) {
    return user.info;
  }

  return undefined;
});

export const getUrlSponsorSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.uuid) {
    const url2 = {};
    const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
      process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
    }${PATHS.SPONSOR}${info.uuid}`;

    if (process.env.REACT_APP_LANDING_URL_ES) {
      url2.es = `${process.env.REACT_APP_LANDING_URL_ES}${info.uuid}`;
    }
    if (process.env.REACT_APP_LANDING_URL_ES) {
      url2.en = `${process.env.REACT_APP_LANDING_URL_EN}${info.uuid}`;
    }

    return { url, url2 };
  }

  return undefined;
});

export const getTemporalUserRegisterSelector = createSelector(getStateUserSelector, (user) => {
  if (user && user.temporal) {
    return user.temporal;
  }

  return undefined;
});

export const getTemporalTokenSelector = createSelector(getTemporalUserRegisterSelector, (temp) => {
  if (temp && temp.token) {
    return temp.token;
  }

  return undefined;
});

export const getWalletsSelector = createSelector(getAuthInfoSelector, (info) => {
  if (info && info.wallets) {
    return info.wallets;
  }

  return undefined;
});
