import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import KycWarrning from './kyc.info.view';
import KycForm from './kyc.view';
// import MyWalletForm from './kyc.view';
import PATHS from '../../../constants/paths';
import { withTranslation } from 'react-i18next';
import { getUserUuidSelector } from '../../../store/selectors/authSelector';
// import { showDialog } from '@smartups-sl/react-utils-components';
// import { TFA_TIME_SECURITY } from '../../../constants/index';
// import ControlTime from '../../security/controlTimeSecurity';
import * as UserServices from '../../../services/UserService';
import KycAcceptTerms from './kyc.accept.view';
import { Link } from '@smartups-sl/react-basic-components';
import * as GeneralServices from '../../../services/GeneralServices';

class KycContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: props.verify ? false : true,
      token: null,
      step: 1,
      nationalities: undefined,
      isoResidence: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }
  // handleOnback = () => {
  //   this.props.history.push(PATHS.CLIENT_HOME);
  // };

  loadData = async () => {
    const result = await UserServices.getKycTokenConfig();
    if (result?.data?.token) {
      this.setState({ token: result.data.token });
    }
    GeneralServices.getAllNationalities().then((result) => {
      if (result && result.length > 0) {
        this.setState({ nationalities: result });
      }
    });
  };

  handleCompleteKyc = async (data) => {
    try {
      if (data && data.applicationId) {
        const result = await UserServices.onCompleteKyc(data);
        if (result) {
          this.props.history.push(PATHS.CLIENT_HOME);
        }
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleAcceptTermKyc = async (values) => {
    try {
      if (values) {
        const result = await UserServices.acceptTermsKyc(values);
        if (result && result.acceptKycTerms) {
          this.setState({ isoResidence: result.acceptKycTerms.countryResidenceISO, step: 2 });
        }
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handlerOnverify = () => {
    this.setState({ showInfo: false });
  };

  handlerBack = () => {
    this.setState({ showInfo: true });
  };

  handlerNoVerify = async () => {
    try {
      const result = await UserServices.kycUserDeclined();
      if (result) {
        this.props.history.push(PATHS.CLIENT_HOME);
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  linkTermAndConditions = (values) => {
    const { t, i18n } = this.props;
    let namePage = t('termsFile');
    if (i18n.language === 'es') {
      namePage = 'es/terminos.html';
    } else if (i18n.language === 'en') {
      namePage = 'en/terms.html';
    } else {
      namePage = 'es/terminos.html';
    }
    const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
      process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
    }/docs/${namePage}`;
    return (
      <Link
        variant="primary"
        to={url}
        target="_blank"
        // onClick={() => {
        //   this.handleShowTermAndConditions(values);
        // }}
      >
        {t('forms.register.buttons.view_term')}
      </Link>
    );
  };

  render() {
    const { showInfo, token, step } = this.state;
    const { uuid, verify } = this.props;
    return (
      <>
        {step === 1 && (
          <KycAcceptTerms
            nationalities={this.state.nationalities || []}
            onSubmit={this.handleAcceptTermKyc}
            handleShowTermAndConditions={(values) => this.linkTermAndConditions(values)}
          ></KycAcceptTerms>
        )}
        {step === 2 && this.state.isoResidence && (
          <KycForm
            token={token}
            uuid={uuid}
            handleCompleteKyc={this.handleCompleteKyc}
            onback={this.handlerBack}
            fromVerify={verify}
            isoResidence={this.state.isoResidence}
          ></KycForm>
        )}
      </>
    );
  }
}

KycContainer.propTypes = {
  securityOnSubmit: PropTypes.func,
};

const mapStateToProps = (state) => ({
  uuid: getUserUuidSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(KycContainer)));
