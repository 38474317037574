import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { dataModel, validationDataModel } from './tfaRecovery.login.data.form';
import { ButtonsContainer } from '../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-basic-components';
import { InputTextFormik } from '@smartups-sl/react-forms-components';
import { Center70Container } from './tfaRecovery.styled';

const LoginForm = (props) => {
  const { t, handleSubmit } = props;
  return (
    <Center70Container className="row justify-content-center align-items-center">
      <div className="col-10 col-sm-8 col-lg-5">
        <div className="row">
          <div className="col-12 logo-icon">
            <i className="fas fa-user-shield"></i>
          </div>
        </div>
        <Title type="h4" className="text-center" variant="secondary">
          {t('forms.resettfa.titleLogin')}
        </Title>
        <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 login-padding-sup">
              <Field
                id="user"
                className="form-control input-login-sup"
                name="user"
                component={InputTextFormik}
                fieldType="text"
                placeholder={t('forms.login.labels.email')}
                typeStyleErrorMessage="in"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 login-padding-sup">
              <Field
                id="password"
                className="form-control input-login-inf"
                name="password"
                component={InputTextFormik}
                fieldType="password"
                showHidePassword
                placeholder={t('forms.login.labels.password')}
                typeStyleErrorMessage="in"
              />
            </div>
          </div>
          <ButtonsContainer className="text-center">
            <Button type="submit" className="btn btn-lg btn-block" variant="primary">
              {t('forms.login.buttons.signin')}
            </Button>
          </ButtonsContainer>
        </form>
      </div>
    </Center70Container>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
};

const LoginFormF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'LoginForm',
})(withTranslation()(LoginForm));

export default LoginFormF;
