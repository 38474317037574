import React from 'react';
import Marketplace from '../../../components/others/marketPlace';
import Layout from '../../../components/layouts/simpleLayout';

const MarketPlacePage = () => {
  return (
    <Layout
      body={
        <>
          <Marketplace />
        </>
      }
    />
  );
};

MarketPlacePage.propTypes = {};

export default MarketPlacePage;
