import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ResetTFA from './resetTFA.view';
import * as AuthServices from '../../../services/AuthService';
import PATHS from '../../../constants/paths';
import { getAuthInfoSelector } from '../../../store/selectors/authSelector';
import { showDialog } from '@smartups-sl/react-utils-components';

class ResetTFAContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTFA: 'disable',
    };
  }

  sendResetTFA = async () => {
    const result = await AuthServices.resetMyTFA();
    const okAction = () => {
      this.props.history.push(PATHS.LOGIN);
    };
    let text = '';
    let icon = '';
    if (result && result === 'OK') {
      icon = 'success';
      text = this.props.t('forms.resettfa.messages.success');
    } else {
      icon = 'error';
      text = this.props.t('forms.resettfa.messages.error');
    }
    showDialog(
      this.props.t('forms.resettfa.messages.title'),
      text,
      icon,
      false,
      'OK',
      false,
      okAction,
      undefined,
      undefined,
      '#E83C7E',
      '#12374e'
    );
  };

  cancelResetTFA = async () => {
    this.props.history.push(PATHS.LOGIN);
  };

  render() {
    return (
      <ResetTFA
        dataTfa={this.state.dataTFA || {}}
        sendResetTFA={this.sendResetTFA}
        cancelResetTFA={this.cancelResetTFA}
      />
    );
  }
}

ResetTFAContainer.propTypes = {};

const mapStateToProps = (state) => ({
  info: getAuthInfoSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(ResetTFAContainer)));
