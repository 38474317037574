import React from 'react';
import DetailsNews from '../../../components/others/detailsNews';
import Layout from '../../../components/layouts/simpleLayout';
import PropTypes from 'prop-types';

const NewsDetailsPage = (props) => {
  return (
    <Layout
      body={
        <>
          <DetailsNews uuid={props.uuid} />
        </>
      }
    />
  );
};

NewsDetailsPage.propTypes = {
  uuid: PropTypes.string,
};

export default NewsDetailsPage;
