import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as UserService from '../../../services/UserService';
import * as NavService from '../../../services/NavService';
import PATHS from '../../../constants/paths';
import { getNavFromSelector } from '../../../store/selectors/navSelector';
import { ROLE_CLIENT, KYC_STATUS_PENDING, TIME_REMAINED } from '../../../constants/index';

class SecurityRedirectContainer extends Component {
  componentDidMount = async () => {
    if (this.props.from) {
      if (this.props.from === PATHS.LOGIN) {
        const resultInfo = await UserService.getAuthUserProfile();

        if (resultInfo.data.role === ROLE_CLIENT) {
          const currentTime = new Date().getTime();
          const meetStart = resultInfo.zoom?.startDate
            ? new Date(resultInfo.zoom.startDate).getTime()
            : 0;
          const diff = meetStart - currentTime;

          if (resultInfo.zoom && meetStart > 0 && diff < TIME_REMAINED) {
            this.props.history.push({
              pathname: PATHS.MEETING_PRE,
              state: { zoom: resultInfo.zoom },
            });
          } else if (
            resultInfo.data.passedKYC === KYC_STATUS_PENDING &&
            resultInfo.data.requiredKYC === 'REQUIRED' &&
            !resultInfo.data.kycUserUuid
          ) {
            this.props.history.push(PATHS.KYC);
          } else {
            this.props.history.push(PATHS.CLIENT_HOME);
          }
        } else {
          this.props.history.push(PATHS.LOGIN);
        }
      }

      NavService.setCleanNav();
    }
  };

  render() {
    return <>Redirect...</>;
  }
}

SecurityRedirectContainer.propTypes = {};

const mapStateToProps = (state) => ({
  from: getNavFromSelector(state),
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(SecurityRedirectContainer))
);
