import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Center70Container } from './resetTFA.styled';
import { Button, Title, Text } from '@smartups-sl/react-basic-components';

class ResetTFA extends Component {
  render() {
    const { t, cancelResetTFA, sendResetTFA } = this.props;
    return (
      <>
        <Center70Container className="row justify-content-center align-items-center">
          <div className="col-12 col-sm-6 col-lg-6">
            <div className="row">
              <div className="col-12 logo-icon">
                <i className="fas fa-shield-alt"></i>
              </div>
            </div>
            <Title type="h2" className="text-center marg-btm-1rem" variant="secondary">
              {t('forms.resettfa.title')}
            </Title>
            <div className="row">
              <div className="col-12">
                <Text type="p" className="marg-btm-1rem" variant="secondary">
                  {t('forms.resettfa.description')}
                </Text>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center margBottom15">
                <Text type="span" className="marg-btm-1rem" variant="secondary">
                  {t('forms.resettfa.description2')}
                </Text>
              </div>
            </div>
            <div className="bg-default content-box text-center button-confirm">
              <div className="row justify-content-center align-items-center">
                <div className="col-12 col-md-5 col-lg-4 margBottom15">
                  <Button
                    type="button"
                    className="btn btn-md btn-block"
                    variant="secondary"
                    onClick={() => {
                      cancelResetTFA();
                    }}
                  >
                    {t('forms.resettfa.buttons.cancel')}
                  </Button>
                </div>
                <div className="col-12 col-md-5 col-lg-4 margBottom15">
                  <Button
                    type="button"
                    className="btn btn-md btn-block"
                    variant="primary"
                    onClick={() => {
                      sendResetTFA();
                    }}
                  >
                    {t('forms.resettfa.buttons.ok')}
                  </Button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center margBottom15">
                <Text type="span" className="marg-btm-1rem" variant="secondary">
                  {t('forms.resettfa.note')}
                </Text>
              </div>
            </div>
          </div>
        </Center70Container>
      </>
    );
  }
}

ResetTFA.propTypes = {
  cancelResetTFA: PropTypes.func,
  sendResetTFA: PropTypes.func,
};

export default withTranslation()(ResetTFA);
