import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import Login from './pages/user/login';
import Duplicated from './pages/user/duplicated';
import ForgotPassword from './pages/user/forgotPassword';
import PreRegister from './pages/user/preRegister';
import RecoveryPassword from './pages/user/recoveryPassword';
import Register from './pages/user/register';
// import VerifyAccount from './pages/user/verifyAccount';
import ConfirmRegister from './pages/user/confirmRegister';
import EmailVerification from './pages/user/emailVerification';
import UserProfile from './pages/user/userProfile';
import TermConditions from './pages/others/termAndConditions';
import UnilevelTree from './pages/user/unilevelTree';
import ManageSecurity from './pages/security/manageSecurity';
import RedirectSecurity from './pages/security/redirectSecurity';
import TFA from './pages/security/tfa';
import ResetTFA from './pages/security/resetTFA';
import KYCPage from './pages/user/kyc';
import RecoveryTFA from './pages/security/recoveryTfa';
import ClientHome from './pages/user/clientHome';
import PATHS from './constants/paths';
import * as GeneralServices from './services/GeneralServices';
import moment from 'moment-timezone';
import AuthRoute from './routes/AuthRoute';
import MaintenancePage from './pages/others/maintenance';
import { AbilityContext } from './components/permissions/Can';
import { buildAbilityFor } from './components/permissions/ability';
import MarketPlacePage from './pages/others/marketPlace';
import NewsDetailsPage from './pages/others/news';
import LoginImpersonate from './pages/user/loginImpersonate';
import RegisterPlt from './pages/user/registerPlt';
import ContactPage from './pages/others/contact';
/* GENERATOR_INJECT_IMPORT_ROUTES */

const ability = buildAbilityFor();

class App extends Component {
  constructor(props) {
    super(props);
    // this.getStatus = this.getStatus.bind(this);
    // const status = this.getStatus().then((result) => {
    //   return result;
    // });
    // console.log(status);
    this.state = {
      dataTFA: undefined,
      maintenance: false,
    };
  }

  componentDidMount() {
    GeneralServices.setTimeZone({ timeZone: moment.tz.guess() });
    this.getStatus();
  }

  getStatus = async () => {
    const result = await GeneralServices.getPlatformStatus();
    if (result?.maintenance !== undefined && result?.maintenance === true) {
      this.setState({ maintenance: result.maintenance });
    }
  };

  render() {
    const { maintenance } = this.state;
    return (
      <>
        <AbilityContext.Provider value={ability}>
          {maintenance ? (
            <Router>
              <MaintenancePage />
            </Router>
          ) : (
            <Router>
              <Switch>
                <Route
                  exact
                  path={PATHS.EXTERNAL_LOGIN}
                  component={(props) => <Login idPlatform={props.match.params.idPlatform} />}
                />
                <Route
                  exact
                  path={PATHS.LOGIN_IMPERSONATE}
                  component={(props) => <LoginImpersonate token={props.match.params.token} />}
                />
                <AuthRoute
                  exact
                  path={PATHS.KYC_VERIFY}
                  component={(props) => <KYCPage verify={true} />}
                />
                <AuthRoute exact path={PATHS.KYC} component={KYCPage} />
                <Route exact path={PATHS.DUPLICATED} component={Duplicated} />
                <Route exact path={PATHS.LOGIN} component={Login} />
                <Route exact path={PATHS.PRE_REGISTER} component={PreRegister} />
                <Route exact path={PATHS.FORGOT_PASSWORD} component={ForgotPassword} />
                <Route
                  exact
                  path={PATHS.RECOVERY_PASSWORD}
                  component={(props) => <RecoveryPassword token={props.match.params.token} />}
                />
                <AuthRoute exact path={PATHS.MY_PROFILE} component={UserProfile} />
                <AuthRoute exact path={PATHS.SECURITY_TFA_REDIRECT} component={RedirectSecurity} />
                <AuthRoute exact path={PATHS.SECURITY} component={ManageSecurity} />
                <AuthRoute exact path={PATHS.SECURITY_TFA} component={TFA} />
                <AuthRoute exact path={PATHS.RESET_TFA} component={ResetTFA} />
                <Route
                  exact
                  path={PATHS.RECOVERY_TFA}
                  component={(props) => <RecoveryTFA token={props.match.params.token} />}
                />
                <Route exact path={PATHS.CONFIRM_REGISTER} component={ConfirmRegister} />
                <Route
                  exact
                  path={PATHS.VERIFY_REGISTER}
                  component={(props) => <EmailVerification token={props.match.params.token} />}
                />

                <Route
                  exact
                  path={PATHS.REGISTER_FROM_PLATFORM}
                  component={(props) => (
                    <RegisterPlt
                      sponsorUId={props.match.params.sponsorUId}
                      platform={props.match.params.platform}
                    />
                  )}
                />

                <Route
                  exact
                  path={PATHS.REGISTER}
                  component={(props) => <Register sponsorUId={props.match.params.sponsorUId} />}
                />
                {/* <Route
                  exact
                  path={PATHS.SPECIAL_VERIFY}
                  component={(props) => <VerifyAccount activateToken={props.match.params.token} />}
                /> */}
                <Route
                  exact
                  path={PATHS.NEWS_DETAILS}
                  component={(props) => <NewsDetailsPage uuid={props.match.params.uuid} />}
                />

                <Route exact path={PATHS.CONTACT} component={(props) => <ContactPage />} />

                <Route
                  exact
                  path={PATHS.SPECIAL_ACTIVATE}
                  component={(props) => <Register activateToken={props.match.params.token} />}
                />
                <AuthRoute path={PATHS.UNI_TREENODES} component={UnilevelTree} />
                <AuthRoute path={PATHS.CLIENT_HOME} component={ClientHome} />
                <Route path={PATHS.TERM_AND_CONDITIONS} component={TermConditions} />
                <Route path={PATHS.MARKET_PLACE} component={MarketPlacePage} />
                {/* GENERATOR_INJECT_ROUTES */}

                {/* <Route exact path={PATHS.LOGOUT} component={LogoutContainer} />
        <Route exact path={PATHS.ERROR} component={ErrorPage} />  */}
                <Route path={PATHS.INIT} render={() => <Redirect to={PATHS.LOGIN} />} />
              </Switch>
            </Router>
          )}
        </AbilityContext.Provider>
      </>
    );
  }
}

App.propTypes = {};

export default App;
