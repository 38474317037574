const PATHS = {};

PATHS.INIT = '/';
PATHS.ERROR = '/error';
PATHS.LOGOUT = '/logout';
PATHS.LOGIN = '/login';
PATHS.LOGIN_IMPERSONATE = '/login/impersonate/:token';
PATHS.EXTERNAL_LOGIN = '/auth/login/:idPlatform';
PATHS.HOME = '/home';
PATHS.REGISTER = '/register/:sponsorUId';
PATHS.REGISTER_FROM_PLATFORM = '/user_register/:platform/:sponsorUId';
PATHS.SPONSOR = '/register/';
PATHS.CONFIRM_REGISTER = '/register/confirm';
PATHS.SELECT_NODE = '/register/selectNode';
PATHS.VERIFY_REGISTER = '/register/verify/:token';
PATHS.RECOVERY_PASSWORD = '/recovery/password/:token';
PATHS.FORGOT_PASSWORD = '/forgotPassword';
PATHS.PRE_REGISTER = '/preRegister';
PATHS.RESET_TFA = '/reset/tfa';
PATHS.RECOVERY_TFA = '/recovery/TFA/:token';
PATHS.MY_PROFILE = '/myProfile';
PATHS.SECURITY = '/security';
PATHS.WALLETS = '/wallets';
PATHS.SECURITY_TFA = '/security/tfa';
PATHS.SECURITY_TFA_REDIRECT = '/security/tfa/redirect';
PATHS.UNI_TREENODES = '/treeNodes/unilevels';
PATHS.TERM_AND_CONDITIONS = '/term_and_conditions';
PATHS.KYC_VERIFY = '/kyc/verify';
PATHS.KYC = '/kyc';
PATHS.SETTINGS = '/settings';
PATHS.CONTACT = '/contact';
/** CLIENT PATH */
PATHS.CLIENT_HOME = '/client/home';
// PATHS.SPECIAL_VERIFY = '/account/active/:token';
PATHS.SPECIAL_ACTIVATE = '/account/active/:token';

PATHS.MARKET_PLACE = '/marketsplace';
PATHS.DUPLICATED = '/user/duplicated';

PATHS.NEWS_DETAILS = '/news/details/:uuid';
/* GENERATOR_INJECT_PATHS */

export default PATHS;
