import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';
import { InputTextFormik } from '@smartups-sl/react-forms-components';
import { dataModel, validationDataModel } from './recoveryPassword.data.form';
import { Button } from '@smartups-sl/react-basic-components';

import {
  CenterContainer,
  ButtonsContainer,
} from '../../../design/styleComponents/globalComponents';
import { ContainerTitle, CustomTiTle } from '../../../design/styleComponents/globalComponents';

const RecoveryForm = (props) => {
  const { t, handleSubmit } = props;
  return (
    <>
      <ContainerTitle className="row justify-content-center">
        <div className="col-10 py-5">
          <CustomTiTle type="h3" variant="white">
            {t('forms.recovery.title')}
          </CustomTiTle>
        </div>
      </ContainerTitle>
      <CenterContainer className="row justify-content-center align-items-center mt-5">
        <div className="col-10 col-sm-8 col-lg-5">
          <div className="example-box-wrapper">
            <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <Field
                    id="password"
                    className="form-control"
                    labelField={t('forms.recovery.labels.newpassword')}
                    name="password"
                    component={InputTextFormik}
                    fieldType="password"
                    showHidePassword
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Field
                    id="passwordConfirm"
                    className="form-control"
                    labelField={t('forms.recovery.labels.passwordConfirm')}
                    name="passwordConfirm"
                    component={InputTextFormik}
                    fieldType="password"
                    showHidePassword
                    placeholder=""
                  />
                </div>
              </div>
              <ButtonsContainer className="text-center">
                <Button type="submit" className="btn btn-lg btn-block" variant="primary">
                  {t('forms.recovery.buttons.recovery')}
                </Button>
              </ButtonsContainer>
            </form>
          </div>
        </div>
      </CenterContainer>
    </>
  );
};

RecoveryForm.propTypes = {
  handleSubmit: PropTypes.func,
};

const RecoveryFormF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'RecoveryForm',
})(withTranslation()(RecoveryForm));

export default RecoveryFormF;
