import { createAction } from 'redux-actions';
import { apiPost, apiGet } from '../../api/index';
import {
  urllogin,
  urlForgotPassword,
  urlVerifyEmail,
  urlRecoveryPass,
  urlManageTwoFA,
  urlVerifyTwoFA,
  urlDisableTwoFA,
  urlResendVerifyEmail,
  urlResetTFA,
  urlRecoveryTFA,
} from '../../api/urls';
import {
  LOGIN_SUCCESS,
  CLEAN_AUTH,
  UPDATE_TOKEN,
  LOGIN_USER,
  FORGOT_PASS,
  VERIFY_EMAIL,
  RECOVERY_PASS,
  MANAGE_2FA,
  VERIFY_2FA,
  DISABLE_2FA,
  SET_TIME_TFA,
  RESET_TFA,
  RECOVERY_TFA_USER,
} from '../../constants/index';

const generateUriVerify = (url, token, fromPlatform) => {
  let uri = `${url}/${token[0]}`;

  if (fromPlatform) {
    uri = uri + `?fromPlatform=${fromPlatform}`;
  }

  return uri;
};

/**
 * Login to server. Call to server.
 */
export const loginUser = createAction(LOGIN_USER, (obj) =>
  apiPost(urllogin, obj, undefined, false)()
);

/**
 * Login to server. Call to server.
 */
export const forgotPass = createAction(FORGOT_PASS, (obj) =>
  apiPost(urlForgotPassword, obj, undefined, false)()
);

/**
 * Get auth info profile (user loged).
 */
export const verifyEmailRegister = createAction(VERIFY_EMAIL, (obj, fromPlatform) =>
  apiGet(generateUriVerify(urlVerifyEmail, obj, fromPlatform), undefined, false, true)()
);

/**
 * Resend verification mail.
 */
export const resendVerifyEmailRegister = createAction(VERIFY_EMAIL, (obj) =>
  apiPost(urlResendVerifyEmail, obj, undefined, false)()
);

/**
 * Recovery user password.
 */
export const recoveryUserPassword = createAction(RECOVERY_PASS, (obj) =>
  apiPost(urlRecoveryPass, obj, undefined, false)()
);

/**
 * Set 2fa.
 */
export const getTwoFA = createAction(MANAGE_2FA, () => apiGet(urlManageTwoFA, undefined, true)());

/**
 * Verify 2fa.
 */
export const verifyTfa = createAction(VERIFY_2FA, (obj) =>
  apiPost(urlVerifyTwoFA, obj, undefined, true)()
);

/**
 * Disable 2fa.
 */
export const disableTfa = createAction(DISABLE_2FA, (obj) =>
  apiPost(urlDisableTwoFA, obj, undefined, true)()
);

/**
 * Reset TFA.
 */
export const resetTFA = createAction(RESET_TFA, () => apiGet(urlResetTFA, undefined, true)());

/**
 * Recovert TFA.
 */
export const recoveryTFA = createAction(RECOVERY_TFA_USER, (param) =>
  apiGet(urlRecoveryTFA, param, true)()
);

export const loginSucess = createAction(LOGIN_SUCCESS, (data) => data);

export const logoutUser = createAction(CLEAN_AUTH, (data) => data);

export const updateToken = createAction(UPDATE_TOKEN, (data) => data);

export const setTfaTime = createAction(SET_TIME_TFA, (data) => data);
