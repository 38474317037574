import React from 'react';
import PropTypes from 'prop-types';
import RecoveryTFA from '../../../components/security/tfaRecovery';
import Layout from '../../../components/layouts/centerLayout';

const RecoveryTFAPage = (props) => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <RecoveryTFA token={props.token} />
        </>
      }
    />
  );
};

RecoveryTFAPage.propTypes = {
  token: PropTypes.string,
};

export default RecoveryTFAPage;
