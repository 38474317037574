import { Ability, AbilityBuilder } from '@casl/ability';
import { store } from '../../store';
import { ROLE_CLIENT, ROLE_SUPPORT } from '../../constants/index';

import {
  getPermisionsClient,
  getPermisionsSupport,
  getPermisionsAnonymous,
  getPermisionsClientTestingMode,
  getPermisionsSupportTestingMode,
} from './rolesPermission';

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

export const defineRulesFor = (auth) => {
  const { can, rules } = new AbilityBuilder(Ability);
  if (auth.role) {
    const enabledTestingMode = process.env.REACT_APP_ENABLED_TEST_MODE === 'true';
    let isStaff = false;

    if (enabledTestingMode && auth.roles) {
      const roleStaff = auth.roles.find((role) => role === 'STAFF');
      if (roleStaff) {
        isStaff = true;
      }
    }

    if (enabledTestingMode) {
      if (auth.role === ROLE_CLIENT) {
        getPermisionsClientTestingMode(can, isStaff);
      }
      if (auth.role === ROLE_SUPPORT) {
        getPermisionsSupportTestingMode(can, isStaff);
      }
    } else {
      if (auth.role === ROLE_CLIENT) {
        getPermisionsClient(can);
      }
      if (auth.role === ROLE_SUPPORT) {
        getPermisionsSupport(can);
      }
    }
    getPermisionsAnonymous(can);
  } else {
    getPermisionsAnonymous(can);
  }
  return rules;
};

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().auth.info;
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth ? currentAuth : 'anonymous'));
  }
});

export const buildAbilityFor = (role) => {
  return ability.update(defineRulesFor(currentAuth ? currentAuth : 'anonymous'));
};
