/* eslint-disable indent */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DuplicatedView from './duplicated.view';
import ReviewView from './review.view';
import * as UserService from '../../../services/UserService';
import * as AuthService from '../../../services/AuthService';
import { getStatusDuplicatedSelector } from '../../../store/selectors/authSelector';
import PATHS from '../../../constants/paths';
// import { ROLE_CLIENT, KYC_STATUS_PENDING } from '../../../constants/index';
import { showDialog } from '@smartups-sl/react-utils-components';
import PropTypes from 'prop-types';

class DuplicatedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      statusDuplicated: null,
    };
  }

  componentDidMount() {
    const { statusDuplicated } = this.props;
    if (statusDuplicated === 'REVIEW') {
      this.setState({ statusDuplicated: 'REVIEW' });
    } else {
      this.loadData();
    }
  }

  loadData = async () => {
    const result = await UserService.getUserDuplicatedActive();
    if (result?.data) {
      this.setState({ users: result.data, statusDuplicated: 'BLOCKED' });
    }
  };

  // handlerSubmitFusion = async () => {
  //   const { t } = this.props;

  //   const result = await UserService.fussion();
  //   if (result?.status === 'OK') {
  //     const okAction = async () => {
  //       this.handlerCancel();
  //     };

  //     showDialog(
  //       t('forms.duplicated.messages.fussionTitle'),
  //       t('forms.duplicated.messages.fussionText'),
  //       'success',
  //       false,
  //       t('forms.duplicated.messages.buttonOk'),
  //       false,
  //       okAction,
  //       undefined,
  //       undefined,
  //       '#26abe1',
  //       '#12374e',
  //       true
  //     );
  //   }
  // };

  handlerCancel = async () => {
    localStorage.removeItem('smartToken');
    await AuthService.logout();
    this.props.history.push(PATHS.LOGIN);
  };

  render() {
    const { statusDuplicated } = this.state;
    return (
      <>
        {statusDuplicated === 'REVIEW' ? (
          <ReviewView okFunction={this.handlerCancel} />
        ) : (
          <DuplicatedView
            users={this.state.users || null}
            handlerFusion={this.handlerCancel}
            // cancelFusion={this.handlerCancel}
          />
        )}
      </>
    );
  }
}

DuplicatedContainer.propTypes = {
  idPlatform: PropTypes.string,
};

const mapStateToProps = (state) => ({
  statusDuplicated: getStatusDuplicatedSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(DuplicatedContainer)));
