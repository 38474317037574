import styled from 'styled-components';
import { Button } from '@smartups-sl/react-basic-components';

export const ComingSoon = styled.div`
  background-color: #e83c7e;
  position: absolute;
  top: 35%;
  color: #fff;
  font-size: 1.5rem;
  padding: 10px 35px;
  border-radius: 25px;
  -webkit-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
`;

export const MarketContainer = styled.div`
  background-color: #fff;
  padding: 1.5rem;
  border: 2px dashed #26abe1;
  border-radius: 25px;
  -webkit-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 9px 28px -12px rgba(0, 0, 0, 0.75);
`;

export const MarketLink = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export const MarketLinkActive = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const LabelStatus = styled.div`
  color: #12374e;
`;

export const SwitchStatus = styled.input`
  background-color: ${({ statusCheck }) =>
    statusCheck ? '#ff438b !important' : '#fff !important'};
  border-color: ${({ statusCheck }) => (statusCheck ? '#ff438b !important' : '#bfbfbf !important')};
  box-shadow: rgb(74 74 104 / 20%) 0px 2px 2px 0px inset !important;
  /* width: 3rem !important; */
`;

export const CustomTiTleService = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
  color: #12374e;
`;

export const ButtonGoto = styled(Button)`
  width: 90%;
  border-radius: 25px;
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const MarketImage = styled.img`
  width: 90%;
  border-radius: 25px;
`;

export const MarketImageDisabled = styled.img`
  width: 100%;
  border-radius: 25px;
  filter: url(filters.svg#grayscale); /* Firefox 3.5+ */
  filter: gray; /* IE5+ */
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  -webkit-transition: all 0.5s ease-in-out;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StatusActionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const LandingLink = styled.a`
  color: ${({ theme }) => theme.colors.terciary};
  margin-left: 0.5rem;
  font-size: 1.2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ButtonActivationTerms = styled.button`
  color: #e83c7e;
  margin-left: 5px;
  text-transform: uppercase;
  background: transparent;
  border: 0px;

  &:hover {
    text-decoration: underline;
  }
`;
