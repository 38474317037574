const colors = {
  primary: '#26abe1',
  primaryLight: '#3bc2f8',
  secondary: '#12374e',
  fourthy: '#FF8F36',
  terciary: '#E83C7E',
  terciaryLight: '#ff438b',
  white: '#ffffff',
  fidelitybotsPrimary: '#00a79d',
  fidelitybotsLight: '#02bbb0',
  evolutionPrimary: '#e81d75',
  evolutionLight: '#c51964',
  mypocketbotPrimary: '#136b88',
  mypocketbotLight: '#1982a5',
  mypocketbotDark: '#363f5a',
  gtraderPrimary: '#210041',
  gtraderDark: '#210041',
  gtraderText: '#27ea86 ',
};

const theme = {
  platforms: {
    smartnet: colors.primary,
    fidelitybots: colors.fidelitybotsPrimary,
    evolution: colors.evolutionPrimary,
    evolution_shock: colors.evolutionPrimary,
    mypocketbot: colors.mypocketbotPrimary,
    mypocketbotDark: colors.mypocketbotDark,
    gtrader: colors.gtraderPrimary,
    gtraderDark: colors.gtraderDark,
    gtraderText: colors.gtraderText,
    evolutionborder: colors.evolutionPrimary,
    evolution_shockborder: colors.evolutionPrimary,
  },
  imgPlatformsPaddings: {
    smartnet: '12px 0px',
    bot: '10px 0px',
  },
  colors: {
    primary: colors.primary,
    primaryLight: colors.primaryLight,
    secondary: colors.secondary,
    fourthy: colors.fourthy,
    terciary: colors.terciary,
    terciaryLight: colors.terciaryLight,
    white: colors.white,
    fidelitybotsPrimary: colors.fidelitybotsPrimary,
    fidelitybotsLight: colors.fidelitybotsLight,
    mypocketbotPrimary: colors.mypocketbotPrimary,
    mypocketbotLight: colors.mypocketbotLight,
  },
  paginations: {
    buttons: {
      disabled: '#fff',
      color: '#fff',
      hover: '#26abe1',
      align: 'center',
    },
  },
  notifications: {
    personal: {
      backgroundColor: '#26abe1',
      backgroundColorHover: '#26abe1',
    },
    general: {
      backgroundColor: '#26abe1',
      backgroundColorHover: '#26abe1',
    },
  },
  sidebar: {
    colorLink: '#ffffff',
    colorLinkHover: '#00a79d',
    backgroundColor: '#12374e',
  },
  body: {
    backgroundColor: '#fff',
  },
  header: {
    backgroundColor: '#ffffff',
    borderColor: colors.primary,
  },
  buttons: {
    default: {
      backgroundColor: '#fff',
      backgroundColorHover: '#fff',
      color: '#000',
      colorHover: '#000',
      borderColor: '#000',
      borderColorHover: '#000',
    },
    primary: {
      backgroundColor: colors.primary,
      backgroundColorHover: colors.primaryLight,
      color: colors.white,
      colorHover: colors.white,
      borderColor: colors.primary,
      borderColorHover: colors.primaryLight,
    },
    secondary: {
      backgroundColor: '#12374e',
      backgroundColorHover: '#1f1f1f',
      color: '#fff',
      colorHover: '#fff',
      borderColor: '#12374e',
      borderColorHover: '#545454',
    },
    terciary: {
      backgroundColor: colors.terciary,
      backgroundColorHover: colors.terciaryLight,
      color: colors.white,
      colorHover: colors.white,
      borderColor: colors.terciary,
      borderColorHover: colors.terciaryLight,
    },
    alert: {
      backgroundColor: '#d00000',
      backgroundColorHover: '#e80000',
      color: '#fff',
      colorHover: '#fff',
      borderColor: '#d00000',
      borderColorHover: '#e80000',
    },
    trasparent: {
      backgroundColor: 'trasparent',
      backgroundColorHover: 'trasparent',
      color: '#fff',
      colorHover: '#fff',
      borderColor: 'trasparent',
      borderColorHover: 'trasparent',
    },
    fidelitybots: {
      backgroundColor: colors.fidelitybotsPrimary,
      backgroundColorHover: colors.fidelitybotsLight,
      color: colors.white,
      colorHover: colors.white,
      borderColor: colors.fidelitybotsPrimary,
      borderColorHover: colors.fidelitybotsLight,
    },
    evolution: {
      backgroundColor: colors.evolutionPrimary,
      backgroundColorHover: colors.evolutionLight,
      color: colors.white,
      colorHover: colors.white,
      borderColor: colors.evolutionPrimary,
      borderColorHover: colors.evolutionLight,
    },
    evolution_shock: {
      backgroundColor: colors.evolutionPrimary,
      backgroundColorHover: colors.evolutionLight,
      color: colors.white,
      colorHover: colors.white,
      borderColor: colors.evolutionPrimary,
      borderColorHover: colors.evolutionLight,
    },
    mypocketbot: {
      backgroundColor: colors.mypocketbotPrimary,
      backgroundColorHover: colors.mypocketbotPrimary,
      color: colors.white,
      colorHover: colors.white,
      borderColor: colors.mypocketbotPrimary,
      borderColorHover: colors.mypocketbotPrimary,
    },
    gtrader: {
      backgroundColor: colors.mypocketbotPrimary,
      backgroundColorHover: colors.mypocketbotPrimary,
      color: colors.white,
      colorHover: colors.white,
      borderColor: colors.mypocketbotPrimary,
      borderColorHover: colors.mypocketbotPrimary,
    },
  },
  links: {
    settings: {
      active: colors.secondary,
      default: colors.primary,
      hover: '#1d95c5',
    },
    default: {
      color: '#000',
      colorHover: '#000',
    },
    secondary: {
      color: colors.terciary,
      colorHover: colors.primary,
    },
    primary: {
      color: '#E83C7E',
      colorHover: '#E83C7E',
    },
  },
  text: {
    align: 'justify',
  },
  title: {
    h1: {
      size: '2rem',
      family: 'Gotham',
    },
    h2: {
      size: '1.8rem',
      family: 'Gotham',
    },
    h3: {
      size: '1.6rem',
      family: 'Gotham',
    },
    h4: {
      size: '1.4rem',
      family: 'Gotham',
    },
    h5: {
      size: '1.2rem',
      family: 'Gotham',
    },
  },
  fonts: {
    family: 'Gotham',
    size: {
      sm: '0.7rem',
      md: '0.875rem',
      lg: '1rem',
      xlg: '1.5rem',
      xxlg: '2rem',
    },
  },
};

export default theme;
