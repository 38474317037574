import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ContainerTitle, CustomTiTle } from '../../../design/styleComponents/globalComponents';
import { Button } from '@smartups-sl/react-basic-components';

const DuplicatedView = (props) => {
  const { t } = useTranslation();
  const { okFunction } = props;
  return (
    <>
      <ContainerTitle className="row justify-content-center">
        <div className="col-10 py-5">
          <CustomTiTle type="h3" variant="white">
            {t('forms.duplicated.titleReview')}
          </CustomTiTle>
        </div>
      </ContainerTitle>
      <div className="row justify-content-center mt-5">
        <div className="col-10">{t('forms.duplicated.descriptionReview')}</div>
      </div>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-4 text-center">
          <Button
            type="button"
            className="btn btn-md btn-block"
            variant="primary"
            onClick={() => {
              okFunction();
            }}
          >
            {t('forms.duplicated.buttons.accept')}
          </Button>
        </div>
      </div>
    </>
  );
};

DuplicatedView.propTypes = {
  okFunction: PropTypes.func,
};

export default DuplicatedView;
