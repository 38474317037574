import { createAction } from 'redux-actions';
import { SET_NAV_FROM, CLEAN_NAV, HIDDEN_INIT_TOUR } from '../../constants/index';

/**
 * Set navigation from.
 */
export const setFrom = createAction(SET_NAV_FROM, (data) => data);

/**
 * Clean navigation from.
 */
export const cleanNav = createAction(CLEAN_NAV, () => {});

/**
 * Set navigation from.
 */
export const hiddenInitTour = createAction(HIDDEN_INIT_TOUR, (data) => data);
