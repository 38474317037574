import React from 'react';
import PropTypes from 'prop-types';
// import { withFormik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
// import { dataModel, validationDataModel } from './login.data.form';
// import {
//   CenterContainer,
//   ButtonsContainer,
// } from '../../../design/styleComponents/globalComponents';
import {
  TitletIcon,
  IconUser,
  IconTree,
  IconArrow,
  IconUserACtive,
  TitletQuestion,
} from './duplicated.styled';
// import { Button, Link, Title } from '@smartups-sl/react-basic-components';
// import { InputTextFormik } from '@smartups-sl/react-forms-components';
import { ContainerTitle, CustomTiTle } from '../../../design/styleComponents/globalComponents';
import { Button } from '@smartups-sl/react-basic-components';

const DuplicatedView = (props) => {
  const { t } = useTranslation();
  const { users, handlerFusion } = props;
  return (
    <>
      <ContainerTitle className="row justify-content-center">
        <div className="col-10 py-5">
          <CustomTiTle type="h3" variant="white">
            {t('forms.duplicated.title')}
          </CustomTiTle>
        </div>
      </ContainerTitle>
      <div className="row justify-content-center mt-5">
        <div className="col-10">{t('forms.duplicated.description')}</div>
      </div>
      <div className="row justify-content-center mt-3">
        <div className="col-3 d-flex flex-column justify-content-center align-items-center">
          <TitletIcon>{t('forms.duplicated.second')}</TitletIcon>
          <IconUser className="fas fa-user"></IconUser>
          <IconTree className="fas fa-sitemap"></IconTree>
          <TitletIcon className="mt-3">{users?.duplicateUser.email}</TitletIcon>
        </div>
        <div className="col-1 d-flex flex-column justify-content-center align-items-center">
          {/* <IconArrow className="fas fa-arrow-alt-circle-right"></IconArrow> */}
        </div>
        <div className="col-3 d-flex flex-column justify-content-center align-items-center">
          <TitletIcon>{t('forms.duplicated.active')}</TitletIcon>
          <IconUserACtive className="fas fa-user-check"></IconUserACtive>
          <IconTree className="fas fa-sitemap"></IconTree>
          <TitletIcon className="mt-3">{users?.activeUser.email}</TitletIcon>
        </div>
      </div>
      {/* <div className="row justify-content-center mt-5">
        <div className="col-10 text-center">
          <TitletQuestion>{t('forms.duplicated.question')}</TitletQuestion>
        </div>
      </div> */}
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-4 text-center">
          <Button
            type="button"
            className="btn btn-md btn-block"
            variant="primary"
            onClick={() => {
              handlerFusion();
            }}
          >
            {t('forms.duplicated.buttons.accept')}
          </Button>
          {/* <Button
            type="button"
            className="btn btn-md btn-block"
            variant="secondary"
            onClick={() => {
              cancelFusion();
            }}
          >
            {t('forms.duplicated.buttons.cancel')}
          </Button> */}
        </div>
      </div>
    </>
  );
};

DuplicatedView.propTypes = {
  users: PropTypes.object,
  handlerFusion: PropTypes.func,
  // cancelFusion: PropTypes.func,
};

export default DuplicatedView;
