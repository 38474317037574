import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withFormik, Field } from 'formik';
import { ButtonsContainer } from '../../../design/styleComponents/globalComponents';
import { Button, Title, Text } from '@smartups-sl/react-basic-components';
import { InputCheckFormik, InputSelectFormik } from '@smartups-sl/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './kyc.accept.data.form';

const KycAcceptTerms = (props) => {
  const { t, handleSubmit, handleShowTermAndConditions, nationalities } = props;

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-sm-10 col-lg-10">
          <div className="row">
            <div className="col-12 marg-top-40px">
              <Title type="h3" variant="primary">
                {t('forms.kyc.title')}
              </Title>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Text type="p" className="marg-btm-1rem" variant="warrning">
                {t('forms.kyc.accept_description')}
              </Text>
            </div>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-3">
                <Field
                  id="nationality"
                  labelField={t('forms.kyc.nationality')}
                  name="nationality"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="visualName"
                  optionValue="uuid"
                  options={nationalities}
                  classNamePrefix="dark"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <Field
                  id="countryResidence"
                  labelField={t('forms.kyc.countryOfResidence')}
                  name="countryResidence"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="visualName"
                  optionValue="uuid"
                  options={nationalities}
                  classNamePrefix="dark"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-6">
                <Field
                  id="confirm"
                  labelField={t('forms.kyc.confirmData')}
                  name="confirm"
                  component={InputCheckFormik}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-12 col-lg-6">
                <Field
                  id="accept"
                  labelField={t('forms.kyc.acceptTerms')}
                  name="accept"
                  component={InputCheckFormik}
                  withLink={handleShowTermAndConditions}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                <Button type="submit" className="btn btn-lg" variant="primary">
                  {t('forms.kyc.buttons.accept')}
                </Button>
              </ButtonsContainer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

KycAcceptTerms.propTypes = {
  handleSubmit: PropTypes.func,
  handleShowTermAndConditions: PropTypes.func,
  nationalities: PropTypes.array,
};

const KycAcceptTermsF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'KycAcceptTerms',
})(withTranslation()(KycAcceptTerms));

export default KycAcceptTermsF;
