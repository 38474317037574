const urlBase = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/`;

/** PLATFORM STATUS */
export const urlGetStatus = `${urlBase}v1/adm/status`;

/** AUTH **/
export const urllogin = `${urlBase}v1/auth/login`;
export const urlauthProfile = `${urlBase}v1/auth/profile`;
export const urlauthUpline = `${urlBase}v1/auth/upline`;
export const urlauthChangePass = `${urlBase}v1/auth/profile/password`;
export const urlLanguageProfile = `${urlBase}v1/auth/profile/lang`;
export const urlForgotPassword = `${urlBase}v1/auth/forgot`;
export const urlVerifyEmail = `${urlBase}v1/auth/verify`;
export const urlResendVerifyEmail = `${urlBase}v1/auth/resend/email`;
export const urlRecoveryPass = `${urlBase}v1/auth/recovery`;
export const urlManageTwoFA = `${urlBase}v1/security/twofactor/generate`;
export const urlVerifyTwoFA = `${urlBase}v1/security/twofactor/verify`;
export const urlDisableTwoFA = `${urlBase}v1/security/twofactor/disable`;
export const urlAvatar = `${urlBase}v1/auth/profile/avatar`;
export const urlInfoDashboard = `${urlBase}v1/auth/profile/dashboard`;
export const urlResetTFA = `${urlBase}v1/auth/tfa/reset`;
export const urlRecoveryTFA = `${urlBase}v1/auth/tfa/recovery`;
export const urlVerifyActivationAccount = `${urlBase}v1/auth/account/activation/verify`;
export const urlCheckActivationAccount = `${urlBase}v1/auth/account/activation/check`;
export const urlActiveAccount = `${urlBase}v1/auth/account/active`;
/** USER **/
export const urlUserExtRegister = `${urlBase}v1/auth/ext/register`;
export const urlUserRegister = `${urlBase}v1/auth/register`;
export const urlConfirmUserRegister = `${urlBase}v1/auth/register/confirmNode`;
export const urlGetAvatar = `${urlBase}v1/users/{param}/avatar`;
export const urlUserKyc = `${urlBase}v1/kyc`;
export const urlUserKycDeclined = `${urlBase}v1/kyc/declined`;
export const urlUserActiveOnPlatform = `${urlBase}v1/users/platform/active`;
export const urlUserPlatformExternalToken = `${urlBase}v1/users/platform/externalToken`;
export const urlUserKycToken = `${urlBase}v1/kyc/config`;
export const urlUserDuplicated = `${urlBase}v1/auth/duplicated`;
export const urlUserFussion = `${urlBase}v1/users/fixDuplicated`;

/** GLOBALS **/
export const urlGetNationalities = `${urlBase}v1/countries`;
export const urlGetunilevelTree = `${urlBase}v1/tree/unilevel/{param}`;

export const urlPlatformsSecured = `${urlBase}v1/platforms-s`;
export const urlPlatforms = `${urlBase}v1/platforms`;
export const urlUserAcceptTermsKyc = `${urlBase}v1/kyc/acceptTerms`;

export const urlGetNews = `${urlBase}v1/news`;
export const urlGetItem = `${urlBase}v1/items`;
/* GENERATOR_INJECT_URLS */
