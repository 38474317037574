import { createAction } from 'redux-actions';
import { apiGet } from '../../api/index';

import { urlPlatformsSecured, urlPlatforms } from '../../api/urls';

import { FILTER_PLATFORM } from '../../constants/index';

/**
 * Filter list ApiExchange.
 */
export const listAllPlatform = createAction(FILTER_PLATFORM, (param) =>
  apiGet(urlPlatformsSecured, undefined, true)()
);

/**
 * Filter list ApiExchange.
 */
export const getPlatformPublic = createAction('GET_PUBLIC_PLATFORM', (param) =>
  apiGet(urlPlatforms, param, false)()
);
