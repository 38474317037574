/* eslint-disable indent */
import React from 'react';
import Register from '../../../components/user/register';
import Layout from '../../../components/layouts/simpleLayout';
import PropTypes from 'prop-types';

const RegisterPage = (props) => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <Register sponsorUId={props.sponsorUId} activateToken={props.activateToken} />
        </>
      }
    />
  );
};

RegisterPage.propTypes = {
  sponsorUId: PropTypes.string,
};

export default RegisterPage;
