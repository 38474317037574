import styled from 'styled-components';

export const LoginLogoContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
`;

export const LoginLogo = styled.img`
  width: 80%;
`;
