/* eslint-disable indent */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import PreRegisterForm from './preRegister.view';
import * as UserService from '../../../services/UserService';
import { showDialog } from '@smartups-sl/react-utils-components';

class PreRegisterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailSended: false,
    };
  }

  handleOnResend = () => {
    this.setState({ mailSended: false });
  };

  handleSubmit = async (values) => {
    try {
      const result = await UserService.checkEmailActiveAccount(values);
      let text = '';
      let title = '';
      let icon = '';
      if (result?.data && result.data === 'OK') {
        title = this.props.t('forms.preRegister.successTitle');
        text = this.props.t('forms.preRegister.success');
        icon = 'success';
      } else {
        title = 'Error';
        text = this.props.t('forms.preRegister.errors.errorSend');
        icon = 'error';
      }
      const okAction = () => {
        this.props.history.push(`/login`);
      };

      showDialog(
        title,
        text,
        icon,
        undefined,
        'OK',
        false,
        okAction,
        undefined,
        undefined,
        '#E83C7E',
        '#12374e'
      );
    } catch (error) {
      console.log('Error');
    }
  };

  render() {
    return (
      <>
        <PreRegisterForm onSubmit={this.handleSubmit} />
      </>
    );
  }
}

PreRegisterContainer.propTypes = {};

export default withRouter(withTranslation()(PreRegisterContainer));
