export const USER_PERMISSIONS = {
  /* GENERATOR_INJECT_DOMAIN */
  ACCESS_FIDELITY: 'ACCESS_FIDELITY',
  ACCESS_EVO: 'ACCESS_EVO',
  ACCESS_GTR: 'ACCESS_GTR',
  ACCESS_EXT: 'ACCESS_EXT',
  ACCESS_MYPOCKETBOT: 'ACCESS_MYPOCKETBOT',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_SECURITY: 'UPDATE_SECURITY',
};

export function getPermisionsClient(can) {
  can(USER_PERMISSIONS.ACCESS_FIDELITY);
  can(USER_PERMISSIONS.ACCESS_MYPOCKETBOT);
  can(USER_PERMISSIONS.ACCESS_EVO);
  can(USER_PERMISSIONS.ACCESS_GTR);
  can(USER_PERMISSIONS.ACCESS_EXT);
  can(USER_PERMISSIONS.UPDATE_PROFILE);
  can(USER_PERMISSIONS.UPDATE_SECURITY);
}

export function getPermisionsSupport(can) {
  can(USER_PERMISSIONS.ACCESS_FIDELITY);
  can(USER_PERMISSIONS.UPDATE_PROFILE);
  can(USER_PERMISSIONS.UPDATE_SECURITY);
  can(USER_PERMISSIONS.ACCESS_EVO);
  can(USER_PERMISSIONS.ACCESS_GTR);
}

export function getPermisionsClientTestingMode(can, isStaff) {
  can(USER_PERMISSIONS.ACCESS_FIDELITY);
  can(USER_PERMISSIONS.UPDATE_PROFILE);
  can(USER_PERMISSIONS.UPDATE_SECURITY);
  can(USER_PERMISSIONS.ACCESS_EVO);
  if (isStaff) {
    can(USER_PERMISSIONS.ACCESS_GTR);
    can(USER_PERMISSIONS.ACCESS_MYPOCKETBOT);
    can(USER_PERMISSIONS.ACCESS_EXT);
  }
}

export function getPermisionsSupportTestingMode(can, isStaff) {
  can(USER_PERMISSIONS.ACCESS_FIDELITY);
  can(USER_PERMISSIONS.UPDATE_PROFILE);
  can(USER_PERMISSIONS.UPDATE_SECURITY);
  can(USER_PERMISSIONS.ACCESS_EVO);
  if (isStaff) {
    can(USER_PERMISSIONS.ACCESS_GTR);
  }
}

export function getPermisionsAnonymous(can) {
  /* GENERATOR_INJECT_PERMISSSIONS */
}
