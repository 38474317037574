import { store } from '../store/index';
import { setFrom, cleanNav, hiddenInitTour } from '../store/actions/NavActions';
/**
 * Set from page navigation
 */
export const setFromPage = async (from) => {
  store.dispatch(setFrom(from));
};

/**
 * Clean from page navigation
 */
export const setCleanNav = async () => {
  store.dispatch(cleanNav());
};

/**
 * Set from page navigation
 */
export const setFinishInitTour = async () => {
  store.dispatch(hiddenInitTour());
};
