import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import PATHS from '../../../constants/paths';
import { dataModel, validationDataModel } from './preRegister.data.form';
import {
  CenterContainer2,
  ButtonsContainer,
} from '../../../design/styleComponents/globalComponents';
import { Button, Link, Title } from '@smartups-sl/react-basic-components';
import { InputTextFormik } from '@smartups-sl/react-forms-components';
import * as GeneralServices from '../../../services/GeneralServices';
import { ButtonLanguage, ImageButton } from './preRegister.styled';

const PreRegister = (props) => {
  const { t, i18n, handleSubmit } = props;

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    GeneralServices.setPreferendLanguage({ lang: lang });
  };

  return (
    <CenterContainer2 className="row justify-content-center align-items-center">
      <div className="col-10 col-sm-8 col-lg-8">
        <div className="row justify-content-center">
          <div className="col-6 col-sm-6 col-lg-4 d-flex justify-content-center mb-4">
            <ButtonLanguage
              onClick={() => {
                changeLanguage('en');
              }}
            >
              <ImageButton
                src={`/assets/images/en.png`}
                alt={t(`language.languages.en`)}
                title={t(`language.languages.en`)}
              />
              {}
            </ButtonLanguage>
            <ButtonLanguage
              onClick={() => {
                changeLanguage('es');
              }}
            >
              <ImageButton
                src={`/assets/images/es.png`}
                alt={t(`language.languages.es`)}
                title={t(`language.languages.es`)}
              />
            </ButtonLanguage>
          </div>
        </div>
        <Title type="h1" className="text-center" variant="secondary">
          {t('forms.preRegister.title')}
        </Title>
        <div className="row">
          <div className="col-12">
            <div
              className="preRegister"
              dangerouslySetInnerHTML={{
                __html: t('forms.preRegister.description'),
              }}
            />
          </div>
        </div>
        <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <Field
                id="email"
                className="form-control"
                name="email"
                component={InputTextFormik}
                fieldType="email"
                placeholder={t('forms.preRegister.labels.email')}
              />
            </div>
          </div>
          <ButtonsContainer className="text-center">
            <Button type="submit" className="btn btn-lg btn-block" variant="primary">
              {t('forms.preRegister.buttons.send')}
            </Button>
          </ButtonsContainer>
          <div className="row mb-5">
            <div className="col-12 text-center">
              <Link
                to={PATHS.LOGIN}
                className="show-pane-forgot-password"
                type="routerDom"
                variant="secondary"
              >
                <i className="fas fa-long-arrow-alt-left"></i> {t('forms.preRegister.buttons.back')}
              </Link>
            </div>
          </div>
        </form>
      </div>
    </CenterContainer2>
  );
};

PreRegister.propTypes = {
  handleSubmit: PropTypes.func,
};

const PreRegisterF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'PreRegister',
})(withTranslation()(PreRegister));

export default PreRegisterF;
