import { store } from '../store/index';
import {
  userRegister,
  setTemporalData,
  cleanTemporalData,
  updateProfile,
  updateUserAvatar,
  getAvatar,
  getUnilevelTree,
  getAuthProfile,
  getDashBoardInfo,
  updateLanguage,
  registerKycData,
  kycDecline,
  updatePassword,
  // verifyActivation,
  activeUser,
  activeOnPlatform,
  confirmUserNodeRegister,
  checkctivation,
  getUpline,
  getUserExternalToken,
  checkEmailActivation,
  getKycToken,
  getUserDuplicated,
  accountFussion,
  acceptKycTerms,
  setRoleSupport,
  userExtRegister,
} from '../store/actions/UserActions';
import { ROLE_SUPPORT } from '../constants/index';

/**
 * Get auth profile info
 */
export const getAuthUserProfile = async (impersonate = false) => {
  const result = await store.dispatch(getAuthProfile());
  if (impersonate) {
    await store.dispatch(setRoleSupport({ role: ROLE_SUPPORT }));
  }
  if (result && result.payload && result.payload.data) {
    return result.payload;
  }
  return null;
};

/**
 * Get user duplicated
 */
export const getUserDuplicatedActive = async () => {
  const result = await store.dispatch(getUserDuplicated());
  if (result && result.payload && result.payload.data) {
    return result.payload;
  }
  return null;
};

/**
 * Get auth profile info
 */
export const getInfoDashboard = async () => {
  const result = await store.dispatch(getDashBoardInfo());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Register user
 * @param {*} values
 */
export const userPreRegister = async (values) => {
  const objSend = {
    email: values.email.trim(),
    lang: values.lang.trim(),
    password: values.password.trim(),
    referral: values.referral.trim(),
    role: 'client',
  };
  if (values.fromPlatform) {
    objSend.fromPlatform = values.fromPlatform;
  }
  try {
    const result = await store.dispatch(userRegister(objSend));
    if (result && result.payload.data && result.payload.data) {
      if (!result.payload.data.temporalToken) {
        store.dispatch(setTemporalData(result.payload.data));
      }
      return result.payload.data;
    }
  } catch (error) {
    throw error;
  }

  return null;
};

/**
 * Register user
 * @param {*} values
 */
export const userRegisterExt = async (values, extra = null, extraUser = null) => {
  const objSend = {
    email: values.email.trim(),
    password: values.password.trim(),
    lang: values.lang.trim(),
    referral: values.referral.trim(),
    role: 'client',
  };
  if (values.fromPlatform) {
    objSend.fromPlatform = values.fromPlatform;
  }
  if (values.name) {
    objSend.name = values.name;
  }
  if (values.surname) {
    objSend.surname = values.surname;
  }
  if (values.nationality) {
    objSend.nationality = values.nationality;
  }
  if (extra) {
    objSend.extra = extra;
  }
  if (extraUser) {
    objSend.originUserRegister = extraUser;
  }
  try {
    const result = await store.dispatch(userExtRegister(objSend));
    if (result && result.payload.data && result.payload.data) {
      if (!result.payload.data.temporalToken) {
        store.dispatch(setTemporalData(result.payload.data));
      }
      return result.payload.data;
    }
  } catch (error) {
    throw error;
  }

  return null;
};

/**
 * Confirm Register user
 * @param {*} values
 */
export const confirmUserRegister = async (objSend) => {
  try {
    const result = await store.dispatch(confirmUserNodeRegister(objSend));
    if (result?.payload?.data) {
      return result.payload.data;
    }
  } catch (error) {
    throw error;
  }

  return null;
};

/**
 * Send verification token activation
 * @param {*} values
 */
// export const verifyActivationToken = async (token) => {
//   const param = [token];
//   try {
//     const result = await store.dispatch(verifyActivation(param));
//     if (result?.payload?.data) {
//       return result.payload.data;
//     }
//   } catch (error) {
//     throw error;
//   }

//   return null;
// };

/**
 * Check activation user
 * @param {*} values
 */
export const checkActivationUser = async (token) => {
  const param = [token];
  try {
    const result = await store.dispatch(checkctivation(param));
    if (result?.payload?.data) {
      return result.payload.data;
    }
  } catch (error) {
    throw error;
  }

  return null;
};

/**
 * Active old user account
 * @param {*} values
 */
export const activeAccount = async (values, token) => {
  const objSend = {
    // email: values.email,
    password: values.password.trim(),
  };
  try {
    const result = await store.dispatch(activeUser(objSend, [token]));
    if (result?.payload?.data) {
      return result.payload.data;
    }
  } catch (error) {
    throw error;
  }

  return null;
};

/**
 * Update profile
 */
export const updateMyProfile = async (values) => {
  const objSend = {
    name: values.name.trim(),
    surname: values.surname.trim(),
    nationality: values.nationality,
    nickname: values.nickname.trim(),
  };
  if (values.lang && values.lang.length > 0) {
    objSend.lang = values.lang;
  }
  const result = await store.dispatch(updateProfile(objSend));
  if (result && result.payload && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * Update password
 */
export const changePassword = async (values) => {
  const objSend = {
    password: values.password,
  };

  const result = await store.dispatch(updatePassword(objSend));
  if (result && result.payload && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * Clean user tree store.
 */
export const cleanUserData = async () => {
  try {
    store.dispatch(cleanTemporalData());
  } catch (error) {
    throw error;
  }

  return null;
};

/**
 * Update avatar
 */
export const updateAvatar = async (image) => {
  const objSend = {
    avatar: image,
  };
  const result = await store.dispatch(updateUserAvatar(objSend));
  if (result && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * Get User avatar
 */
export const getUserAvatar = async (id) => {
  const objSend = [id];
  const result = await store.dispatch(getAvatar(objSend));
  if (result && result.payload) {
    return result.payload;
  }
  return null;
};

/**
 * Get unilevel tree nodes
 * @param {*} userId
 */
export const getUnilevelTreeNodes = async (userId, platform) => {
  const objSend = [userId];
  if (platform) {
    objSend.push(platform.uuid);
  }
  const result = await store.dispatch(getUnilevelTree(objSend));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Update preferend user language
 */
export const updatePreferendLanguage = async (lang) => {
  const objSend = {
    lang: lang,
  };
  const result = await store.dispatch(updateLanguage(objSend));
  if (result && result.payload && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * Get Token Kyc config.
 */
export const getKycTokenConfig = async () => {
  const result = await store.dispatch(getKycToken());
  if (result?.payload) {
    return result.payload;
  }
  return null;
};

/**
 * Register data kyc.
 */
export const onCompleteKyc = async (values) => {
  const objSend = {
    kycId: values.applicationId,
    data: values,
  };
  const result = await store.dispatch(registerKycData(objSend));
  if (result?.payload) {
    return result.payload;
  }
  return null;
};

/**
 * Register data kyc.
 */
export const acceptTermsKyc = async (values) => {
  const objSend = {
    ...values,
  };
  const result = await store.dispatch(acceptKycTerms(objSend));
  if (result?.payload) {
    return result.payload;
  }
  return null;
};

/**
 * User declined kyc.
 */
export const kycUserDeclined = async () => {
  const objSend = {};
  const result = await store.dispatch(kycDecline(objSend));
  if (result?.payload) {
    return result.payload;
  }
  return null;
};

/**
 * User active Platform.
 */
export const activePlatform = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(activeOnPlatform(param));
  if (result?.payload) {
    return result.payload;
  }
  return null;
};

/**
 * Get upline
 */
export const getUserUpline = async () => {
  const result = await store.dispatch(getUpline());
  if (result && result.payload && result.payload.data) {
    return result.payload;
  }
  return null;
};

/**
 * Get auth profile info
 */
export const getExternalToken = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(getUserExternalToken(param));
  if (result && result.payload && result.payload.data) {
    return result.payload;
  }
  return null;
};

/**
 * Check email activation account
 * @param {*} values
 */
export const checkEmailActiveAccount = async (values) => {
  const objSend = {
    email: values.email,
  };
  try {
    const result = await store.dispatch(checkEmailActivation(objSend));
    if (result?.payload?.data) {
      return result.payload.data;
    }
  } catch (error) {
    throw error;
  }

  return null;
};

/**
 * Account Fusion
 * @param {*} values
 */
export const fussion = async () => {
  try {
    const result = await store.dispatch(accountFussion());
    if (result?.payload?.data) {
      return result.payload.data;
    }
  } catch (error) {
    throw error;
  }

  return null;
};
