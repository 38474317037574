import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Home from './clientHome.view';
import { getLangSelector } from '../../../store/selectors/navSelector';
import { getUrlSponsorSelector } from '../../../store/selectors/userSelector';
import { getUserPersonalInfoSelector } from '../../../store/selectors/authSelector';
import * as UserService from '../../../services/UserService';

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upline: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const result = await UserService.getUserUpline();
    if (result?.data?.uuid) {
      this.setState({ upline: result.data });
    }
  };

  render() {
    const { urlSponsor, lang } = this.props;
    const { upline } = this.state;
    return (
      <>
        <Home urlSponsor={urlSponsor || ''} lang={lang} upline={upline} />
      </>
    );
  }
}

HomeContainer.propTypes = {
  urlSponsor: PropTypes.object,
  infoUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  infoUser: getUserPersonalInfoSelector(state),
  urlSponsor: getUrlSponsorSelector(state),
  lang: getLangSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(HomeContainer)));
