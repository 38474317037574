import React from 'react';
import ClientHome from '../../../components/user/clientHome';
import Layout from '../../../components/layouts/simpleLayout';

const ClientHomePage = () => {
  return (
    <Layout
      body={
        <>
          <ClientHome />
        </>
      }
    />
  );
};

ClientHomePage.propTypes = {};

export default ClientHomePage;
