import React, { useEffect, useState } from 'react';
import * as PlatformService from '../../../services/PlatformService';
import * as UserServices from '../../../services/UserService';
import MarketPlace from './marketPlace.view';
import { useSelector } from 'react-redux';
import {
  getBotStatusSelector,
  getEvoStatusSelector,
  getExtStatusSelector,
  getPocStatusSelector,
  getGtrStatusSelector,
  getAuthInfoSelector,
} from '../../../store/selectors/authSelector';
import { showDialog } from '@smartups-sl/react-utils-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { show, hide } from 'redux-modal';
import ActivationTerms from './activationTerms.view';
import * as FaceBookCampaignService from '../../../services/FaceBookCampaign';

const MarketPlaceContainer = () => {
  const [platforms, setPlatforms] = useState([]);
  const [platformStatus, setPlatformStatus] = useState({
    botStatus: false,
    evoStatus: false,
    gtrStatus: false,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const botStatus = useSelector(getBotStatusSelector);
  const evoStatus = useSelector(getEvoStatusSelector);
  const extStatus = useSelector(getExtStatusSelector);
  const pocStatus = useSelector(getPocStatusSelector);
  const gtrStatus = useSelector(getGtrStatusSelector);
  const userInfo = useSelector(getAuthInfoSelector);

  useEffect(() => {
    const statusBot = botStatus === 'ACTIVE';
    const statusEvo = evoStatus === 'ACTIVE';
    const statusExt = extStatus === 'ACTIVE';
    const statusPoc = pocStatus === 'ACTIVE';
    const statusGtr = gtrStatus === 'ACTIVE';

    setPlatformStatus({
      botStatus: statusBot,
      evoStatus: statusEvo,
      extStatus: statusExt,
      pocStatus: statusPoc,
      gtrStatus: statusGtr,
    });
  }, [botStatus, evoStatus, extStatus, pocStatus, gtrStatus]);

  useEffect(() => {
    PlatformService.listPlatform().then((result) => {
      if (result) {
        setPlatforms(result.docs);
      }
    });
  }, []);

  const handlerClosePopup = (popupName) => {
    dispatch(hide(popupName));
  };

  const executeActivation = async (platform, acceptTerms, acceptPersonalData) => {
    if (platform && acceptTerms && acceptPersonalData) {
      const result = await UserServices.activePlatform(platform.uuid);
      if (result) {
        if (userInfo.fromPlatform && userInfo.extra) {
          FaceBookCampaignService.trackActivate(userInfo.fromPlatform);
        }
        // console.log(result);
      }
      handlerClosePopup('ModalForm');
    } else {
      showDialog(
        t('forms.marketplace.messages.activeService'),
        t('forms.marketplace.messages.activeServiceText'),
        'info',
        false,
        'OK',
        false,
        undefined,
        undefined,
        undefined,
        '#26abe1',
        '#12374e',
        true
      );
    }
  };

  const handlerShowTerms = (url) => {
    window.open(url, '_blank').focus();
  };

  const activeOn = async (platform) => {
    dispatch(
      show('ModalForm', {
        body: (
          <>
            <ActivationTerms
              platform={platform}
              showTerms={handlerShowTerms}
              cancelAction={() => handlerClosePopup('ModalForm')}
              onActive={(acceptTerms, acceptPersonalData) =>
                executeActivation(platform, acceptTerms, acceptPersonalData)
              }
            ></ActivationTerms>
          </>
        ),
      })
    );
  };

  const handlerGotoPlatform = async (platform) => {
    const result = await UserServices.getExternalToken(platform.uuid);
    if (result?.data?.token) {
      window.location.href = `${result.data.urlRedirect}/${result.data.token}`;
    }
  };

  return (
    <MarketPlace
      platforms={platforms}
      activeOn={activeOn}
      platformStatus={platformStatus}
      gotoPlatform={handlerGotoPlatform}
    />
  );
};

MarketPlaceContainer.propTypes = {};

export default MarketPlaceContainer;
