/* eslint-disable indent */
import React from 'react';
import ResendEmail from '../../../components/user/resendEmailVerification';
import Layout from '../../../components/layouts/simpleLayout';
import PropTypes from 'prop-types';
import queryString from 'query-string';

const RegisterPage = (props) => {
  let platform = null;
  const query = queryString.parse(props.location.search);
  if (query && query.platform) {
    platform = query.platform;
  }
  return (
    <Layout
      idPlatform={platform}
      showUserMenu={false}
      body={
        <>
          <ResendEmail sponsorUId={props.sponsorUId} platform={platform} />
        </>
      }
    />
  );
};

RegisterPage.propTypes = {
  sponsorUId: PropTypes.string,
};

export default RegisterPage;
