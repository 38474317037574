import styled from 'styled-components';

export const ImageRegister = styled.img`
  /* border: 1px solid #12374e; */
  width: 100%;
`;

export const ImageLogo = styled.img`
  /* border: 1px solid #12374e; */
  width: 28%;
`;

export const TextRegister = styled.div`
  /* border: 1px solid #12374e; */
  font-size: 2rem;
  font-weight: bold;
`;

export const SubmitButton = styled.button`
  /* border: 1px solid #12374e; */
  background-color: #000;
  color: #fff;

  &:hover {
    background-color: #2b2b2b !important;
    color: #fff;
  }
`;

export const ContainerDataImage = styled.div`
  width: 100%;
  position: absolute;
  top: 40px;
  right: 100px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const TextDataImage = styled.div`
  color: #fff;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28%;
  text-align: center;
  font-size: 1rem;
`;

export const NetShipping = styled.span`
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
`;
