import smartnetTheme from './smartnet';
import defaultTheme from './default';

export const getTheme = (themeName = 'default') => {
  if (themeName === 'smartnet') {
    return smartnetTheme;
  } else {
    return defaultTheme;
  }
};
