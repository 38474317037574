import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import * as AuthService from '../../../../../../services/AuthService';
import PATHS from '../../../../../../constants/paths';
import { Button } from '@smartups-sl/react-basic-components';
import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import { IconStyled } from './styled';
import { showDialog } from '@smartups-sl/react-utils-components';

class UserMenuHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  switchShowMenu = () => {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  };

  onClick = async (info) => {
    const { key } = info;
    const { nickname, t } = this.props;
    this.switchShowMenu();

    switch (key) {
      case 'home':
        this.props.history.push(PATHS.CLIENT_HOME);
        break;
      case 'my_profile':
        this.props.history.push(PATHS.MY_PROFILE);
        break;
      case 'unilevelTree':
        if (nickname) {
          this.props.history.push(PATHS.UNI_TREENODES);
        } else {
          showDialog(
            t('unilevel'),
            t('nickname_required'),
            'warning',
            false,
            'OK',
            false,
            undefined,
            undefined,
            undefined,
            '#E83C7E',
            '#12374e'
          );
        }
        break;
      case 'security':
        this.props.history.push(PATHS.SECURITY);
        break;
      case 'contact':
        this.props.history.push(PATHS.CONTACT);
        break;
      case 'market':
        this.props.history.push(PATHS.MARKET_PLACE);
        break;
      case 'settings':
        this.props.history.push(PATHS.MY_PROFILE);
        break;
      case 'close_session':
        localStorage.removeItem('smartToken');
        await AuthService.logout();
        this.props.history.push(PATHS.LOGIN);
        break;
      default:
        break;
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="buttonMenu" data-tut="reactour__menu">
          <Button type="button" variant="trasparent" onClick={() => this.switchShowMenu()}>
            <i className="fas fa-bars" />
          </Button>
        </div>
        <CSSTransition
          in={this.state.showMenu}
          timeout={400}
          classNames="menu-transition"
          unmountOnExit
          appear
        >
          <div className="menuContainer">
            <Menu mode="inline" onClick={this.onClick}>
              <MenuItem key="home">
                <IconStyled className="fas fa-home" />
                {t('home')}
              </MenuItem>
              <MenuItem key="unilevelTree">
                <IconStyled className="fas fa-sitemap" />
                {t('user_tree')}
              </MenuItem>
              <MenuItem key="market">
                <IconStyled className="fas fa-desktop" />
                {t('market')}
              </MenuItem>
              <MenuItem key="settings">
                <IconStyled className="fas fa-user-cog" />
                {t('settings')}
              </MenuItem>
              <MenuItem key="contact">
                <IconStyled className="fa-solid fa-headset" />
                {t('support')}
              </MenuItem>
              <MenuItem key="close_session">
                <IconStyled className="fas fa-power-off" />
                {t('close_session')}
              </MenuItem>
            </Menu>
          </div>
        </CSSTransition>
      </>
    );
  }
}

UserMenuHeader.propTypes = {
  registerPayed: PropTypes.bool,
  nickname: PropTypes.string,
};

export default withRouter(withTranslation()(UserMenuHeader));
