import { createAction } from 'redux-actions';
import { apiGet } from '../../api/index';
import { urlGetNationalities, urlGetStatus, urlGetNews, urlGetItem } from '../../api/urls';
import {
  GET_NATIONALITIES,
  SET_TIME_ZONE,
  SET_LANGUAGE,
  GET_STATUS,
  PAGE_SIZE_10,
} from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.language) filter += '&language=' + filters.language;
  }

  if (sort) filter += '&sort=' + sort;

  return filter;
};

/**
 * Get platform status.
 */
export const getStatus = createAction(GET_STATUS, () => apiGet(urlGetStatus, undefined, false)());

/**
 * Get nationalities.
 */
export const getNationalities = createAction(GET_NATIONALITIES, () =>
  apiGet(urlGetNationalities, undefined, false)()
);

/**
 * Set TimeZone.
 */
export const settingTimeZone = createAction(SET_TIME_ZONE, (data) => data);

/**
 * Set TimeZone.
 */
export const setLanguage = createAction(SET_LANGUAGE, (data) => data);

/**
 * Filter list BotConfiguration.
 */
export const filterNews = createAction('FILTER_NEWS', (filter, param) =>
  apiGet(urlGetNews + generateFilterQuery(filter), param, true)()
);

/**
 * Get news.
 */
export const getNewsByUuid = createAction('GET_NEWS', (param) =>
  apiGet(urlGetNews, param, false)()
);

/**
 * Get item from smartnet.
 */
export const getItem = createAction('GET_ITEM', (token) => apiGet(urlGetItem, [token], false)());
