import { handleActions } from 'redux-actions';

import { GET_INFO_DASHBOARD } from '../../constants/index';

export const dashboard = handleActions(
  {
    [GET_INFO_DASHBOARD]: (state, action) => ({
      ...state,
      info: action.payload.data,
    }),
  },
  {},
);
