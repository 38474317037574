import * as Yup from 'yup';

export const dataModel = {
  code: '',
};

export const validationDataModel = Yup.object().shape({
  code: Yup.string()
    .required('forms.validations.required')
    .max(6, {
      message: 'forms.validations.maxLength',
      args: { max: 6 },
    })
    .min(6, {
      message: 'forms.validations.minLength',
      args: { min: 6 },
    }),
});

export const setInitialValues = (initialValues) => {
  if (initialValues) {
    const items = Object.keys(dataModel);
    items.forEach((item) => {
      if (initialValues[item]) {
        dataModel[item] = initialValues[item];
      }
    });
    return dataModel;
  }

  return dataModel;
};
