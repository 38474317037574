/* eslint-disable indent */
import React from 'react';
import ResetTFA from '../../../components/security/resetTFA';
import Layout from '../../../components/layouts/centerLayout';

const ResetTFAPage = () => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <ResetTFA />
        </>
      }
    />
  );
};

ResetTFAPage.propTypes = {};

export default ResetTFAPage;
