import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from '../commons/header';
import Sidebar from '../commons/sidebar';
import {
  checkBootstrapTypeBreak,
  checkBootstrapSizeBreak,
} from '../../../utils/device/checkDevice';
import {
  OffCanvas,
  SideBarContainer,
  BodyContainer,
  SettingButton,
  Footer,
  LinkTerms,
} from './styled';
import { ContainerTitle, CustomTiTle } from '../../../design/styleComponents/globalComponents';
import { Title } from '@smartups-sl/react-basic-components';
import PATHS from '../../../constants/paths';

const SettingsLayout = (props) => {
  const { type } = props;
  const breakDevice = checkBootstrapTypeBreak();
  const breakSize = checkBootstrapSizeBreak();
  const [toggle, setToggle] = useState(breakDevice === 'xs' || breakDevice === 'sm' ? false : true);

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const hideShowSidebar = () => {
    setToggle(!toggle);
  };

  let namePageLegal = '';
  let namePagePolicy = '';
  if (i18n.language === 'es') {
    namePageLegal = 'es/legal.html';
    namePagePolicy = 'es/politicas.html';
  } else if (i18n.language === 'en') {
    namePageLegal = 'en/legal.html';
    namePagePolicy = 'en/policy.html';
  } else {
    namePageLegal = 'en/legal.html';
    namePagePolicy = 'en/policy.html';
  }

  const urlLegal = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
    process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
  }/docs/${namePageLegal}`;

  const urlPolicy = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
    process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
  }/docs/${namePagePolicy}`;

  const goTo = (key) => {
    switch (key) {
      case 'my_profile':
        history.push(PATHS.MY_PROFILE);
        break;
      case 'security':
        history.push(PATHS.SECURITY);
        break;
      default:
        break;
    }
  };

  if (type && type === 'vertical') {
    return (
      <>
        <OffCanvas
          className="offcanvas offcanvas-start d-flex justify-content-between flex-wrap flex-column"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <Sidebar offCanvas />
        </OffCanvas>

        <SideBarContainer
          className={`side-navbar ${
            toggle ? 'active-nav' : ''
          }  d-flex justify-content-between flex-wrap flex-column`}
          id="sidebar"
        >
          <Sidebar />
        </SideBarContainer>

        <div className={`my-container  ${toggle ? 'active-cont' : ''}`}>
          <BodyContainer className="container-fluid">
            <Header
              showUserMenu={props.showUserMenu !== undefined ? props.showUserMenu : undefined}
              withSidebar={true}
              toggleFunc={() => {
                hideShowSidebar();
              }}
            />
            <div id="page-content-wrapper">
              <div id="page-content">
                <ContainerTitle className="row">
                  <div className="col-12 px-5 mt-5 mb-5">
                    <Title type="h1" variant="white">
                      {t('settings')}
                    </Title>
                  </div>
                </ContainerTitle>
                <div className="row">
                  <div className="col-3 col-lg-2">
                    <ul className="nav flex-column">
                      <li className="nav-item px-4 mb-3">
                        <SettingButton
                          active={props.active && props.active === 'profile'}
                          type="button"
                          onClick={() => goTo('my_profile')}
                        >
                          {t('my_profile')}
                        </SettingButton>
                      </li>
                      <li className="nav-item px-4 mb-3">
                        <SettingButton
                          active={props.active && props.active === 'security'}
                          type="button"
                          onClick={() => goTo('security')}
                        >
                          {t('security')}
                        </SettingButton>
                      </li>
                    </ul>
                  </div>
                  <div className="col-9 col-lg-10">{props.body}</div>
                </div>
              </div>
            </div>
          </BodyContainer>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div id="sb-site">
          <BodyContainer className="container-fluid" id="page-wrapper">
            <Header
              showUserMenu={props.showUserMenu !== undefined ? props.showUserMenu : undefined}
            />
            <div id="page-content-wrapper">
              <div id="page-content">
                <ContainerTitle className="row justify-content-center">
                  <div className="col-10 py-5">
                    <CustomTiTle type="h3" variant="white">
                      {t('settings')}
                    </CustomTiTle>
                  </div>
                </ContainerTitle>
                <div className="row justify-content-center mt-5">
                  <div className="col-12 col-md-10">
                    <div className="row">
                      <div className="col-12 col-lg-3">
                        <ul className={breakSize < 3 ? 'nav mb-3' : 'nav flex-column'}>
                          <li className="nav-item px-4 mb-3">
                            <SettingButton
                              active={props.active && props.active === 'profile'}
                              type="button"
                              onClick={() => goTo('my_profile')}
                            >
                              {t('my_profile')}
                            </SettingButton>
                          </li>
                          <li className="nav-item px-4 mb-3">
                            <SettingButton
                              active={props.active && props.active === 'security'}
                              type="button"
                              onClick={() => goTo('security')}
                            >
                              {t('security')}
                            </SettingButton>
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 col-lg-9">{props.body}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BodyContainer>
          <Footer>
            <div>
              <LinkTerms variant="primary" to={urlPolicy} target="_blank">
                {t('policy')}
              </LinkTerms>
              <LinkTerms variant="primary" to={urlLegal} target="_blank">
                {t('legal')}
              </LinkTerms>
            </div>
            <div>{t('reserved')}</div>
          </Footer>
        </div>
      </>
    );
  }
};

export default SettingsLayout;
