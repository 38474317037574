import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';
import {
  InputTextFormik,
  InputSelectFormik,
  InputImageFormik,
} from '@smartups-sl/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './myProfile.data.form';
import { ButtonsContainer } from '../../../design/styleComponents/globalComponents';
import { Button } from '@smartups-sl/react-basic-components';
import { Letter, Nickname, Email } from './myProfile.styled';
import { LANGUAGES } from '../../../constants/index';
import { AbilityContext, Can } from '../../permissions/Can';
import { USER_PERMISSIONS } from '../../permissions/rolesPermission';

const MyProfileForm = (props) => {
  const { t, handleSubmit, nationalities, getAvatar, passedKYC, userInfo } = props;
  const context = useContext(AbilityContext);
  const hasUpdate = context.can(USER_PERMISSIONS.UPDATE_PROFILE);

  const blockFields = () => {
    let readonly = true;
    if (passedKYC === 'pending') {
      readonly = false;
    }

    if (!readonly && !hasUpdate) {
      readonly = true;
    }

    return readonly;
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 d-flex mb-4">
              <Letter>
                {userInfo && userInfo.email ? userInfo.email.substr(0, 1).toUpperCase() : ''}
              </Letter>
              <div className="d-flex flex-column ms-3 justify-content-end">
                <Nickname>{userInfo?.nickname || ''}</Nickname>
                <Email>{userInfo?.email || ''}</Email>
              </div>
            </div>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-sm-7 col-lg-8">
                {!userInfo.nickname && (
                  <div className="row">
                    <div className="col-12">
                      <Field
                        id="nickname"
                        className="form-control"
                        labelField={t('forms.myProfile.labels.nickName')}
                        name="nickname"
                        component={InputTextFormik}
                        fieldType="text"
                        placeholder=""
                        readOnly={!hasUpdate}
                      />
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-12">
                    <Field
                      id="name"
                      className="form-control"
                      labelField={t('forms.myProfile.labels.name')}
                      name="name"
                      component={InputTextFormik}
                      fieldType="text"
                      placeholder=""
                      readOnly={blockFields()}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="surname"
                      className="form-control"
                      labelField={t('forms.myProfile.labels.surname')}
                      name="surname"
                      component={InputTextFormik}
                      fieldType="text"
                      placeholder=""
                      readOnly={blockFields()}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="nationality"
                      labelField={t('forms.myProfile.labels.nationality')}
                      name="nationality"
                      component={InputSelectFormik}
                      placeholder=""
                      optionLabel="visualName"
                      optionValue="uuid"
                      options={nationalities}
                      classNamePrefix="dark"
                      readOnly={blockFields()}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="lang"
                      labelField={t('forms.myProfile.labels.preferedLang')}
                      name="lang"
                      component={InputSelectFormik}
                      placeholder=""
                      optionLabel="label"
                      optionValue="code"
                      options={LANGUAGES.map((item) => ({
                        ...item,
                        label: t(item.label),
                      }))}
                      classNamePrefix="dark"
                      readOnly={!hasUpdate}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 order-first order-sm-last col-sm-5 col-lg-4">
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="avatar"
                      className="avatar"
                      labelField={t('forms.myProfile.labels.avatar')}
                      name="avatar"
                      component={InputImageFormik}
                      showPreview
                      singleImage
                      i18n={t}
                      withIcon={false}
                      withLabel={false}
                      functionGetImage={getAvatar}
                      buttonText={t('generic.image.choose')}
                      maxFileSize={1000000}
                      allowExtensions={['.jpg', '.png']}
                      textFileSizeError={t('generic.image.tooBig')}
                      textFileTypeError={t('generic.image.noext')}
                      resizeImage
                      resizeCompressFormat="JPEG"
                      resizeWidth={230}
                      resizeHeight={230}
                      resizeOutType="base64"
                      defaultPreviewWithURL
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <Can do={USER_PERMISSIONS.UPDATE_PROFILE}>
                <ButtonsContainer className="col-10 col-sm-10 containerButtons">
                  <Button type="submit" className="btn btn-md" variant="primary">
                    {t('forms.myProfile.buttons.save')}
                  </Button>
                </ButtonsContainer>
              </Can>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

MyProfileForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  nationalities: PropTypes.array,
  getAvatar: PropTypes.func,
  passedKYC: PropTypes.string,
  userInfo: PropTypes.object,
};

const MyProfileFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
  enableReinitialize: true,
  displayName: 'MyProfileForm',
})(withTranslation()(MyProfileForm));

export default MyProfileFormF;
