import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ViewTFAInfo from './tfa.view';
import * as AuthServices from '../../../services/AuthService';
import PATHS from '../../../constants/paths';
import { getAuthInfoSelector } from '../../../store/selectors/authSelector';
import { getIdPlatformSelector } from '../../../store/selectors/navSelector';
import * as NavService from '../../../services/NavService';

class TfaSecurityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTFA: 'disable',
    };
  }

  componentDidMount = async () => {
    const result = await AuthServices.generateTFA();
    if (result && result.tfacode) {
      this.setState({ dataTFA: result });
    }
  };

  verifyTfaCode = async (values) => {
    const { idPlatform } = this.props;
    const obj = {
      tfacode: values.twofa,
    };
    if (idPlatform) {
      obj.idPlatform = idPlatform;
    }
    const result = await AuthServices.verifyTfaToken(obj);
    if (result && result.token) {
      AuthServices.updateTFATime();
      localStorage.setItem('smartToken', result.token);
      if (idPlatform && result.externalToken) {
        NavService.setCleanNav();
        window.location.href = `${result.uriRedirect}/${result.externalToken}`;
      } else {
        this.props.history.push(PATHS.SECURITY_TFA_REDIRECT);
      }
    }
  };

  render() {
    return <ViewTFAInfo dataTfa={this.state.dataTFA || {}} onSubmit={this.verifyTfaCode} />;
  }
}

TfaSecurityContainer.propTypes = {};

const mapStateToProps = (state) => ({
  info: getAuthInfoSelector(state),
  idPlatform: getIdPlatformSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(TfaSecurityContainer)));
