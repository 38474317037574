import styled from 'styled-components';

export const IconUser = styled.i`
  font-size: 3rem;
  color: #26abe1;
`;

export const IconUserACtive = styled.i`
  font-size: 3rem;
  color: #26abe1;
  margin-left: 18px;
`;

export const IconTree = styled.i`
  font-size: 3rem;
  color: #26abe1;
  margin-top: -15px;
`;

export const IconArrow = styled.i`
  font-size: 3rem;
  color: #e83c7e;
`;

export const TitletIcon = styled.div`
  font-size: 1.5rem;
  color: #032b44;
  margin-bottom: 10px;
`;

export const TitletQuestion = styled.div`
  font-size: 1.5rem;
  color: #e83c7e;
`;
