import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withFormik, Field } from 'formik';
import { dataModel, validationDataModel } from './tfaRecovery.data.form';
import { Center70Container } from './tfaRecovery.styled';
import { Button, Title, Text } from '@smartups-sl/react-basic-components';
import { InputTextFormik } from '@smartups-sl/react-forms-components';

class RecoveryTFA extends Component {
  renderConfigTFA = (dataTfa) => {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-12 text-center tfaQr">
            {dataTfa.QRurl ? <img src={dataTfa.QRurl} alt="2FA Code" /> : ''}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-11 col-md-10 alert alert-dark text-center tfaQrCode">
            {dataTfa.tfacode ? dataTfa.tfacode : ''}
          </div>
        </div>
      </>
    );
  };

  renderVerifyTFA = (handleSubmit, t) => {
    return (
      <>
        <form className="form-horizontal bordered-row" onSubmit={handleSubmit} autoComplete="off">
          <div className="row">
            <div className="col-12">
              <Field
                id="twofa"
                className="form-control"
                labelField={t('forms.tfa.labels.twofa')}
                name="twofa"
                component={InputTextFormik}
                fieldType="text"
                placeholder=""
                typeStyleErrorMessage="in"
              />
            </div>
          </div>
          <div className="bg-default content-box text-center marg-btm-1rem">
            <Button type="submit" className="btn btn-lg" variant="primary">
              {t('forms.tfa.buttons.verify')}
            </Button>
          </div>
        </form>
      </>
    );
  };

  render() {
    const { dataTfa, t, handleSubmit } = this.props;
    return (
      <>
        <Center70Container className="row justify-content-center align-items-center">
          <div className="col-12 col-sm-6 col-lg-6">
            <div className="row">
              <div className="col-12 logo-icon">
                <i className="fas fa-user-shield"></i>
              </div>
            </div>
            <Title type="h2" className="text-center marg-btm-1rem" variant="secondary">
              {t('forms.tfa.title')}
            </Title>
            <Text type="p" className="marg-btm-1rem" variant="secondary">
              {t('forms.resettfa.description3')}
            </Text>
            {dataTfa && dataTfa !== 'disable' && this.renderConfigTFA(dataTfa)}
            {this.renderVerifyTFA(handleSubmit, t)}
          </div>
        </Center70Container>
      </>
    );
  }
}

RecoveryTFA.propTypes = {
  dataTfa: PropTypes.any,
};

const RecoveryTFAF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'RecoveryTFAForm',
})(withTranslation()(RecoveryTFA));

export default RecoveryTFAF;
