import * as Yup from 'yup';

export const dataModel = {
  password: '',
  passwordConfirm: '',
};

export const validationDataModel = Yup.object().shape({
  password: Yup.string()
    .required('forms.validations.required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
      'forms.validations.password',
    ),
  passwordConfirm: Yup.string()
    .required('forms.validations.required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
      'forms.validations.password',
    )
    .oneOf([Yup.ref('password')], 'forms.validations.matchPassword'),
});
