import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as AuthService from '../../../services/AuthService';
import PATHS from '../../../constants/paths';
import { showDialog } from '@smartups-sl/react-utils-components';
import queryString from 'query-string';
import * as PlatformService from '../../../services/PlatformService';
import Loader from 'react-loader-spinner';
import { Text, Container, DostContainer } from './emailVerify.styled';

class VerificationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  redirectTo = async (fromPlatform = null) => {
    let platform = null;
    let uri = null;
    if (fromPlatform) {
      const result = await PlatformService.getPublicPlatform(fromPlatform);
      if (result) {
        platform = result;
      }
      if (platform && platform.typePlatform === 'EXTERNAL') {
        uri = platform.uriRedirectLogin;
      } else {
        uri = `/auth/login/${fromPlatform}`;
      }
    } else {
      uri = PATHS.LOGIN;
    }

    if (platform && platform.typePlatform === 'EXTERNAL') {
      window.location.href = uri;
    } else {
      this.props.history.push(uri);
    }
  };

  async componentDidMount() {
    let title = '';
    let text = '';
    let type = '';
    let autoRedirect = false;
    let result;
    // let uri;
    let showNormalDialog = true;

    const query = queryString.parse(this.props.location.search);
    let platform = query && query.fromPlatform ? query.fromPlatform : null;
    // if (query && query.fromPlatform) {
    //   const result = await PlatformService.listPlatform();
    //   if (result && result.docs.length > 0) {
    //     platform = result.docs.find((plat) => plat.internalKey === query.fromPlatform);
    //   }
    //   if (platform && platform.typePlatform === 'EXTERNAL') {
    //     uri = `/auth/login/${query.fromPlatform}`;
    //   } else {
    //     uri = `/auth/login/${query.fromPlatform}`;
    //   }
    // } else {
    //   uri = PATHS.LOGIN;
    // }

    if (this.props.token) {
      result = await AuthService.verifyEmail(
        this.props.token,
        query.fromPlatform
          ? query.fromPlatform === 'evolution_shock'
            ? 'evolution'
            : query.fromPlatform
          : undefined
      );
      if (result.error) {
        if (result.error.code === 'USER.VERIFICATION_EMAIL.BAD_TOKEN') {
          showNormalDialog = false;

          text = this.props.t('forms.verify.messages.errors.token_expired');

          const okAction = async () => {
            const result = await AuthService.resendVerifyEmail(this.props.token);
            if (result && result.payload) {
              let okActionSend = undefined;
              okActionSend = () => {
                this.redirectTo(platform);
              };

              showDialog(
                this.props.t('forms.verify.messages.titleResend'),
                this.props.t('forms.verify.messages.resendSuccess'),
                'success',
                false,
                'OK',
                false,
                okActionSend,
                undefined,
                undefined,
                '#E83C7E',
                '#12374e'
              );
            }
          };

          const cancelAction = () => {
            this.redirectTo(platform);
          };

          type = 'warning';
          showDialog(
            title,
            text,
            type,
            true,
            this.props.t('forms.verify.buttons.sendme'),
            false,
            okAction,
            this.props.t('forms.verify.buttons.noSendme'),
            cancelAction,
            '#E83C7E',
            '#12374e'
          );
        } else {
          title = 'Error';
          text = this.props.t('forms.verify.messages.errors.general');
          type = 'error';
        }
      } else {
        if (result && result.payload && result.payload.status === 'OK') {
          if (query.fromPlatform && result.externalToken) {
            window.location.href = `${result.uriRedirect}/${result.externalToken}${
              query.fromPlatform === 'evolution_shock' ? '?to=course' : ''
            }`;
            autoRedirect = true;
          }
          title = this.props.t('forms.verify.title');
          text = this.props.t('forms.verify.messages.success');
          type = 'success';
        } else {
          title = 'Error';
          text = this.props.t('forms.verify.messages.errors.general');
          type = 'error';
        }
      }
    } else {
      title = 'Error';
      text = this.props.t('forms.verify.messages.errors.noToken');
      type = 'error';
    }

    if (showNormalDialog) {
      let okAction = undefined;
      okAction = () => {
        if (autoRedirect) {
          window.location.href = `${result.uriRedirect}/${result.externalToken}${
            query.fromPlatform === 'evolution_shock' ? '?to=course' : ''
          }`;
        } else {
          this.redirectTo(platform);
        }
      };

      showDialog(
        title,
        text,
        type,
        false,
        'OK',
        false,
        okAction,
        undefined,
        undefined,
        '#E83C7E',
        '#12374e'
      );
    }

    this.setState({ loading: false });
  }

  render() {
    const { t } = this.props;
    const { loading } = this.state;
    return (
      <Container>
        {loading ? (
          <>
            <div className="row justify-content-center">
              <Text className="col-12 text-center">{t('verification')}</Text>
            </div>
            <div className="row justify-content-center mt-4">
              <div className="col-12">
                <DostContainer>
                  <Loader type="ThreeDots" color="#fff" height={100} width={100} />
                </DostContainer>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </Container>
    );
  }
}

export default withRouter(withTranslation()(VerificationContainer));
