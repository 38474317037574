import { createSelector } from 'reselect';

const getStateNavSelector = (state) => state.nav;

export const getNavFromSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.from) {
    return nav.from;
  }

  return undefined;
});

export const getTimeZoneSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.timeZone) {
    return nav.timeZone;
  }

  return undefined;
});

export const getLangSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.language) {
    return nav.language;
  }

  return undefined;
});

export const getIdPlatformSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.idPlatform) {
    return nav.idPlatform;
  }

  return undefined;
});

export const getHiddenInitTourSelector = createSelector(getStateNavSelector, (nav) => {
  if (nav && nav.hiddenInitTour) {
    return nav.hiddenInitTour;
  }

  return undefined;
});
