import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { CenterContainer } from '../../../design/styleComponents/globalComponents';
import { LoginLogoContainer, LoginLogo } from './maintenance.styled';
import { Title } from '@smartups-sl/react-basic-components';

const MaintenanceForm = (props) => {
  const { t } = props;
  return (
    <CenterContainer className="row justify-content-center align-items-center">
      <div className="col-10 col-sm-8 col-lg-5">
        <LoginLogoContainer>
          <LoginLogo
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_login.png`}
            alt="logo"
            className="logo"
          />
        </LoginLogoContainer>
        <Title type="h3" className="text-center" variant="white">
          {t('forms.maintenance.title')}
        </Title>
        <Title type="h5" className="text-center" variant="primary">
          {t('forms.maintenance.description')}
        </Title>
      </div>
    </CenterContainer>
  );
};

MaintenanceForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default withTranslation()(MaintenanceForm);
