import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RegisterForm from './register.view';
import SelectNode from './register.selectNode.view';
import TermConditions from '../../others/termAndConditions';
import PATHS from '../../../constants/paths';
import { withTranslation } from 'react-i18next';
import * as UserService from '../../../services/UserService';
import { showDialog } from '@smartups-sl/react-utils-components';
import { Link } from '@smartups-sl/react-basic-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';

class RegisterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: false,
      values: null,
      captcha: null,
      nodeSelected: null,
      temporalData: null,
      fromPlatform: null,
      dataUserToActivate: null,
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.fromPlatform) {
      this.setState({ fromPlatform: query.fromPlatform });
    }
    if (this.props.activateToken) {
      this.ckeckUser();
    }
  }

  ckeckUser = async () => {
    if (this.props.activateToken) {
      const result = await UserService.checkActivationUser(this.props.activateToken);
      if (result?.uuid) {
        this.setState({ dataUserToActivate: result, values: { email: result.oldEmail } });
      } else {
        this.props.history.push(PATHS.LOGIN);
      }
    }
  };

  confirmRegisterNode = async (values) => {
    if (this.state.temporalData && this.state.nodeSelected) {
      const { temporalToken, from } = this.state.temporalData;
      const obj = { nodeSelected: this.state.nodeSelected, temporalToken, verifyCode: values.code };
      if (from) {
        obj.fromPlatform = from;
      }
      const result = await UserService.confirmUserRegister(obj);
      if (result?.fromPlatform && result?.fromPlatform !== 'ActivationEmail') {
        this.props.history.push(`/auth/login/${result.fromPlatform}`);
      } else {
        const okAction = () => {
          this.props.history.push(PATHS.LOGIN);
        };

        const title = this.props.t('forms.register.messages.activation.title');
        const text = this.props.t('forms.register.messages.activation.success');

        showDialog(
          title,
          text,
          'success',
          false,
          'OK',
          false,
          okAction,
          undefined,
          undefined,
          '#E83C7E',
          '#12374e'
        );
      }
    } else {
      let text = this.props.t('forms.register.messages.error.temporalData');
      if (!this.state.nodeSelected) {
        text = this.props.t('forms.register.messages.error.nodeSelected');
      }

      showDialog(
        'Error',
        text,
        'error',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        '#E83C7E',
        '#12374e'
      );
    }
  };

  handleSubmit = async (values) => {
    try {
      if (this.state.captcha) {
        if (this.props.sponsorUId) {
          values.referral = this.props.sponsorUId;
          if (this.state.fromPlatform) {
            values.fromPlatform = this.state.fromPlatform;
          }
          values.lang = this.props.i18n.language;
          const result = await UserService.userPreRegister(values);
          if (result) {
            if (result.temporalToken) {
              const setData = {
                temporalData: result,
              };
              if (result.allowSelectSmartnetNode) {
                setData.nodeSelected = 'newSmartnetAccount';
              } else if (result.nodes && result.nodes.length > 0) {
                setData.nodeSelected = result.nodes[0].uuid;
              }
              this.setState(setData);
            } else {
              this.props.history.push(PATHS.CONFIRM_REGISTER);
            }
          } else {
            const text = this.props.t('forms.register.messages.error.general');
            showDialog(
              'Error',
              text,
              'error',
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              '#E83C7E',
              '#12374e'
            );
          }
        } else if (this.props.activateToken) {
          const result = await UserService.activeAccount(values, this.props.activateToken);
          if (result) {
            if (result.temporalToken) {
              const setData = {
                temporalData: result,
              };
              if (result.nodes && result.nodes.length > 0) {
                setData.nodeSelected = result.nodes[0].uuid;
              }
              this.setState(setData);
            } else {
              this.props.history.push(PATHS.CONFIRM_REGISTER);
            }
          } else {
            //TODO Error
            this.props.history.push(PATHS.LOGIN);
            console.log('ERORR');
          }
        } else {
          this.props.history.push(PATHS.LOGIN);
        }
      } else {
        const text = this.props.t('forms.register.messages.error.recaptcha');
        showDialog(
          'Error',
          text,
          'error',
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          '#E83C7E',
          '#12374e'
        );
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleShowTermAndConditions = (values) => {
    if (values) {
      this.setState(() => ({ showTerms: !this.state.showTerms, values }));
    } else {
      this.setState(() => ({ showTerms: !this.state.showTerms }));
    }
  };

  linkTermAndConditions = (values) => {
    const { t, i18n } = this.props;
    let namePage = t('termsFile');
    if (i18n.language === 'es') {
      namePage = 'es/terminos.html';
    } else if (i18n.language === 'en') {
      namePage = 'en/terms.html';
    } else {
      namePage = 'es/terminos.html';
    }
    const url = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
      process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
    }/docs/${namePage}`;
    return (
      <Link
        variant="primary"
        to={url}
        target="_blank"
        // onClick={() => {
        //   this.handleShowTermAndConditions(values);
        // }}
      >
        {t('forms.register.buttons.view_term')}
      </Link>
    );
  };

  handleOnchangeCaptcha = (value) => {
    if (value) {
      this.setState({ captcha: value });
    } else {
      this.setState({ captcha: null });
    }
  };

  onChangeNodeSelected = (nodeUuid, event) => {
    if (event.target.checked) {
      this.setState({ nodeSelected: nodeUuid });
    }
  };

  render() {
    return (
      <>
        {this.state.showTerms && <TermConditions onBack={this.handleShowTermAndConditions} />}
        {!this.state.showTerms && (
          <>
            {this.state.temporalData ? (
              <SelectNode
                onSubmit={this.confirmRegisterNode}
                nodes={this.state.temporalData?.nodes || []}
                oldUser={this.state.temporalData?.user || null}
                from={this.state.temporalData?.from || null}
                allowSelectSmartnetNode={this.state.temporalData.allowSelectSmartnetNode || false}
                onChangeNodeSelected={this.onChangeNodeSelected}
                nodeSelected={this.state.nodeSelected}
              />
            ) : (
              <RegisterForm
                activateToken={this.props.activateToken}
                initialValues={this.state.values || {}}
                onSubmit={this.handleSubmit}
                handleShowTermAndConditions={(values) => this.linkTermAndConditions(values)}
                handleOnchangeCaptcha={(value) => this.handleOnchangeCaptcha(value)}
              />
            )}
          </>
        )}
      </>
    );
  }
}

RegisterContainer.propTypes = {
  activateToken: PropTypes.string,
  sponsorUId: PropTypes.string,
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(RegisterContainer)));
