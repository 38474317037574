import * as Yup from 'yup';

export const dataModel = {
  name: '',
  surname: '',
  nationality: '',
  email: '',
  password: '',
  passwordConfirm: '',
  termAndConditions: false,
  termAndConditionsPlt: false,
};

Yup.addMethod(Yup.string, 'validEmail', function (errorMessage) {
  const test = this.test(`test-validEmail`, errorMessage, function (value) {
    const { path, createError, parent } = this;
    let result = true;
    const data = value ? value.toLowerCase() : '';
    // if (data.includes('@hotmail')) {
    //   result = createError({
    //     path,
    //     message: {
    //       message: 'forms.validations.string.email.hotmail',
    //     },
    //   });
    //   return result;
    // }
    return true;
  });
  return test;
});

export const validationDataModel = Yup.object().shape({
  name: Yup.string().required('forms.validations.required'),
  surname: Yup.string().required('forms.validations.required'),
  nationality: Yup.string().required('forms.validations.required'),
  email: Yup.string()
    .required('forms.validations.required')
    .email('forms.validations.email')
    .validEmail(),
  password: Yup.string()
    .required('forms.validations.required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
      'forms.validations.password'
    ),
  passwordConfirm: Yup.string()
    .required('forms.validations.required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s)(?=.*[^\da-zA-Z]).{6,15}$/,
      'forms.validations.password'
    )
    .oneOf([Yup.ref('password')], 'forms.validations.matchPassword'),
  termAndConditions: Yup.boolean()
    .required('forms.validations.required')
    .oneOf([true], 'forms.validations.mustSelect'),
  termAndConditionsPlt: Yup.boolean()
    .required('forms.validations.required')
    .oneOf([true], 'forms.validations.mustSelect'),
});

export const setInitialValues = (initialValues) => {
  if (initialValues) {
    const items = Object.keys(dataModel);
    items.forEach((item) => {
      if (initialValues[item]) {
        dataModel[item] = initialValues[item];
      }
    });
    return dataModel;
  }

  return dataModel;
};
