import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as GeneralServices from '../../../../services/GeneralServices';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  ContainerImage,
  ContainerText,
  ResumeNews,
  TitleNews,
  DateNews,
  ContainerListNews,
  ContainerTitleNews,
  ContainerInfinite,
  ImageList,
} from './ListNews.styled';
import { Can, AbilityContext } from '../../../permissions/Can';
import { InfiniteList } from '@smartups-sl/react-complex-components';
import moment from 'moment-timezone';
import ContainerExpandable from '../../../utils/commons/containerExpandable';
import ContentLoader from 'react-content-loader';
import { checkBootstrapSizeBreak } from '../../../../utils/device/checkDevice';
import { connect } from 'react-redux';
import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';

class NewsListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      items: [],
      finish: true,
      objFilter: undefined,
      breakDevice: checkBootstrapSizeBreak(),
      currentLanguage: this.props.i18n.language,
    };
  }

  async componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
    if (this.props.i18n.language !== this.state.currentLanguage) {
      this.resetRefreshDataLang(this.props.i18n.language);
    }
  }

  resetRefreshDataLang = (lang) => {
    this.setState({ refreshData: true, currentLanguage: lang });
  };

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  handlerGotoNews = (news) => {
    this.props.history.push(`/news/details/${news.uuid}`);
  };

  renderRowExchange = (news) => {
    return (
      <ContainerExpandable
        key={news.uuid}
        header={this.renderHeaderRow(news)}
        onClickActionOpen={() => this.handlerGotoNews(news)}
        // body={this.renderBodyRow(news)}
        classNameHeader=""
      />
    );
  };

  renderHeaderRow = (news) => {
    const { t, timeZone } = this.props;
    const date = moment(news.updatedAt);
    return (
      <div className="row align-items-center">
        <div className="col-12 d-flex flex-column flex-md-row">
          <ContainerImage platform={news.platform} className="">
            {news.miniatureUrl ? (
              <ImageList platform={news.platform} src={news.miniatureUrl}></ImageList>
            ) : (
              <ImageList
                platform={news.platform}
                src={`/assets/images/newsMini_${news?.platform}.png`}
              ></ImageList>
            )}
          </ContainerImage>
          <ContainerText>
            <TitleNews>{news.title}</TitleNews>
            <DateNews>{date.tz(timeZone).format('DD-MM-yyyy : HH:mm')}</DateNews>
            <ResumeNews>{news.resume}</ResumeNews>
          </ContainerText>
        </div>
      </div>
    );
  };

  toList = async (filter) => {
    const { i18n } = this.props;

    if (!filter) {
      filter = {
        page: 1,
        pageSize: 10,
        filters: {
          language: i18n.language,
        },
      };
    } else {
      if (filter.filters) {
        filter.filters.language = i18n.language;
      } else {
        filter.filters = {
          language: i18n.language,
        };
      }
    }
    const result = await GeneralServices.listNews(filter);
    return result;
  };

  renderListLoader = () => {
    return (
      <div className="row">
        <div className="col-12 mt-4">
          <ContentLoader
            speed={2}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            style={{ width: '100%' }}
          >
            <rect x="5" y="1" rx="3" ry="3" width="400" height="5" />
            <rect x="5" y="15" rx="3" ry="3" width="400" height="5" />
          </ContentLoader>
        </div>
      </div>
    );
  };

  render() {
    const { t, filters, className } = this.props;
    return (
      <ContainerListNews>
        <ContainerTitleNews>
          <div data-tut="reactour__news">{t('newsPlatform')}</div>
        </ContainerTitleNews>
        <ContainerInfinite>
          <InfiniteList
            id="listNews"
            renderItem={this.renderRowExchange}
            toList={this.toList}
            // filters={filters}
            variantTitle="primary"
            forceRefresh={this.state.refreshData}
            endMessage={t('goToTop')}
            messageButtonMore={t('readMore')}
            iconAdd="fas fa-plus"
            classNameLinkAdd="actionAdd"
            floatButton
            variantButtonMore="primary"
            headerComponentList={this.renderHeaderList}
            renderLoader={this.renderListLoader}
          />
        </ContainerInfinite>
      </ContainerListNews>
    );
  }
}

NewsListContainer.contextType = AbilityContext;

NewsListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  timeZone: getTimeZoneSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(NewsListContainer)));
