/* eslint-disable indent */
import React from 'react';
import Duplicated from '../../../components/user/duplicated';
import Layout from '../../../components/layouts/simpleLayout';
import PropTypes from 'prop-types';

const DuplicatedPage = (props) => {
  return (
    <Layout
      showUserMenu={false}
      body={
        <>
          <Duplicated />
        </>
      }
    />
  );
};

DuplicatedPage.propTypes = {};

export default DuplicatedPage;
