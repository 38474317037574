import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { InputTextFormik, InputCheckFormik } from '@smartups-sl/react-forms-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { dataModel, validationDataModel, setInitialValues } from '../registerPlt.data.form';
import {
  ImageRegister,
  TextRegister,
  SubmitButton,
  ImageLogo,
  ContainerDataImage,
  TextDataImage,
  NetShipping,
} from './evolution.styled';
import { Link } from '@smartups-sl/react-basic-components';
import PATHS from '../../../../constants/paths';

const RegisterPltForm = (props) => {
  const { t, handleSubmit, handleShowTermAndConditions, handleOnchangeCaptcha, platform } = props;
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-8 no-padding-left no-padding-right d-none d-md-block">
          <ImageRegister
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/platform/Registro_Curso.jpg`}
            alt=""
          />
        </div>
        <div className="col-12 no-padding-left no-padding-right d-block d-md-none">
          <ImageRegister
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/platform/Registro_Curso_Movil.jpg`}
            alt=""
          />
        </div>
        <div className="col-12 col-md-12 col-lg-4 py-5 pe-3 pe-lg-5">
          <div className="row mb-4">
            <TextRegister className="col-12 d-flex justify-content-center">
              {t('freeRegister')}
            </TextRegister>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <Field
                  id="email"
                  className="form-control"
                  labelField={t('forms.register.labels.email')}
                  name="email"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="password"
                  className="form-control"
                  labelField={t('forms.register.labels.password')}
                  name="password"
                  component={InputTextFormik}
                  fieldType="password"
                  showHidePassword
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="passwordConfirm"
                  className="form-control"
                  labelField={t('forms.register.labels.passwordConfirm')}
                  name="passwordConfirm"
                  component={InputTextFormik}
                  fieldType="password"
                  showHidePassword
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="termAndConditions"
                  labelField={t('forms.register.labels.termAndConditions')}
                  name="termAndConditions"
                  component={InputCheckFormik}
                  withLink={handleShowTermAndConditions}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 recaptcha text-center">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleOnchangeCaptcha}
                />
              </div>
            </div>
            <div className="bg-default content-box text-center">
              <SubmitButton
                type="submit"
                className="btn btn-lg"
                variant="primary"
                platform={platform}
              >
                {t('forms.register.buttons.register')}
              </SubmitButton>
            </div>
          </form>
          <div className="row mt-5">
            <div className="col-12 text-center">
              <span>{t('haveAccount')}</span>
              <Link
                to={'/auth/login/evolution'}
                className="show-pane-forgot-password"
                type="routerDom"
                variant="secondary"
              >
                {t('login')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

RegisterPltForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleShowTermAndConditions: PropTypes.func,
  handleOnchangeCaptcha: PropTypes.func,
  platform: PropTypes.string,
};

const RegisterPltFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'RegisterPltForm',
})(withTranslation()(RegisterPltForm));

export default RegisterPltFormF;
