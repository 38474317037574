import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import * as GeneralServices from '../../../../../../services/GeneralServices';
import * as UserService from '../../../../../../services/UserService';
import PropTypes from 'prop-types';
import { LANGUAGES, LANGUAGE_ES } from '../../../../../../constants/index';

class Languages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [],
      selected: undefined,
    };
  }

  componentDidMount() {
    const { preferendLanguage } = this.props;
    let dataLang;
    if (preferendLanguage && preferendLanguage !== this.props.i18n.language) {
      dataLang = preferendLanguage;
    } else {
      const navigatorLang = navigator.language.split('-');
      dataLang =
        navigatorLang && navigatorLang[0]
          ? navigatorLang[0].toLocaleLowerCase()
          : this.props.i18n.language;
    }
    let langD = LANGUAGES.find((lng) => lng.code === dataLang);
    if (!langD) {
      langD = LANGUAGE_ES.code;
    }
    this.changeLanguage(langD.code);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.preferendLanguage !== this.props.preferendLanguage) {
      this.changeLanguage(this.props.preferendLanguage);
    }
  }

  changeLanguage = (lang) => {
    const { token, preferendLanguage } = this.props;
    this.props.i18n.changeLanguage(lang);
    GeneralServices.setPreferendLanguage({ lang: lang });
    this.setState({
      selected: lang ? lang : 'es',
      languages: LANGUAGES.map((lang) => lang.code), //['es', 'en'],
      // languages: this.props.i18n.languages ? this.props.i18n.languages : ['es', 'en'],
    });
    if (token && preferendLanguage && preferendLanguage !== this.props.i18n.language) {
      UserService.updatePreferendLanguage(lang);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="dropdown select-languages">
        <button
          className="btn btn-sm dropdown-toggle language-item"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          id="dropdownMenuButton"
        >
          <img
            src={`/assets/images/${this.state.selected}.png`}
            alt={`language.languages.${this.state.selected}`}
          />
          {this.state.selected
            ? t(`language.languages.${this.state.selected}`)
            : t('language.select_option')}
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {this.state.languages && this.state.languages.length > 0 && (
            <>
              {this.state.languages.map((lang) => {
                return (
                  <li key={lang}>
                    <button
                      className="dropdown-item language-item"
                      onClick={() => {
                        this.changeLanguage(lang);
                      }}
                    >
                      <img src={`/assets/images/${lang}.png`} alt={`language.languages.${lang}`} />
                      {t(`language.languages.${lang}`)}
                    </button>
                  </li>
                );
              })}
            </>
          )}
        </ul>
      </div>
    );
  }
}

Languages.propTypes = {
  preferendLanguage: PropTypes.string,
  token: PropTypes.string,
};

export default withTranslation()(Languages);
