import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ContainerTitle, CustomTiTle } from '../../../design/styleComponents/globalComponents';
import {
  MarketContainer,
  MarketImage,
  MarketLink,
  LandingLink,
  TitleContainer,
  CustomTiTleService,
  StatusActionContainer,
  LabelStatus,
  SwitchStatus,
  MarketImageDisabled,
  ComingSoon,
  MarketLinkActive,
  ButtonGoto,
} from './marketplace.styled';
import { Button } from '@smartups-sl/react-basic-components';
import { Can, AbilityContext } from '../../permissions/Can';
import { USER_PERMISSIONS } from '../../permissions/rolesPermission';

const MarketPlace = (props) => {
  const { activeOn, platforms, platformStatus, gotoPlatform } = props;
  const { t } = useTranslation();
  const context = useContext(AbilityContext);

  const canAccess = (platform) => {
    let access = false;
    if (platform.prefix === 'bot' && context.can(USER_PERMISSIONS.ACCESS_FIDELITY)) {
      access = true;
    }
    if (platform.prefix === 'poc' && context.can(USER_PERMISSIONS.ACCESS_MYPOCKETBOT)) {
      access = true;
    }
    if (platform.prefix === 'evo' && context.can(USER_PERMISSIONS.ACCESS_EVO)) {
      access = true;
    }
    if (platform.prefix === 'ext' && context.can(USER_PERMISSIONS.ACCESS_EVO)) {
      access = true;
    }
    if (platform.prefix === 'gtr' && context.can(USER_PERMISSIONS.ACCESS_GTR)) {
      access = true;
    }
    return access;
  };

  const renderPlatform = (platform) => {
    console.log(platform);
    if (platform.prefix !== 'smartnet' && platform.name !== 'Evolution Shock') {
      const canAcc = canAccess(platform);
      if (!canAcc) {
        platform.status = 'COMING';
      }
      return (
        <div key={platform.uuid} className="col-12 col-md-4 mb-4">
          <MarketContainer>
            <div className="row align-items-center">
              <TitleContainer className="col-12 col-md-12 col-lg-12">
                <CustomTiTleService>{platform.name}</CustomTiTleService>
                <LandingLink href={platform.landing} title={t('gotoLanding')}>
                  <i className="fas fa-external-link-alt"></i>
                </LandingLink>
              </TitleContainer>
              {/* <StatusActionContainer className="col-12 col-md-12 col-lg-6">
                <LabelStatus>{t('statusAccess')}</LabelStatus>
                <div className="serviceStatusContainer form-switch">
                  <SwitchStatus
                    statusCheck={platformStatus[`${platform.prefix}Status`]}
                    className="serviceStatus form-check-input"
                    type="checkbox"
                    id={`${platform.uuid}_status`}
                    onChange={(event) => handlerOnchangeServciceStatus(platform, event)}
                    checked={platformStatus[`${platform.prefix}Status`]}
                    disabled={platform.status === 'COMING'}
                  />
                </div>
              </StatusActionContainer> */}
            </div>

            <div className="row">
              <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                {platform.status === 'ACTIVE' ? (
                  <>
                    {platformStatus[`${platform.prefix}Status`] ? (
                      <>
                        {/* <MarketLinkActive > */}
                        <MarketImage
                          src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${platform.prefix}.png`}
                          alt="logo"
                          title={`Ir a ${platform.name}`}
                        />
                        {/* </MarketLinkActive> */}
                        <ButtonGoto
                          type="button"
                          className="btn btn-md btn-block"
                          variant="terciary"
                          onClick={() => gotoPlatform(platform)}
                        >
                          {`${t('forms.marketplace.buttons.goto')}${platform.name}`}
                        </ButtonGoto>
                      </>
                    ) : (
                      <MarketLink onClick={() => activeOn(platform)}>
                        <MarketImageDisabled
                          src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${platform.prefix}.png`}
                          alt="logo"
                        />
                      </MarketLink>
                    )}
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <MarketImageDisabled
                      src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${platform.prefix}.png`}
                      alt="logo"
                    />
                    <ComingSoon>{t('comingSoon')}</ComingSoon>
                  </div>
                )}
              </div>
            </div>
          </MarketContainer>
        </div>
      );
    } else {
      return '';
    }
  };

  return (
    <>
      <ContainerTitle className="row justify-content-center">
        <div className="col-12 col-md-10 py-5">
          <CustomTiTle type="h3" variant="white">
            {t('forms.marketplace.title')}
          </CustomTiTle>
        </div>
      </ContainerTitle>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11">
          <div className="row">{platforms.map((platform) => renderPlatform(platform))}</div>
        </div>
      </div>
    </>
  );
};

MarketPlace.propTypes = {
  activeOn: PropTypes.func,
  platforms: PropTypes.array,
  platformStatus: PropTypes.object,
  gotoPlatform: PropTypes.func,
};

export default MarketPlace;
