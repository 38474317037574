import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { InputTextFormik } from '@smartups-sl/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './changePassword.data.form';
import { ButtonsContainer } from '../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-basic-components';
import { AbilityContext, Can } from '../../permissions/Can';
import { USER_PERMISSIONS } from '../../permissions/rolesPermission';

const ChangePasswordForm = (props) => {
  const { t, handleSubmit } = props;
  const context = useContext(AbilityContext);
  const hasUpdate = context.can(USER_PERMISSIONS.UPDATE_SECURITY);

  return (
    <>
      <div className="row">
        <div className="col-12 mb-5">
          <div className="row">
            <div className="col-12 mb-2">
              <Title type="h3" variant="secondary">
                <ins>{t('forms.security.changePass')}</ins>
              </Title>
            </div>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-sm-7 col-lg-8">
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="password"
                      className="form-control"
                      labelField={t('forms.myProfile.labels.password')}
                      name="password"
                      component={InputTextFormik}
                      fieldType="password"
                      showHidePassword
                      placeholder=""
                      readOnly={!hasUpdate}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="passwordConfirm"
                      className="form-control"
                      labelField={t('forms.myProfile.labels.passwordConfirm')}
                      name="passwordConfirm"
                      component={InputTextFormik}
                      fieldType="password"
                      showHidePassword
                      placeholder=""
                      readOnly={!hasUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>

            {hasUpdate && (
              <div className="row">
                <ButtonsContainer className="col-10 col-sm-10 containerButtons">
                  <Button type="submit" className="btn btn-md" variant="primary">
                    {t('forms.myProfile.buttons.save')}
                  </Button>
                </ButtonsContainer>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const ChangePasswordFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'ChangePasswordForm',
})(withTranslation()(ChangePasswordForm));

export default ChangePasswordFormF;
