import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { CenterContainer } from '../../../design/styleComponents/globalComponents';
import { LoginLogoContainer, LoginLogo } from './contact.styled';
import { Title } from '@smartups-sl/react-basic-components';

const ContactView = (props) => {
  const { t } = props;
  return (
    <CenterContainer className="row justify-content-center align-items-center mt-5">
      <div className="col-10 col-sm-8 col-lg-5 mt-5">
        <LoginLogoContainer>
          <LoginLogo
            src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_login.png`}
            alt="logo"
            className="logo"
          />
        </LoginLogoContainer>
        <Title type="h1" className="text-center" variant="primary">
          {t('forms.contact.title')}
        </Title>
        <Title type="h5" className="text-center" variant="primary">
          {t('forms.contact.text01')}
        </Title>
        <Title type="h2" className="text-center" variant="terciary">
          {t('forms.contact.text02')}
        </Title>
      </div>
    </CenterContainer>
  );
};

ContactView.propTypes = {
  handleSubmit: PropTypes.func,
};

export default withTranslation()(ContactView);
