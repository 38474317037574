import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-toastify/dist/ReactToastify.css';
import { TitleStyled, ContentStyled, TextHightLightStyled } from './Sponsor.styled';

const SimpleInfo = (props) => {
  const { urls, copyClipboard, title, textHighlight, customDivClassname } = props;
  const { t, i18n } = useTranslation();

  if (copyClipboard) {
  } else {
  }
  return (
    <div className={`row align-items-center ${customDivClassname ? customDivClassname : ''}`}>
      {title && <TitleStyled className="col-12 text-center">{title}</TitleStyled>}
      <ContentStyled className="col-12 text-center">
        {copyClipboard ? (
          <>
            <div>
              <CopyToClipboard
                text={urls.url}
                onCopy={() => {
                  toast(t('linkCopied'), {
                    position: 'top-right',
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                  });
                }}
              >
                {textHighlight ? (
                  <TextHightLightStyled title={t('copyLink')} className="urlSponsord">
                    <i className="fas fa-copy"></i> {urls.url}
                  </TextHightLightStyled>
                ) : (
                  <span title={t('copyLink')} className="urlSponsord">
                    <i className="fas fa-copy"></i> {urls.url}
                  </span>
                )}
              </CopyToClipboard>
            </div>
            {urls.url2[`${i18n.language}`] && (
              <div>
                <CopyToClipboard
                  text={urls.url2[`${i18n.language}`]}
                  onCopy={() => {
                    toast(t('linkCopied'), {
                      position: 'top-right',
                      autoClose: 1500,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: false,
                      progress: undefined,
                    });
                  }}
                >
                  {textHighlight ? (
                    <TextHightLightStyled title={t('copyLink')} className="urlSponsord">
                      <i className="fas fa-copy"></i> {urls.url2[`${i18n.language}`]}
                    </TextHightLightStyled>
                  ) : (
                    <span title={t('copyLink')} className="urlSponsord">
                      <i className="fas fa-copy"></i> {urls.url2[`${i18n.language}`]}
                    </span>
                  )}
                </CopyToClipboard>
              </div>
            )}
          </>
        ) : (
          <>
            {textHighlight ? (
              <TextHightLightStyled title={t('copyLink')} className="">
                {urls.url}
              </TextHightLightStyled>
            ) : (
              <span title={t('copyLink')} className="">
                {urls.url}
              </span>
            )}
          </>
        )}
      </ContentStyled>
    </div>
  );
};

SimpleInfo.propTypes = {
  urls: PropTypes.object,
  copyClipboard: PropTypes.bool,
  title: PropTypes.string,
  textHighlight: PropTypes.bool,
  customDivClassname: PropTypes.string,
};

export default SimpleInfo;
