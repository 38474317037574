import styled from 'styled-components';
import { Title } from '@smartups-sl/react-basic-components';

export const StyledApp = styled.div`
  font-family: ${({ theme }) => theme.fonts.family} !important;
  font-size: ${({ theme }) => theme.fonts.size.md};
`;

export const CenterContainer = styled.div`
  height: 80%;
`;

export const CenterContainer2 = styled.div`
  /* height: 80%; */
`;

export const ButtonsContainer = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

export const CustomTiTle = styled(Title)`
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 5px;
  text-shadow: 1px 1px 2px black;
  color: ${({ theme, platform }) =>
    platform && theme.platforms[`${platform}Text`]
      ? `${theme.platforms[`${platform}Text`]}`
      : '#fff !important'};
`;

export const LoginLogo = styled.img`
  width: 35%;
`;

export const ContainerTitle = styled.div`
  /* background: rgb(18, 55, 78);
  background: linear-gradient(
    333deg,
    rgba(18, 55, 78, 1) 0%,
    rgba(38, 171, 225, 1) 100%,
    rgba(38, 171, 225, 1) 100%
  ); */
  /* background: rgb(59, 194, 248);
  background: linear-gradient(149deg, rgba(59, 194, 248, 1) 35%, rgba(232, 60, 126, 1) 100%); */
  /* background: rgb(59, 194, 248);
  background: linear-gradient(130deg, rgba(59, 194, 248, 1) 21%, rgba(255, 255, 255, 1) 100%); */
  background: ${({ theme, platform }) =>
    platform && theme.platforms[platform] ? theme.platforms[platform] : '#12374e'};
  background: ${({ theme, platform }) =>
    platform && theme.platforms[platform] && theme.platforms[`${platform}Dark`]
      ? `linear-gradient(130deg, ${theme.platforms[`${platform}Dark`]} 0%, ${
          theme.platforms[platform]
        } 30%, #fff 100%)`
      : 'linear-gradient(130deg, #12374e 0%, #3bc2f8 30%, #fff 100%)'};
`;
