import { createAction } from 'redux-actions';
import { apiPost, apiPut, apiGetFile, apiGet } from '../../api/index';
import {
  urlUserRegister,
  urlauthProfile,
  urlAvatar,
  urlGetAvatar,
  urlGetunilevelTree,
  urlInfoDashboard,
  urlLanguageProfile,
  urlUserKyc,
  urlUserKycDeclined,
  urlauthChangePass,
  urlVerifyActivationAccount,
  urlActiveAccount,
  urlUserActiveOnPlatform,
  urlConfirmUserRegister,
  urlCheckActivationAccount,
  urlauthUpline,
  urlUserPlatformExternalToken,
  urlUserKycToken,
  urlUserDuplicated,
  urlUserAcceptTermsKyc,
  urlUserFussion,
  urlUserExtRegister,
} from '../../api/urls';
import {
  USER_REGISTER,
  SET_TEMPORAL,
  CLEAN_TEMPORAL,
  UPDATE_PROFILE,
  UPDATE_AVATAR,
  GET_AVATAR,
  GET_UNILEVEL_TREE,
  SET_AUTH_PROFILE,
  GET_INFO_DASHBOARD,
  VERIFY_ACTIVATION_USER,
  ACTIVE_USER,
  CONFIRM_USER_REGISTER,
  GET_UPLINE,
  SET_USER_SUPPORT,
} from '../../constants/index';

/**
 * Get auth info profile (user loged).
 */
export const getAuthProfile = createAction(SET_AUTH_PROFILE, () =>
  apiGet(urlauthProfile, undefined, true)()
);

/**
 * Get auth info profile (user loged).
 */
export const getUpline = createAction(GET_UPLINE, () => apiGet(urlauthUpline, undefined, true)());

/**
 * Get dashboard info (user loged).
 */
export const getDashBoardInfo = createAction(GET_INFO_DASHBOARD, () =>
  apiGet(urlInfoDashboard, undefined, true)()
);

/**
 * User Register.
 */
export const userRegister = createAction(USER_REGISTER, (obj) =>
  apiPost(urlUserRegister, obj, undefined, false, true)()
);

/**
 * User Register.
 */
export const userExtRegister = createAction(USER_REGISTER, (obj) =>
  apiPost(urlUserExtRegister, obj, undefined, false, true)()
);

/**
 * Confirm User Register.
 */
export const confirmUserNodeRegister = createAction(CONFIRM_USER_REGISTER, (obj) =>
  apiPost(urlConfirmUserRegister, obj, undefined, false)()
);

/**
 * Set temportal data user registered
 */
export const setTemporalData = createAction(SET_TEMPORAL, (data) => data);

/**
 * Clean temportal data user registered
 */
export const cleanTemporalData = createAction(CLEAN_TEMPORAL, () => {});

/**
 * Get auth info profile (user loged).
 */
export const updateProfile = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlauthProfile, obj, undefined, true)()
);

/**
 * Get auth info profile (user loged).
 */
export const updatePassword = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlauthChangePass, obj, undefined, true)()
);

/**
 * Update User Avatar.
 */
export const updateUserAvatar = createAction(UPDATE_AVATAR, (obj) =>
  apiPut(urlAvatar, obj, undefined, true)()
);

/**
 * Get User Avatar.
 */
export const getAvatar = createAction(GET_AVATAR, (param) =>
  apiGetFile(urlGetAvatar, param, true)()
);

/**
 * Get unilevel tree.
 */
export const getUnilevelTree = createAction(GET_UNILEVEL_TREE, (param) =>
  apiGet(urlGetunilevelTree, param, true)()
);

/**
 * Update language.
 */
export const updateLanguage = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlLanguageProfile, obj, undefined, true)()
);

/**
 * Register data KYC.
 */
export const registerKycData = createAction(UPDATE_PROFILE, (obj) =>
  apiPost(urlUserKyc, obj, undefined, true)()
);

/**
 * User decline KYC.
 */
export const kycDecline = createAction(UPDATE_PROFILE, (obj) =>
  apiPost(urlUserKycDeclined, obj, undefined, true)()
);

/**
 * Verify activation user.
 */
// export const verifyActivation = createAction(VERIFY_ACTIVATION_USER, (param) =>
//   apiGet(urlVerifyActivationAccount, param, true)()
// );

/**
 * Activation user Register.
 */
export const activeUser = createAction(ACTIVE_USER, (obj, param) =>
  apiPost(urlActiveAccount, obj, param, false)()
);

/**
 * User active on platform.
 */
export const activeOnPlatform = createAction(SET_AUTH_PROFILE, (param) =>
  apiPost(urlUserActiveOnPlatform, undefined, param, true)()
);

/**
 * Check activation user.
 */
export const checkctivation = createAction('CHECK_ACTIVATION', (param) =>
  apiGet(urlCheckActivationAccount, param, true)()
);

/**
 * Check activation user.
 */
export const getUserExternalToken = createAction('GET_EXTERNAL_TOKEN', (param) =>
  apiGet(urlUserPlatformExternalToken, param, true)()
);

/**
 * Activation user Register.
 */
export const checkEmailActivation = createAction(ACTIVE_USER, (obj) =>
  apiPost(urlVerifyActivationAccount, obj, undefined, false)()
);

/**
 * Get token KYC.
 */
export const getKycToken = createAction('GET_TOKEN_KYC', () =>
  apiGet(urlUserKycToken, undefined, true)()
);

/**
 * Get user duplicated.
 */
export const getUserDuplicated = createAction('USER_DUPLICATED', () =>
  apiGet(urlUserDuplicated, undefined, true)()
);

/**
 * Get fussion Acount.
 */
export const accountFussion = createAction('FUSSION_ACOUNT', () =>
  apiGet(urlUserFussion, undefined, true)()
);

/**
 * Accept Termns KYC.
 */
export const acceptKycTerms = createAction(UPDATE_PROFILE, (obj) =>
  apiPost(urlUserAcceptTermsKyc, obj, undefined, true)()
);

/**
 * Set user role at support
 */
export const setRoleSupport = createAction(SET_USER_SUPPORT, (data) => data);
