import React from 'react';
import Contact from '../../../components/others/contact';
import Layout from '../../../components/layouts/simpleLayout';
// import PropTypes from 'prop-types';

const ContactPage = (props) => {
  return (
    <Layout
      body={
        <>
          <Contact />
        </>
      }
    />
  );
};

ContactPage.propTypes = {
  // uuid: PropTypes.string,
};

export default ContactPage;
