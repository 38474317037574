import { store } from '../store/index';
import {
  getNationalities,
  settingTimeZone,
  setLanguage,
  getStatus,
  filterNews,
  getNewsByUuid,
  getItem,
} from '../store/actions/GeneralActions';
import * as AuthService from './AuthService';
import * as UserService from './UserService';
import * as NavService from './NavService';

/**
 * Get all nationalities
 */
export const getAllNationalities = async () => {
  const result = await store.dispatch(getNationalities());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get platform status
 */
export const getPlatformStatus = async () => {
  const result = await store.dispatch(getStatus());
  if (result && result.payload && result.payload.data) {
    if (result.payload.data.maintenance) {
      AuthService.logout();
      UserService.cleanUserData();
      NavService.setCleanNav();
    }
    return result.payload.data;
  }
  return null;
};

/**
 * Set Time Zone
 */
export const setTimeZone = async (obj) => {
  store.dispatch(settingTimeZone(obj));
};

/**
 * Set preferend language
 */
export const setPreferendLanguage = async (obj) => {
  store.dispatch(setLanguage(obj));
};

/**
 * Get all nationalities
 */
export const listNews = async (filter) => {
  const result = await store.dispatch(filterNews(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get news
 */
export const getNews = async (uuid) => {
  const result = await store.dispatch(getNewsByUuid([uuid]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get auth profile info
 */
export const getItemTemp = async (token) => {
  const result = await store.dispatch(getItem(token));
  if (result?.payload?.data?.item) {
    return result.payload.data.item;
  }
  return null;
};
