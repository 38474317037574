import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button } from '@smartups-sl/react-basic-components';
import { ContainerTitle, CustomTiTle } from '../../../design/styleComponents/globalComponents';
import { ButtonPlt } from './resendEmailVerification.styled';

const VerificationEmailResend = (props) => {
  const { t, onGoToLogin, onResendVerify, from, platform } = props;
  return (
    <>
      <ContainerTitle platform={platform} className="row justify-content-center">
        <div className="col-10 py-5">
          <CustomTiTle type="h3" variant="white" platform={platform}>
            {from === 'register'
              ? t('forms.confirm_register.title')
              : t('forms.confirm_register.title2')}
          </CustomTiTle>
        </div>
      </ContainerTitle>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-7 mt-5">
          <div className="row">
            <div className="col-12 text-confirm-register mb-5">
              {from === 'register'
                ? t('forms.confirm_register.text')
                : t('forms.confirm_register.text2')}
            </div>
          </div>
          <div className="bg-default content-box text-center button-confirm">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-6 col-lg-5 margBottom15">
                <ButtonPlt
                  type="button"
                  className={`btn btn-md btn-block btn-${platform}`}
                  variant="secondary"
                  onClick={() => {
                    onGoToLogin();
                  }}
                >
                  {t('forms.confirm_register.buttons.goLogin')}
                </ButtonPlt>
              </div>
              <div className="col-12 col-md-6 col-lg-5 margBottom15">
                <ButtonPlt
                  type="button"
                  className={`btn btn-md btn-block btn-${platform}`}
                  variant="primary"
                  platform={platform}
                  onClick={() => {
                    onResendVerify();
                  }}
                >
                  {t('forms.confirm_register.buttons.resend')}
                </ButtonPlt>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

VerificationEmailResend.propTypes = {
  onGoToLogin: PropTypes.func,
  onResendVerify: PropTypes.func,
  from: PropTypes.string,
  platform: PropTypes.string,
};

export default withTranslation()(VerificationEmailResend);
