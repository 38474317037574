import React from 'react';
import PropTypes from 'prop-types';
import { init } from 'getid-launcher';
import { useTranslation } from 'react-i18next';
import { Title, Button } from '@smartups-sl/react-basic-components';
import { ButtonsContainer } from '../../../design/styleComponents/globalComponents';

const KycForm = (props) => {
  const { t, i18n } = useTranslation();
  const { uuid, handleCompleteKyc, onback, fromVerify, token, isoResidence } = props;
  let languageSelected = 'en';
  if (i18n.language === 'es') {
    languageSelected = 'es_ES';
  }

  if (token && isoResidence) {
    const config = {
      apiUrl: process.env.REACT_APP_KYC_API_URL,
      jwt: token,
      containerId: 'getid-component',
      flowName: process.env.REACT_APP_KYC_FLOWNAME,
      locale: languageSelected,
      // profile: [],
      profile: [{ category: 'Country of residence', value: isoResidence }],
      metadata: {
        externalId: uuid ? uuid : '',
      },
      onComplete(data) {
        // console.log('---------------everything is complete', data);
        handleCompleteKyc(data);
      },
      onFail({ code, message }) {
        console.error('-----------------something went wrong: ', code, message);
      },
    };

    init(config);
  }

  return (
    <>
      {!token ? (
        <>Loading..</>
      ) : (
        <>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-lg-10">
              <div className="row">
                <div className="col-12 marg-top-40px marg-btm-1rem text-center">
                  <Title type="h3" variant="white">
                    {t('forms.kyc.title')}
                  </Title>
                </div>
              </div>
              <div id="getid-component" />
            </div>
          </div>
          {!fromVerify && (
            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="secondary"
                  onClick={() => onback()}
                >
                  {t('forms.kyc.buttons.back')}
                </Button>
              </ButtonsContainer>
            </div>
          )}
        </>
      )}
    </>
  );
};

KycForm.propTypes = {
  uuid: PropTypes.string,
  handleCompleteKyc: PropTypes.func,
  onback: PropTypes.func,
  fromVerify: PropTypes.bool,
  token: PropTypes.string,
  isoResidence: PropTypes.string,
};

export default KycForm;
