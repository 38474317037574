import styled from 'styled-components';

export const AlertText = styled.span`
  color: #e83c7e;
  margin-bottom: 10px;
  display: flex;
`;

export const RowHeaderStyle = styled.div`
  /* border: 1px solid #12374e; */
  background-color: #12374e;
  color: #fff;
`;

export const CellStyle = styled.div`
  border: 1px solid #12374e;
  align-items: center;
  align-items: center;
  justify-content: center;
  height: 50px;
  display: flex;
  font-size: 0.75rem;
`;
