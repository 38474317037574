import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import MyProfileForm from './myProfile.view';
import PATHS from '../../../constants/paths';
import { withTranslation } from 'react-i18next';
import * as UserService from '../../../services/UserService';
import * as GeneralServices from '../../../services/GeneralServices';
import { getUserPersonalInfoSelector } from '../../../store/selectors/authSelector';
import { showDialog } from '@smartups-sl/react-utils-components';

class MyProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nationalities: undefined,
      from: null,
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.from) {
      this.setState({ from: query.from });
    }
    GeneralServices.getAllNationalities().then((result) => {
      if (result && result.length > 0) {
        this.setState({ nationalities: result });
      }
    });
  }

  handleOnback = () => {
    this.props.history.push(PATHS.CLIENT_HOME);
  };

  updateData = async (values) => {
    try {
      if (values.avatar && !values.avatar.uuid && values.avatar.length > 0) {
        await UserService.updateAvatar(values.avatar.replace('data:image/jpeg;base64,', ''));
      }
      const result = await UserService.updateMyProfile(values);
      let title = '';
      let text = '';
      let icon = '';
      if (result && result === 'OK') {
        title = this.props.t('forms.myProfile.messages.update.title');
        text = this.props.t('forms.myProfile.messages.update.success');
        icon = 'success';
      } else {
        title = 'Error';
        text = this.props.t('forms.myProfile.messages.update.error');
        icon = 'error';
      }

      let okAction = undefined;
      if (this.state.from) {
        okAction = () => {
          this.props.history.push(this.state.from);
        };
      }
      showDialog(
        title,
        text,
        icon,
        false,
        'OK',
        false,
        okAction,
        undefined,
        undefined,
        '#E83C7E',
        '#12374e'
      );
    } catch (error) {
      //console.log('Error', error);
    }
  };
  handleSubmit = async (values) => {
    try {
      if (this.props.info.nickname) {
        await this.updateData(values);
      } else {
        const okAction = async () => {
          await this.updateData(values);
        };

        showDialog(
          this.props.t('forms.myProfile.messages.warningUpdateNickname.title'),
          this.props.t('forms.myProfile.messages.warningUpdateNickname.text'),
          'info',
          true,
          this.props.t('forms.myProfile.messages.warningUpdateNickname.buttonOk'),
          false,
          okAction,
          this.props.t('forms.myProfile.messages.warningUpdateNickname.buttonNo'),
          undefined,
          '#26abe1',
          '#12374e',
          true
        );
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleGetImageAvatar = async (idImage) => {
    // console.log(idImage, this.props.info);
    // const result = await UserService.getUserAvatar(idImage);
    const time = Date.now();
    return `${this.props.info.avatarUrl}?test=${time}`;
  };

  render() {
    return (
      <MyProfileForm
        nationalities={this.state.nationalities || []}
        passedKYC={this.props.info.passedKYC}
        userInfo={this.props.info}
        initialValues={this.props.info}
        onSubmit={this.handleSubmit}
        onback={this.handleOnback}
        getAvatar={this.handleGetImageAvatar}
      />
    );
  }
}

MyProfileContainer.propTypes = {};

const mapStateToProps = (state) => ({
  info: getUserPersonalInfoSelector(state),
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(MyProfileContainer)));
