import styled from 'styled-components';

export const ButtonLanguage = styled.button`
  background-color: transparent;
  border: 0px;
`;

export const ImageButton = styled.img`
  width: 65%;
  max-width: 100px; ;
`;
