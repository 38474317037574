import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { dataModel, validationDataModel } from './login.data.form';
import PATHS from '../../../constants/paths';
import {
  CenterContainer,
  ButtonsContainer,
} from '../../../design/styleComponents/globalComponents';
import {
  LoginLogoContainer,
  LoginLogo,
  LoginLogoExt,
  IconSum,
  ButtonSubmit,
  ButtonForgot,
} from './login.styled';
import { Button, Link, Title } from '@smartups-sl/react-basic-components';
import { InputTextFormik } from '@smartups-sl/react-forms-components';

const LoginForm = (props) => {
  const { t, handleSubmit, idPlatform, forgotPlatform } = props;
  return (
    <CenterContainer className="row justify-content-center align-items-center">
      <div className="col-10 col-sm-8 col-lg-5">
        <LoginLogoContainer>
          {idPlatform ? (
            <LoginLogo
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/${idPlatform}.png`}
              alt="logo"
              className="logo"
            />
          ) : (
            <LoginLogo
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_login.png`}
              alt="logo"
              className="logo"
            />
          )}
        </LoginLogoContainer>
        <Title type="h3" className="text-center" variant="secondary">
          {t('forms.login.title')}
        </Title>
        <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 login-padding-sup">
              <Field
                id="user"
                className="form-control input-login-sup"
                name="user"
                component={InputTextFormik}
                fieldType="text"
                placeholder={t('forms.login.labels.email')}
                typeStyleErrorMessage="in"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 login-padding-sup">
              <Field
                id="password"
                className="form-control input-login-inf"
                name="password"
                component={InputTextFormik}
                fieldType="password"
                showHidePassword
                placeholder={t('forms.login.labels.password')}
                typeStyleErrorMessage="in"
              />
            </div>
          </div>
          <ButtonsContainer className="text-center">
            <Button
              type="submit"
              className="btn btn-lg btn-block"
              variant={idPlatform || 'primary'}
            >
              {t('forms.login.buttons.signin')}
            </Button>
          </ButtonsContainer>
          {idPlatform && (
            <div className="row">
              <div className="col-12 text-center">
                <ButtonForgot type="button" onClick={() => forgotPlatform(idPlatform)}>
                  {t('forms.login.links.forgot')}
                </ButtonForgot>
              </div>
            </div>
          )}
          {!idPlatform && (
            <>
              <div className="row">
                <div className="col-12 text-center">
                  <Link
                    to={PATHS.FORGOT_PASSWORD}
                    className="show-pane-forgot-password"
                    type="routerDom"
                    variant="secondary"
                  >
                    {t('forms.login.links.forgot')}
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <Link
                    to={PATHS.PRE_REGISTER}
                    className="show-pane-forgot-password"
                    type="routerDom"
                    variant="secondary"
                  >
                    {t('forms.login.links.preRegister')}
                  </Link>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </CenterContainer>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  idPlatform: PropTypes.string,
  forgotPlatform: PropTypes.func,
};

const LoginFormF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'LoginForm',
})(withTranslation()(LoginForm));

export default LoginFormF;
