import styled from 'styled-components';

export const ImagePlatform = styled.img`
  max-width: 250px;
`;

export const CustomTiTle = styled.h2`
  font-weight: bold;
`;

export const ImageNews = styled.img`
  width: 100%;
  max-width: 1024px;
`;

export const Author = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

export const BodyNews = styled.div`
  font-size: 0.875rem !important;
  font-weight: normal !important;
`;
