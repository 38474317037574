import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Icon, Title, Text, ButtonContainer, Counter } from './ErrorModal.styled';
import { Button } from '@smartups-sl/react-basic-components';
import ControlTime from '../../security/controlTimeSecurity';
import { LOGIN_ATTEMPS } from '../../../constants/index';
import { useTapGesture } from 'framer-motion';

class ErrorModal extends Component {
  componentDidMount() {}

  render() {
    const { show, handleHide, text, time, t } = this.props;
    const lastLogin = new Date(time);
    return (
      <Modal size="lg" show={show} onHide={handleHide} centered backdrop={useTapGesture}>
        <Modal.Body>
          <div className="row">
            <div className="col-12 text-center">
              <Icon className="far fa-times-circle"></Icon>
            </div>
          </div>
          <div className="row">
            <Title className="col-12 text-center">Error</Title>
          </div>
          <div className="row">
            <Text className="col-12 text-center">{text}</Text>
          </div>
          <div className="row">
            <Counter className="col-12 text-center">
              {t('remaining_time')}
              <ControlTime
                initTime={lastLogin.getTime() || 0}
                timeOut={LOGIN_ATTEMPS}
                onComplete={() => {}}
              />
            </Counter>
          </div>
          <div className="row justify-content-center align-items-center">
            <ButtonContainer className="col-12 col-md-3 col-lg-3 ">
              <Button
                type="button"
                className="btn btn-lg btn-block"
                variant="primary"
                onClick={() => handleHide()}
              >
                OK
              </Button>
            </ButtonContainer>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ErrorModal.propTypes = {
  show: PropTypes.any,
  handleHide: PropTypes.func,
  text: PropTypes.string,
  time: PropTypes.any,
};

export default connectModal({ name: 'ErrorModal' })(withTranslation()(ErrorModal));
