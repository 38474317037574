import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import PATHS from '../../../constants/paths';
import { dataModel, validationDataModel } from './forgotPassword.data.form';
import {
  CenterContainer,
  ButtonsContainer,
  LoginLogo,
} from '../../../design/styleComponents/globalComponents';
import { Button, Link, Title, Text } from '@smartups-sl/react-basic-components';
import { InputTextFormik } from '@smartups-sl/react-forms-components';

const ForgotPassword = (props) => {
  const { t, handleSubmit } = props;
  return (
    <CenterContainer className="row justify-content-center align-items-center">
      <div className="col-10 col-sm-8 col-lg-5">
        <div className="row justify-content-center mb-5">
          <div className="col-12 text-center">
            <LoginLogo
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_login.png`}
              alt="logo"
              className="logo"
            />
          </div>
        </div>
        <Title type="h1" className="text-center" variant="secondary">
          {t('forms.forgotPassword.title')}
        </Title>
        <div className="row">
          <div className="col-12">
            <Text type="p" className="marg-btm-1rem" variant="secondary">
              {t('forms.forgotPassword.description')}
            </Text>
          </div>
        </div>
        <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <Field
                id="email"
                className="form-control"
                name="email"
                component={InputTextFormik}
                fieldType="email"
                placeholder={t('forms.forgotPassword.labels.email')}
              />
            </div>
          </div>
          <ButtonsContainer className="text-center">
            <Button type="submit" className="btn btn-lg btn-block" variant="primary">
              {t('forms.forgotPassword.buttons.send')}
            </Button>
          </ButtonsContainer>
          <div className="row">
            <div className="col-12 text-center">
              <Link
                to={PATHS.LOGIN}
                className="show-pane-forgot-password"
                type="routerDom"
                variant="secondary"
              >
                <i className="fas fa-long-arrow-alt-left"></i>{' '}
                {t('forms.forgotPassword.buttons.back')}
              </Link>
            </div>
          </div>
        </form>
      </div>
    </CenterContainer>
  );
};

ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func,
};

const ForgotPasswordF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'ForgotPassword',
})(withTranslation()(ForgotPassword));

export default ForgotPasswordF;
