import React from 'react';
import PropTypes from 'prop-types';
import UserKyc from '../../../components/user/kyc';
import Layout from '../../../components/layouts/simpleLayout';

const KycPage = (props) => {
  return (
    <Layout
      showUserMenu={props.verify ? true : false}
      body={
        <>
          <UserKyc verify={props.verify} />
        </>
      }
    />
  );
};

KycPage.propTypes = { verify: PropTypes.bool };

export default KycPage;
