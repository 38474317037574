import { store } from '../store/index';
import { listAllPlatform, getPlatformPublic } from '../store/actions/PlatformsActions';

/**
 * List Platform
 */
export const listPlatform = async () => {
  const result = await store.dispatch(listAllPlatform());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List Platform
 */
export const getPublicPlatform = async (internalKey) => {
  const result = await store.dispatch(getPlatformPublic([internalKey]));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
