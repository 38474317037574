import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as AuthService from '../../../services/AuthService';
import * as UserService from '../../../services/UserService';
import * as NavService from '../../../services/NavService';
import { show } from 'redux-modal';
import { showDialog } from '@smartups-sl/react-utils-components';
import PATHS from '../../../constants/paths';
import TFARecovery from './tfaRecovery.view';
import LoginRecovery from './tfaRecovery.login.view';
import ErrorModal from '../../utils/modals/ErrorModal';

class RecoveryTFAContainer extends Component {
  constructor(props) {
    super(props);
    this.handleClear = this.handleClear.bind(this);
    this.handleClear();
    this.state = {
      dataTFA: undefined,
    };
  }

  handleClear = () => {
    try {
      if (this.props.token) {
        AuthService.logout();
        localStorage.removeItem('smartToken');
      }
      UserService.cleanUserData();
      NavService.setCleanNav();
    } catch (error) {
      this.props.history.push(PATHS.ERROR);
    }
  };

  verifyTfaCode = async (values) => {
    const obj = {
      tfacode: values.twofa,
    };
    const result = await AuthService.verifyTfaToken(obj);
    if (result && result.token) {
      const okAction = () => {
        this.props.history.push(PATHS.LOGIN);
      };
      showDialog(
        this.props.t('forms.resettfa.messages.title'),
        this.props.t('forms.resettfa.messages.successRecovery'),
        'success',
        undefined,
        'OK',
        false,
        okAction,
        undefined,
        undefined,
        '#E83C7E',
        '#12374e'
      );
    }
  };

  onLoginSubmit = async (values) => {
    try {
      const resultLogin = await AuthService.login(values);
      if (resultLogin && resultLogin.token) {
        if (this.props.token) {
          AuthService.recoveryTFAUser(this.props.token).then((result) => {
            if (result === 404) {
              this.props.history.push(PATHS.LOGIN);
            } else {
              this.setState({ dataTFA: result });
            }
          });
        }
      } else {
        const text = this.props.t('forms.login.errors.login-incorrect');
        showDialog(
          'Error',
          text,
          'error',
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          '#E83C7E',
          '#12374e'
        );
      }
    } catch (error) {
      if (
        error?.data?.error?.statusCode === 403 &&
        error?.data?.error?.code?.code === 'USER.LOGIN.REACHED_LIMIT_LOGIN'
      ) {
        const text = this.props.t('server.errors.user.login.reached_limit_login');
        this.props.show('ErrorModal', {
          text: text,
          time: error?.data?.error?.code?.date,
        });
      }
    }
  };

  render() {
    return (
      <>
        <ErrorModal />
        {this.state.dataTFA ? (
          <TFARecovery dataTfa={this.state.dataTFA || {}} onSubmit={this.verifyTfaCode} />
        ) : (
          <LoginRecovery onSubmit={this.onLoginSubmit} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(
  connect(mapStateToProps, { show })(withTranslation()(RecoveryTFAContainer))
);
