import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConfirmRegisterForm from './resendEmailVerification.view';
import { withTranslation } from 'react-i18next';
import { getAuthTokenSelector } from '../../../store/selectors/authSelector';
import { getTemporalTokenSelector } from '../../../store/selectors/userSelector';
import PATHS from '../../../constants/paths';
import * as AuthService from '../../../services/AuthService';
import { showDialog } from '@smartups-sl/react-utils-components';
import PropTypes from 'prop-types';
import ConfirmRegisterFormGtrader from './platform/resendEmailVerification.gtrader.view';

class ConfirmRegisterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: 'register',
    };
  }

  componentDidMount() {
    if (this.props.token) {
      this.setState({ from: 'login' });
    }
  }

  goToExternalLogin = () => {
    //TODO from platform-specific
    if (this.props.platform === 'gtrader') {
      window.location.href = `https://app.gtrader.me/log-in`;
    } else {
      this.props.history.push(`/auth/login/${this.props.platform}`);
    }
  };

  handleGoToLogin = () => {
    if (this.props.platform) {
      this.goToExternalLogin();
    } else {
      this.props.history.push(PATHS.LOGIN);
    }
  };

  handleResendVerifyEmail = async () => {
    try {
      let token = '';
      if (this.props.token) {
        token = this.props.token;
      } else if (this.props.temporalToken) {
        token = this.props.temporalToken;
      }
      const result = await AuthService.resendVerifyEmail(token);
      if (result && result.payload.status === 'OK') {
        const text = this.props.t('forms.confirm_register.messages.verify_sended');
        showDialog(
          this.props.t('forms.confirm_register.title'),
          text,
          'success',
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          '#E83C7E',
          '#12374e'
        );
      }
    } catch (error) {
      console.log('Error');
    }
  };

  renderFromPlatform = (platform) => {
    switch (platform) {
      case 'gtrader':
        return (
          <ConfirmRegisterFormGtrader
            onGoToLogin={this.handleGoToLogin}
            onResendVerify={this.handleResendVerifyEmail}
            from={this.state.from}
            platform={this.props.platform}
          />
        );

      default:
        return (
          <ConfirmRegisterForm
            onGoToLogin={this.handleGoToLogin}
            onResendVerify={this.handleResendVerifyEmail}
            from={this.state.from}
            platform={this.props.platform}
          />
        );
    }
  };
  render() {
    const { platform } = this.props;

    return (
      <>
        {platform ? (
          this.renderFromPlatform(platform)
        ) : (
          <ConfirmRegisterForm
            onGoToLogin={this.handleGoToLogin}
            onResendVerify={this.handleResendVerifyEmail}
            from={this.state.from}
            platform={this.props.platform}
          />
        )}
      </>
    );
  }
}

ConfirmRegisterContainer.propTypes = {
  platform: PropTypes.string,
};

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
  temporalToken: getTemporalTokenSelector(state),
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ConfirmRegisterContainer))
);
