import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import {
  InputTextFormik,
  InputCheckFormik,
  InputSelectFormik,
} from '@smartups-sl/react-forms-components';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from '../registerPlt.complete.data.form';
import {
  ImageRegister,
  TextRegister,
  SubmitButton,
  ImageLogo,
  ContainerDataImage,
  TextDataImage,
  NetShipping,
  ContainerPage,
} from './gtrader.styled';

const RegisterPltForm = (props) => {
  const {
    t,
    handleSubmit,
    handleShowTermAndConditions,
    handleShowTermAndConditionsPlt,
    handleOnchangeCaptcha,
    platform,
    nationalities,
  } = props;
  return (
    <>
      <ContainerPage className="row justify-content-center">
        <div className="col-12 col-md-12 col-lg-4 py-5">
          <div className="row mb-4">
            <div className="col-12 d-flex justify-content-center">
              <img
                src={`/assets/themes/${process.env.REACT_APP_THEME}/images/GTrader.png`}
                alt="logo"
                className="logo d-none d-md-block"
              />
            </div>
          </div>
          <div className="row mb-4 mt-5">
            <TextRegister className="col-12 d-flex justify-content-center">
              {t('letStarted')}
            </TextRegister>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-12">
                <Field
                  id="email"
                  classNameContainer="gtrader"
                  className="form-control"
                  labelField={t('forms.register.labels.email')}
                  name="email"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="password"
                  classNameContainer="gtrader"
                  className="form-control"
                  divClassName="gtrader"
                  labelField={t('forms.register.labels.password')}
                  name="password"
                  component={InputTextFormik}
                  fieldType="password"
                  showHidePassword
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  classNameContainer="gtrader"
                  id="passwordConfirm"
                  className="form-control"
                  divClassName="gtrader"
                  labelField={t('forms.register.labels.passwordConfirm')}
                  name="passwordConfirm"
                  component={InputTextFormik}
                  fieldType="password"
                  showHidePassword
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  classNameContainer="gtrader"
                  id="name"
                  className="form-control"
                  labelField={t('forms.myProfile.labels.name')}
                  name="name"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  classNameContainer="gtrader"
                  id="surname"
                  className="form-control"
                  labelField={t('forms.myProfile.labels.surname')}
                  name="surname"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  classNameContainer="gtrader"
                  id="nationality"
                  labelField={t('forms.myProfile.labels.nationality')}
                  name="nationality"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="visualName"
                  optionValue="uuid"
                  options={nationalities}
                  classNamePrefix="dark"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="termAndConditionsPlt"
                  divClassName="gtrader"
                  labelField={t('forms.register.labels.termAndConditions_gtrader')}
                  name="termAndConditionsPlt"
                  component={InputCheckFormik}
                  withLink={handleShowTermAndConditionsPlt}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="termAndConditions"
                  divClassName="gtrader"
                  labelField={t('forms.register.labels.termAndConditions')}
                  name="termAndConditions"
                  component={InputCheckFormik}
                  withLink={handleShowTermAndConditions}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 recaptcha text-center">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleOnchangeCaptcha}
                />
              </div>
            </div>
            <div className="bg-default content-box text-center mt-4">
              <SubmitButton
                type="submit"
                className="btn btn-primary btn-full btn-gtrader ng-binding btn-block"
                variant="primary"
                platform={platform}
              >
                {t('forms.register.buttons.register')}
              </SubmitButton>
            </div>
          </form>
        </div>
      </ContainerPage>
    </>
  );
};

RegisterPltForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleShowTermAndConditions: PropTypes.func,
  handleShowTermAndConditionsPlt: PropTypes.func,
  handleOnchangeCaptcha: PropTypes.func,
  platform: PropTypes.string,
};

const RegisterPltFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'RegisterPltForm',
})(withTranslation()(RegisterPltForm));

export default RegisterPltFormF;
