import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import {
  RowPrincipal,
  ColNopadding,
  ImageAvatar,
  Row,
  ColNopaddingIMG,
  // ImageRank,
  // ColNopaddingRANK,
  // RankText,
  ColCenter,
} from './styled';
import { formatMoney } from '../../../../../utils/formats/numbers';

const SimpleTreeNode = (props) => {
  const { data, firstLevel } = props;
  // const { t } = useTranslation();
  const noImage = `/assets/themes/${process.env.REACT_APP_THEME}/images/no-image.png`;

  // const level1 = firstLevel + 1;
  return (
    <RowPrincipal id={data.id} className="row nodeSimple">
      {data.info.image && data.info.image.length > 0 ? (
        <ColNopaddingIMG className="col-1 col-md-1 col-lg-1 d-none d-md-block">
          <ImageAvatar alt="Avatar" src={data.info.image}></ImageAvatar>
        </ColNopaddingIMG>
      ) : (
        <ColNopaddingIMG className="col-1 col-md-1 col-lg-1 d-none d-md-block">
          <ImageAvatar alt="Avatar" src={noImage}></ImageAvatar>
        </ColNopaddingIMG>
      )}

      {/* <ColNopaddingRANK className="col-2 col-md-2 col-lg-2 no-padding">
        <ImageRank
          alt={''}
          title={''}
          src={`/assets/themes/${process.env.REACT_APP_THEME}/images/Bronce.png`}
        ></ImageRank>
        <RankText title={''} className="d-none d-md-block">
          {''}
        </RankText>
      </ColNopaddingRANK> */}

      <ColCenter className="col-9 col-md-9 col-lg-9">
        {/* {(data.info.level === level1 || data.info.treeLevel) && ( */}
        <Row>
          <ColNopadding className="col-12 col-md-12 col-lg-12">{data?.info?.email}</ColNopadding>
        </Row>
        {/* )} */}
        <Row>
          <ColNopadding className="col-12 col-md-12 col-lg-12">{data.info.nickname}</ColNopadding>
        </Row>
        {data.info.investment !== undefined && (
          <Row>
            <ColNopadding className="col-12 col-md-12 col-lg-12">
              {data.info.investment === 0
                ? `0,00 USDT`
                : `${formatMoney(Number(data.info.investment))} USDT`}
            </ColNopadding>
          </Row>
        )}
      </ColCenter>
    </RowPrincipal>
  );
};

SimpleTreeNode.propTypes = {
  node: PropTypes.object,
  onClickNode: PropTypes.func,
  firstLevel: PropTypes.number,
  nodeVisited: PropTypes.array,
};

export default SimpleTreeNode;
