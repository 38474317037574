import { store } from '../store/index';
import {
  loginUser,
  loginSucess,
  logoutUser,
  updateToken,
  forgotPass,
  verifyEmailRegister,
  recoveryUserPassword,
  getTwoFA,
  verifyTfa,
  disableTfa,
  resendVerifyEmailRegister,
  setTfaTime,
  resetTFA,
  recoveryTFA,
} from '../store/actions/AuthActions';
import * as UserService from './UserService';
/**
 * Login user
 * @param {*} values
 */
export const login = async (values, idPlatform = undefined) => {
  const objSend = {
    email: values.user.toLowerCase(),
    password: values.password,
  };
  if (idPlatform) {
    objSend.idPlatform = idPlatform;
  }
  const result = await store.dispatch(loginUser(objSend));
  if (result && result.payload.data && result.payload.data.token) {
    const auth = {
      token: result.payload.data.token,
      auth2fa: false,
    };
    await store.dispatch(loginSucess(auth));
    if (result.payload.externalData) {
      auth.externalToken = result.payload.externalData.token;
      auth.uriRedirect = result.payload.externalData.urlRedirect;
    } else if (result.payload.accessNotAllowed) {
      auth.accessNotAllowed = true;
    }
    return auth;
  }

  return null;
};

/**
 * Logout user of system
 */
export const logout = async () => {
  await store.dispatch(logoutUser());
};

/**
 * Send reques for password recovery
 * @param {*} values
 */
export const forgotPassword = async (values) => {
  const objSend = {
    email: values.email,
  };
  const result = await store.dispatch(forgotPass(objSend));
  if (result && result.payload && result.payload.status && result.payload.status === 'OK') {
    return 'OK';
  }

  return null;
};

/**
 * Verify user email
 * @param {*} values
 */
export const verifyEmail = async (token, fromPlatform) => {
  const objSend = [token];
  const result = await store.dispatch(verifyEmailRegister(objSend, fromPlatform));
  if (result && result.payload && result.payload.status && result.payload.status === 'OK') {
    if (result.payload.response?.externalData) {
      result.externalToken = result.payload.response.externalData.token;
      result.uriRedirect = result.payload.response.externalData.urlRedirect;
    }
    return result;
  } else if (
    result &&
    result.payload &&
    result.payload.response &&
    result.payload.response.status === 400
  ) {
    return result.payload.response.data;
  }

  return null;
};

/**
 * Resend Verify user email
 * @param {*} values
 */
export const resendVerifyEmail = async (token) => {
  const objSend = {
    token: token,
  };
  const result = await store.dispatch(resendVerifyEmailRegister(objSend));
  if (result && result.payload && result.payload.status && result.payload.status === 'OK') {
    return result;
  } else if (
    result &&
    result.payload &&
    result.payload.response &&
    result.payload.response.status === 400
  ) {
    return result.payload.response.data;
  }

  return null;
};

/**
 * Login user
 * @param {*} values
 */
export const recoveryPassword = async (values) => {
  const objSend = {
    token: values.token,
    password: values.password,
  };
  const result = await store.dispatch(recoveryUserPassword(objSend));
  if (result && result.payload.status && result.payload.status === 'OK') {
    return 'OK';
  }

  return null;
};

/**
 * Get auth profile info
 */
export const enableTwoFA = async () => {
  const result = await store.dispatch(getTwoFA());
  if (result && result.payload && result.payload.data) {
    UserService.getAuthUserProfile();
    return result.payload.data;
  }
  return null;
};

/**
 * Get 2FA validate token
 */
export const generateTFA = async () => {
  const result = await store.dispatch(getTwoFA());
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Verify 2FA validate token
 */
export const verifyTfaToken = async (obj) => {
  const auth = {};
  const result = await store.dispatch(verifyTfa(obj));
  if (result && result.payload && result.payload.data) {
    const newToken = {
      token: result.payload.data.token,
    };
    auth.token = result.payload.data.token;
    await store.dispatch(updateToken(newToken));
    if (result.payload.externalData) {
      auth.externalToken = result.payload.externalData.token;
      auth.uriRedirect = result.payload.externalData.urlRedirect;
    }
    return auth;
  }
  return null;
};

/**
 * Disable 2FA
 */
export const disableTwoFA = async (obj) => {
  const result = await store.dispatch(disableTfa(obj));
  if (result && result.payload && result.payload.data) {
    UserService.getAuthUserProfile();
    return result.payload.data;
  }
  return null;
};

/**
 * SET time tfa verify
 */
export const updateTFATime = async () => {
  const obj = {
    time: Date.now(),
  };
  store.dispatch(setTfaTime(obj));
};

/**
 * Reset TFA User
 */
export const resetMyTFA = async () => {
  const result = await store.dispatch(resetTFA());
  if (result?.payload?.status === 'OK') {
    return 'OK';
  }
  return null;
};

/**
 * Recovery TFA User
 */
export const recoveryTFAUser = async (token) => {
  const param = [token];
  const result = await store.dispatch(recoveryTFA(param));
  if (result?.payload?.data) {
    return result.payload.data;
  } else if (result?.payload?.response?.status === 404) {
    return result?.payload?.response?.status;
  }
  return null;
};

/**
 * Confirm external Login
 * @param {Login} values
 * @returns
 */
export const confirmLocalLogin = async (token) => {
  const auth = {
    token: token,
    auth2fa: false,
  };
  await store.dispatch(loginSucess(auth));
  return auth;
};

/**
 * Login Impersonate
 * @param {Login} values
 * @returns
 */
export const loginImpersonate = async (token) => {
  const auth = {
    token: token,
    auth2fa: false,
  };
  await store.dispatch(loginSucess(auth));
  return auth;
};
