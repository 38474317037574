import styled from 'styled-components';

export const TitleStyled = styled.div`
  font-size: 0.8rem;
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => (theme?.colors?.primary ? theme.colors.primary : '#000')};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const ContentStyled = styled.div`
  margin-top: 10px;
`;

export const TextHightLightStyled = styled.span`
  color: ${({ theme }) => (theme?.colors?.terciary ? theme.colors.terciary : '#000')};
  font-weight: bold;
`;
