/* eslint-disable indent */
import React from 'react';
import ManageSecurity from '../../../components/security/manageSecurity';
import ChangePassword from '../../../components/user/changePassword';
import Layout from '../../../components/layouts/settingsLayout';

const ManageSecurityPage = () => {
  return (
    <Layout
      active="security"
      body={
        <>
          <div className="row">
            <div className="col-12">
              <ChangePassword />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ManageSecurity />
            </div>
          </div>
        </>
      }
    />
  );
};

ManageSecurityPage.propTypes = {};

export default ManageSecurityPage;
