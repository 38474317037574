import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-toastify/dist/ReactToastify.css';
import { TitleStyled, ContentStyled, TextHightLightStyled } from './SimpleInfo.styled';

const SimpleInfo = (props) => {
  const { text, copyClipboard, title, textHighlight, customDivClassname } = props;
  const { t } = useTranslation();

  if (copyClipboard) {
  } else {
  }
  return (
    <div className={`row align-items-center ${customDivClassname ? customDivClassname : ''}`}>
      {title && <TitleStyled className="col-12 text-center">{title}</TitleStyled>}
      <ContentStyled className="col-12 text-center">
        {copyClipboard ? (
          <CopyToClipboard
            text={text}
            onCopy={() => {
              toast(t('linkCopied'), {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
            }}
          >
            {textHighlight ? (
              <TextHightLightStyled title={t('copyLink')} className="urlSponsord">
                <i className="fas fa-copy"></i> {text}
              </TextHightLightStyled>
            ) : (
              <span title={t('copyLink')} className="urlSponsord">
                <i className="fas fa-copy"></i> {text}
              </span>
            )}
          </CopyToClipboard>
        ) : (
          <>
            {textHighlight ? (
              <TextHightLightStyled title={t('copyLink')} className="">
                {text}
              </TextHightLightStyled>
            ) : (
              <span title={t('copyLink')} className="">
                {text}
              </span>
            )}
          </>
        )}
      </ContentStyled>
    </div>
  );
};

SimpleInfo.propTypes = {
  text: PropTypes.string,
  copyClipboard: PropTypes.bool,
  title: PropTypes.string,
  textHighlight: PropTypes.bool,
  customDivClassname: PropTypes.string,
};

export default SimpleInfo;
