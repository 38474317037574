import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withFormik, Field } from 'formik';
import { dataModel, validationDataModel } from './tfa.data.form';
import { Button, Title, Link } from '@smartups-sl/react-basic-components';
import { InputTextFormik } from '@smartups-sl/react-forms-components';
import PATHS from '../../../constants/paths';

class ViewTFAInfo extends Component {
  renderConfigTFA = (dataTfa) => {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-12 text-center tfaQr">
            {dataTfa.QRurl ? <img src={dataTfa.QRurl} alt="2FA Code" /> : ''}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-11 col-md-10 alert alert-dark text-center tfaQrCode">
            {dataTfa.tfacode ? dataTfa.tfacode : ''}
          </div>
        </div>
      </>
    );
  };

  renderVerifyTFA = (handleSubmit, t) => {
    return (
      <>
        <form className="form-horizontal bordered-row" onSubmit={handleSubmit} autoComplete="off">
          <div className="row">
            <div className="col-12">
              <Field
                id="twofa"
                className="form-control"
                labelField={t('forms.tfa.labels.twofa')}
                name="twofa"
                component={InputTextFormik}
                fieldType="text"
                placeholder=""
                typeStyleErrorMessage="in"
              />
            </div>
          </div>
          <div className="bg-default content-box text-center marg-btm-1rem">
            <Button type="submit" className="btn btn-md" variant="primary">
              {t('forms.tfa.buttons.verify')}
            </Button>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <Link
                to={PATHS.RESET_TFA}
                className="show-pane-forgot-password"
                type="routerDom"
                variant="secondary"
              >
                {t('forms.tfa.links.reset')}
              </Link>
            </div>
          </div>
        </form>
      </>
    );
  };

  render() {
    const { dataTfa, t, handleSubmit, fromManage } = this.props;
    return (
      <>
        <div className={`row ${fromManage ? '' : 'justify-content-center align-items-center'}`}>
          <div
            className={`${
              fromManage ? 'col-12 col-lg-8 mb-4' : 'col-10 col-sm-8 col-lg-5 mb-4 text-center'
            }`}
          >
            <Title type="h3" variant="secondary">
              {fromManage ? <u>{t('forms.security.title')}</u> : t('forms.security.title')}
            </Title>
          </div>
        </div>
        <div className={`${fromManage ? 'row' : 'row justify-content-center align-items-center'}`}>
          <div className={`${fromManage ? 'col-12 col-lg-8 mb-4' : 'col-10 col-sm-8 col-lg-5'}`}>
            <div className="row">
              <div className="col-12 logo-icon">
                <i className="fas fa-user-shield"></i>
              </div>
            </div>
            <Title type="h2" className="text-center marg-btm-1rem" variant="secondary">
              {t('forms.tfa.title')}
            </Title>
            {dataTfa && dataTfa !== 'disable' && this.renderConfigTFA(dataTfa)}
            {this.renderVerifyTFA(handleSubmit, t)}
          </div>
        </div>
      </>
    );
  }
}

ViewTFAInfo.propTypes = {
  dataTfa: PropTypes.any,
  fromManage: PropTypes.bool,
};

const ViewTFAInfoF = withFormik({
  mapPropsToValues: () => dataModel,
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'ViewTFAInfoForm',
})(withTranslation()(ViewTFAInfo));

export default ViewTFAInfoF;
