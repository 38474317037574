const theme = {
  colors: {
    primary: '#E83C7E',
    secondary: '#6c757d',
    white: '#ffffff',
    warrning: '#e3e600',
    yellow: '#e3e600',
  },
  buttons: {
    default: {
      backgroundColor: '#fff',
      backgroundColorHover: '#fff',
      color: '#000',
      colorHover: '#000',
      borderColor: '#000',
      borderColorHover: '#000',
    },
    primary: {
      backgroundColor: '#E83C7E',
      backgroundColorHover: '#af0b34',
      color: '#fff',
      colorHover: '#fff',
      borderColor: '#E83C7E',
      borderColorHover: '#af0b34',
    },
    secondary: {
      backgroundColor: '#171717',
      backgroundColorHover: '#1f1f1f',
      color: '#fff',
      colorHover: '#fff',
      borderColor: '#545454',
      borderColorHover: '#545454',
    },
    alert: {
      backgroundColor: '#d00000',
      backgroundColorHover: '#e80000',
      color: '#fff',
      colorHover: '#fff',
      borderColor: '#d00000',
      borderColorHover: '#e80000',
    },
    trasparent: {
      backgroundColor: 'trasparent',
      backgroundColorHover: 'trasparent',
      color: '#fff',
      colorHover: '#fff',
      borderColor: 'trasparent',
      borderColorHover: 'trasparent',
    },
  },
  links: {
    default: {
      color: '#000',
      colorHover: '#000',
    },
    secondary: {
      color: '#fff',
      colorHover: '#fff',
    },
    primary: {
      color: '#E83C7E',
      colorHover: '#E83C7E',
    },
  },
  text: {
    align: 'justify',
  },
  title: {
    h1: {
      size: '2rem',
      family: 'Gotham',
    },
    h2: {
      size: '1.8rem',
      family: 'Gotham',
    },
    h3: {
      size: '1.6rem',
      family: 'Gotham',
    },
    h4: {
      size: '1.4rem',
      family: 'Gotham',
    },
    h5: {
      size: '1.2rem',
      family: 'Gotham',
    },
  },
  fonts: {
    family: 'Gotham',
    size: {
      sm: '0.7rem',
      md: '0.875rem',
      lg: '1rem',
      xlg: '1.5rem',
      xxlg: '2rem',
    },
  },
};

export default theme;
